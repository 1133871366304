/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

// cspell:disable
function IosCalendarOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M704 192v-64h-32v64H352v-64h-32v64H128v704h768V192H704zm160 672H160V384h704v480zm0-512H160V224h160v64h32v-64h320v64h32v-64h160v128z" />
    </svg>
  );
}

function IosContactOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M512 96C282.2 96 96 282.2 96 512s186.2 416 416 416 416-186.2 416-416S741.8 96 512 96zm0 34.6c210.2 0 381.4 171 381.4 381.4 0 93.2-33.6 178.4-89.2 244.8l-.2-.2c-40-16.4-131.8-48.2-185.8-64.2-4.8-1.4-5.4-1.8-5.4-21.4 0-16.2 6.6-32.6 13.2-46.6 7.2-15 15.4-40.4 18.4-63.2 8.4-9.8 20-29 27.2-65.8 6.4-32.4 3.4-44.2-.8-55.2-.4-1.2-1-2.4-1.2-3.4-1.6-7.6.6-47 6.2-77.6 3.8-21-1-65.6-29.8-102.6-18.2-23.4-53.2-52-117-56h-35c-62.8 4-97.6 32.6-116 56-29 37-33.8 81.6-30 102.6 5.6 30.6 7.8 70 6.2 77.6-.4 1.4-.8 2.4-1.2 3.6-4.2 11-7.4 22.8-.8 55.2 7.4 36.8 18.8 56 27.2 65.8 3 22.8 11.4 48 18.4 63.2 5.2 11 7.6 26 7.6 47.2 0 19.8-.8 20-5.2 21.4-56 16.6-144.4 48.4-180.2 63.8-55.8-66.4-89.4-151.8-89.4-245 0-210.2 171.2-381.4 381.4-381.4zM242.8 782c39.8-16 117.2-43.6 166.2-58.2h.4c16.8-5.2 23.2-17 25.6-26 1.8-6.4 2-13 2-26 0-26.2-3.4-45.4-10.6-60.8-5.8-12.2-13.2-34.4-15.8-53.8l-1.2-9.4-6.2-7.2c-3.8-4.4-13.6-18.4-20.2-51.2-5.2-26-2.8-32-.8-37.4v-.4l.2-.6c.4-1.2 1.2-3.2 2-5.6l.4-2c3.4-16-1.2-63.8-6-90-2.2-12.4.6-47.6 23.6-77.2 14.2-18 41.6-40.2 91.8-43.6h33c51.2 3.4 78.8 25.6 92.8 43.8 23.2 29.6 25.8 64.8 23.6 77.2-4.8 26-9.4 74-6 90l.4 1.2c.8 3 1.8 5.6 2.4 7.2 2.2 5.6 4.2 11.8-.8 37.2-6.6 32.8-16.2 46.6-20 51.2l-6.2 7.2-1.2 9.6c-2.6 19.4-10 41.4-15.6 53.6-8 17-16.2 37.6-16.2 60.2 0 11.6.2 19.2 2.2 26.4 2.6 8.8 9 20.4 25.8 25.6h.4c47.4 14 127.8 41.6 172 58.8-69 68.8-164.2 111.4-269 111.4-104.8.2-199.8-42.4-269-111.2z" />
    </svg>
  );
}

function IosHomeOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M512 224 192 480v416h224V640h192v256h224V480L512 224zm288 640H640V608H384v256H224V495.38l288-230.4 288 230.4V864zM512 128 320 281.6V192H192v191.998l-64 50.998 23.02 22.768L512 168.98l360.98 288.786 23.02-22.77L512 128zM288 307.198l-64 51.2V224h64v83.198z" />
    </svg>
  );
}

function IosInformationCircleOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M543 344c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm1 344V432h-96v16h32v240h-32v16h128v-16zM512 96C282.2 96 96 282.2 96 512s186.2 416 416 416 416-186.2 416-416S741.8 96 512 96zm0 797.4c-210.2 0-381.4-171-381.4-381.4 0-210.2 171-381.4 381.4-381.4 210.2 0 381.4 171 381.4 381.4 0 210.2-171.2 381.4-381.4 381.4z" />
    </svg>
  );
}

function IosPaperOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M224 96v736h32V128h608v738.2c0 16.4-13.4 29.8-29.8 29.8H189.8c-16.4 0-29.8-13.4-29.8-29.8V256h32v-32h-64v642.2c0 34 27.6 61.8 61.8 61.8h644.6c34 0 61.8-27.6 61.8-61.8V96H224zm96 128h256v32H320v-32zm0 160h480v32H320v-32zm0 160h384v32H320v-32zm0 160h480v32H320v-32z" />
    </svg>
  );
}

function IosSettingsOutline(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M378.4 166c13.4 17 29.8 31.6 48.2 42.8 26.6 16.4 56 25 85.4 25s58.8-8.6 85.4-25c18.4-11.2 34.6-25.8 48.2-42.8 9.4 3.4 18.6 7.2 27.6 11.4-6.4 49.6 8.6 93 44.6 128.8 35.8 35.8 74.8 53.8 116 53.8 4.2 0 8.4-.2 12.6-.6 4.2 9 8 18.4 11.6 27.8-43.8 32.8-67.8 76.4-67.8 124.6 0 48.4 23.8 91.8 67.6 124.6-3.4 9.4-7.4 18.8-11.6 27.8-4.2-.4-8.4-.6-12.4-.6-41.4 0-80.4 18.2-116.2 53.8-35.8 36-50.8 79.4-44.6 128.8-9 4.2-18.2 8-27.6 11.4-13.4-17-29.8-31.6-48.2-42.8-26.6-16.4-56-25-85.4-25s-58.8 8.6-85.4 25c-18.4 11.2-34.6 25.8-48.2 42.8-9.4-3.4-18.6-7.2-27.6-11.4 6.4-49.8-8.6-93-44.6-128.8-30.6-30.6-66.6-46-107-46-7 0-14.2.4-21.6 1.4-4.2-9-8-18.2-11.4-27.6 17-13.4 31.6-29.8 43-48.2 16.4-26.6 25.2-56.2 25.2-85.4 0-48.4-24-91.8-68-124.8 3.4-9.4 7.4-18.8 11.6-27.8 4.2.4 8.4.6 12.4.6 41.4 0 80.4-18.2 116.2-53.8 35.8-36 50.8-79.4 44.6-128.8 8.8-3.8 18-7.6 27.4-11zM512 736c59.8 0 116-23.2 158.4-65.6S736 571.8 736 512s-23.2-116-65.6-158.4S571.8 288 512 288s-116 23.2-158.4 65.6S288 452.2 288 512c0 123.6 100.4 224 224 224zm120.8-608c-26 44.4-72.8 73.8-120.8 73.8-47.8 0-94.8-29.4-120.8-73.8-26.8 8-52.4 18.6-76.4 31.6 13 49.8 2.6 90-31.2 124-26.6 26.6-57.4 44.4-93.4 44.4-9.8 0-19.8-1.2-30.4-4-13.2 24-23.8 49.6-31.8 76.4 44.4 26 74 63.8 74 111.6s-29.6 94.8-74 120.8c8 26.8 18.6 52.4 31.6 76.4 13.8-3.6 27-5.4 39.4-5.4 32.2 0 60 12.2 84.4 36.6 34 33.8 44.2 74.2 31.2 124 24.2 13 49.6 23.6 76.4 31.6 26-44.4 73-73.8 120.8-73.8s94.8 29.4 120.8 73.8c26.8-8 52.4-18.6 76.4-31.6-13-49.8-2.6-90 31.2-124 26.6-26.6 57.4-44.4 93.6-44.4 9.8 0 19.8 1.2 30.4 4 13-24.2 23.6-49.8 31.6-76.4-44.2-26-73.6-63.8-73.6-111.6s29.6-85.6 73.8-111.6c-8-26.6-18.6-52.4-31.6-76.4-10.6 2.8-20.8 4-30.6 4-36 0-66.6-17.8-93.4-44.4-33.8-33.8-44.2-74.2-31.2-124-24-13-49.6-23.6-76.4-31.6zM512 704c-105.8 0-192-86-192-192s86-192 192-192 192 86 192 192-86 192-192 192z" />
    </svg>
  );
}

function MdSettings(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M827.934 553.6c2.12-12.47 2.12-27.036 2.12-41.6s-2.12-27.036-2.12-41.6l89.334-68.636c8.52-6.236 10.638-16.634 4.26-27.036L836.43 231.2c-4.258-8.328-17.014-12.47-25.534-8.328l-106.372 41.602c-21.276-16.636-46.788-31.202-72.32-41.602l-14.896-110.234c-2.12-8.308-10.638-16.636-21.276-16.636H425.836c-10.636 0-19.154 8.328-21.274 16.636l-17.016 110.234c-25.534 10.4-48.928 24.964-72.342 41.602l-106.372-41.602c-10.638-4.142-21.276 0-25.534 8.328L98.2 374.73c-4.238 8.306-2.122 20.798 4.258 27.036L193.94 470.4c0 14.564-2.12 27.036-2.12 41.6s2.12 27.036 2.12 41.6l-89.336 68.636c-8.52 6.236-10.636 16.634-4.26 27.036L185.442 792.8c4.26 8.328 17.016 12.47 25.534 8.328l106.374-41.602c21.274 16.636 46.788 31.202 72.32 41.602l17.016 110.234c2.138 10.4 10.636 16.636 21.274 16.636h170.196c10.638 0 19.156-8.328 21.276-16.636l17.036-110.234c25.514-10.4 48.928-24.964 72.32-41.602l106.374 41.602c10.636 4.142 21.274 0 25.534-8.328l85.098-143.53c4.258-8.306 2.12-20.798-4.26-27.036l-93.6-68.634zm-316.998 104c-82.978 0-148.92-64.47-148.92-145.6s65.942-145.6 148.92-145.6S659.858 430.87 659.858 512s-65.944 145.6-148.922 145.6z" />
    </svg>
  );
}

function MdSearch(props) {
  return (
    <svg
      className="sc-bdVaJa fUuvxv"
      width={22}
      height={22}
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M675.018 610.744h-35.002l-13.142-10.972c41.582-50.464 67.844-114.108 67.844-186.514C694.716 255.264 567.792 128 410.27 128 254.904 128 128 255.264 128 413.258s126.904 285.256 284.45 285.256c70.022 0 135.662-26.334 185.982-68.016l13.122 10.974v35.102L830.36 896 896 830.172 675.018 610.744zm-262.568 0c-109.404 0-196.926-87.774-196.926-197.486 0-109.716 87.522-197.484 196.926-197.484 109.4 0 196.924 87.768 196.924 197.484 0 109.712-87.524 197.486-196.924 197.486z" />
    </svg>
  );
}

function BookingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="150.99 151.708 18.725 23.548"
      {...props}
    >
      <path
        d="M160.263 151.72a.355.355 0 0 0-.155.084l-9.011 8.438a.355.355 0 0 0-.046.08.355.355 0 0 0-.011.02.355.355 0 0 0-.003.004.355.355 0 0 0-.04.117.364.364 0 0 0-.007.057v14.381c0 .063.018.12.046.172a.355.355 0 0 0 .006.01c.03.05.071.091.122.121a.355.355 0 0 0 .01.007.35.35 0 0 0 .17.045h18.018a.353.353 0 0 0 .17-.045.355.355 0 0 0 .01-.007.354.354 0 0 0 .122-.121.355.355 0 0 0 .006-.011.351.351 0 0 0 .046-.17V160.52c0-.007-.003-.013-.003-.02a.355.355 0 0 0-.003-.023.346.346 0 0 0-.015-.068.355.355 0 0 0-.093-.167l-9.012-8.438a.355.355 0 0 0-.33-.084zm.088.83 8.113 7.596h-16.227zm-8.65 8.325h2.893v1.875c0 .72-.625 1.315-1.447 1.315-.822 0-1.447-.595-1.447-1.315zm3.603 0h2.894v1.875c0 .72-.626 1.315-1.447 1.315-.822 0-1.447-.595-1.447-1.315zm3.603 0h2.894v1.875c0 .72-.625 1.315-1.447 1.315-.822 0-1.447-.595-1.447-1.315zm3.604 0h2.894v1.875c0 .72-.625 1.315-1.447 1.315-.822 0-1.447-.595-1.447-1.315zm3.604 0h2.894v1.875c0 .72-.626 1.315-1.448 1.315-.821 0-1.447-.595-1.447-1.315zm-11.166 2.987a2.2 2.2 0 0 0 1.802.913 2.2 2.2 0 0 0 1.802-.913c.162.23.376.426.622.578-.16 6.933-4.645 9.845-7.475 10.08v-10.273c.386.328.894.528 1.448.528a2.2 2.2 0 0 0 1.801-.914zm7.207 0c.389.552 1.056.913 1.802.913s1.413-.361 1.802-.913a2.2 2.2 0 0 0 1.801.913c.554 0 1.062-.2 1.447-.528v10.273c-2.829-.235-7.315-3.148-7.474-10.08a2.11 2.11 0 0 0 .622-.578zm-1.126.806-.03.01.03-.01zm-1.35 0 .028.01a6.318 6.318 0 0 1-.028-.01zm.204.055c.152.032.309.052.47.052s.319-.02.47-.052c.216 5.383 2.969 8.523 5.693 9.824h-12.325c2.724-1.3 5.476-4.441 5.692-9.824z"
        color="#000"
        style={{ paintOrder: 'fill markers stroke' }}
      />
    </svg>
  );
}

export {
  IosCalendarOutline,
  IosContactOutline,
  IosHomeOutline,
  IosInformationCircleOutline,
  IosPaperOutline,
  IosSettingsOutline,
  MdSettings,
  MdSearch,
  BookingIcon
};
