import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location?.pathname !== prevProps?.location?.pathname) {
      if (window.scrollTo) {
        window.scrollTo(0, 0);
      }
      const { gtag } = window;
      if (prevProps?.history.action === 'PUSH' && typeof gtag === 'function') {
        try {
          // console.log('sending page event', window.location);
          gtag('config', 'UA-117695765-2', {
            page_location: window.location?.href,
            page_path: window.location?.pathname
          });
        } catch (error) {
          console.error('gtag error');
        }
      }
    }
    // console.info('window.swUpdate', window.swUpdate);
    if (window.swUpdate) {
      window.location = (location && location?.pathname) || '/';
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollToTop.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(ScrollToTop);
