import styled from 'styled-components';

const Wrapper = styled.div`
  label {
    color: #636363 !important;
  }

  .field {
    &.disabled {
      display: none;
    }
  }

  .checkbox {
    &.checked {
      label {
        &::before {
          background-color: #00b91f !important;
        }
        &::after {
          color: white !important;
        }
      }
    }

    &.read-only {
      label {
        cursor: not-allowed !important;
      }
    }

    label {
      &::after,
      &::before {
        width: 1.5rem !important;
        height: 1.5rem !important;
        top: 0 !important;
      }
      padding-left: 2rem !important;
    }
  }
`;

export default Wrapper;
