import React from 'react';

import ContentEditor from '../ContentEditor';

function BookingInformationNote() {
  return (
    <div className="hightlight">
      <ContentEditor contentId="user-book-tent-tent-step2">
        {`<b>Observera:</b>
      <ul className="list-small-space">
        <li>
          Ni kan, efter genomförd bokning, logga in i bokningssystemet och lägga
          till övriga uppgifter.
        </li>
        <li>
          Under våren 2024 kommer informationen i nedan fält att publiceras på
          <a href="//jarvaveckan.se" target="_blank" rel="noopener noreferrer">
            jarvaveckan.se
          </a>
          . Det är därför viktigt att informationen är välskriven och
          presenterar aktiviteterna på ett korrekt och attraktivt sätt.
        </li>
        <li>
          Efter  3 maj 2024  kommer inga justeringar att kunna göras.
          Vi återkommer med påminnelse innan dess.
        </li>
      </ul>`}
      </ContentEditor>
    </div>
  );
}

export default BookingInformationNote;
