// import { TYPE_OF_ORG_3X3_ARRAY } from 'configs';
// import includes from 'lodash/includes';

// const isOrgTypeNgo = (typeOfOrg) => {
//   /* note: enable  Tältyta 2,5 x 2,5m for all*/
//   // console.log('typeOfOrg', typeOfOrg);
//   // return includes(TYPE_OF_ORG_3X3_ARRAY, typeOfOrg);
//   return true;
// };

const isOrgTypeNgo = () => true;

export default isOrgTypeNgo;
