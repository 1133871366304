import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Form, Grid } from 'semantic-ui-react';

import {
  EVENT_DAYS_ARRAY,
  EVENT_DATES_ARRAY,
  EVENT_TIME_SLOTS,
  EVENT_TIME_SLOTS_LIVE
} from '../../configs';
import { UnderlineHeader } from '../../styledComponents';

function RenderSeminarSlots(props) {
  const { data, handleChange, productId } = props;

  if (!data) {
    return null;
  }

  //   console.log('data', data);
  //  {"day3": [0,0,1,0],"day2": [0,1,0,0],"day4": [0,0,0,1],"day1": [1,0,0,0]}

  return EVENT_DAYS_ARRAY.map((key, eventDayIndex) => {
    const eventDayArray = data[key];

    return (
      <div key={key}>
        <UnderlineHeader>
          {key}: <span>{EVENT_DATES_ARRAY[eventDayIndex]}</span>
        </UnderlineHeader>
        <Grid>
          {eventDayArray?.map((timeSlotCount, timeSlots) => (
            <Grid.Column key={timeSlots} mobile={16} tablet={3} computer={3}>
              <Form.Input
                label={
                  productId === 'seminars'
                    ? EVENT_TIME_SLOTS[timeSlots]
                    : EVENT_TIME_SLOTS_LIVE[timeSlots]
                }
                type="number"
                onWheel={(e) => e.target.blur()}
                min="0"
                max="100"
                value={
                  get(data, `${key}[${timeSlots}]`) || timeSlotCount || '0'
                }
                name={`${key}[${timeSlots}]`}
                required
                onChange={handleChange}
              />
            </Grid.Column>
          ))}
        </Grid>
        <br />
        <br />
      </div>
    );
  });
}

RenderSeminarSlots.propTypes = {
  productId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default RenderSeminarSlots;
