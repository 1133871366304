import axios from 'axios';

const { APP_ENV = '', APP_EMULATOR = '' } = process.env;

// this is local firebase emulator url
let API_URL = 'https://api-ql62nibhfq-uc.a.run.app';

if (APP_ENV === 'stage') {
  API_URL = 'https://api-ql62nibhfq-uc.a.run.app';
}

if (APP_ENV === 'live') {
  API_URL = 'https://api-hvppuab3aq-uc.a.run.app';
}

// this is local firebase emulator url for local development need to
// run firebase emulators:start in https://github.com/Accomplicehq/jarvaveckan-node-backend.git
if (APP_EMULATOR === 'local') {
  API_URL = 'http://127.0.0.1:5001/jarvaveckan-stage/us-central1';
}

const defaultConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
};

const productsApi = axios.create(defaultConfig);

// need auth_id | user_id in header
const updatePeAccountProducts = async ({ data, headers }) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      ...headers
    }
  };

  const res = await productsApi.post('/products', data, config);
  // console.log('res', res);
  return res;
};

const getPeAccountProducts = async () => {
  const config = {
    ...defaultConfig
  };

  const response = await productsApi.get('/products', config);

  return response;
};

export { updatePeAccountProducts, getPeAccountProducts };
