/**
 *
 * BookingConfirm
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Button } from 'semantic-ui-react';

import { WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import ContentEditor from '../ContentEditor';
import MyFallbackComponent from '../MyFallbackComponent';

// import styled from 'styled-components';

function BookingConfirm({ history }) {
  return (
    <WhiteBox>
      <ContentEditor contentId="page-user-booking-booking-confirm">
        {`<h3>Bokningsbekräftelse</h3>
        <p>Din bokning har genomförts!</p>
        <p>
          Faktura skickas inom 5 dagar om inga ändringar i beställningen görs.
          Du kan se dina bokningar och fakturor i fliken 'Mitt Konto'.
        </p>`}
      </ContentEditor>
      <br />
      <br />
      <Button
        type="button"
        basic
        primary
        size="large"
        content="Mina bokningar"
        icon="user circle outline"
        labelPosition="right"
        onClick={() => {
          history.push('/user/bookings');
        }}
      />
      <Button
        type="button"
        basic
        primary
        size="large"
        content="Se övriga produkter och tjänster du kan addera till din bokning"
        icon="star outline"
        labelPosition="right"
        onClick={() => {
          history.push('/user/book/tillagg');
        }}
      />
      <Button
        type="button"
        basic
        primary
        size="large"
        content="Boka mer"
        icon="calendar outline"
        labelPosition="right"
        onClick={() => {
          history.push('/user/book');
        }}
      />
    </WhiteBox>
  );
}

BookingConfirm.propTypes = {
  history: PropTypes.object.isRequired
};
export default withErrorBoundary(
  BookingConfirm,
  MyFallbackComponent,
  errorCallback
);
