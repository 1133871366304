import { saveAs } from 'file-saver';
import jsonexport from 'jsonexport';
import sortBy from 'lodash/sortBy';

const sortList = (data) => sortBy(data, ['orgName']);

const exportData = (data) => {
  const minimizedSeminars = sortList(data)?.map((obj) => {
    const {
      fullName = '',
      firstName = '',
      secondName = '',
      email = '',
      fakturaadress = '',
      fakturaEmail = '',
      fakturamottagare = '',
      bransch = '',
      discount = '',
      hemsida = '',
      isApproved = '',
      orgName = '',
      orgNr = '',
      ort = '',
      postnummer = '',
      telefonnummer = '',
      titel = '',
      typeOfOrg = ''
    } = obj;

    return {
      orgName,
      fullName: fullName || `${firstName} ${secondName}`,
      email,
      fakturaadress,
      fakturaEmail,
      fakturamottagare,
      bransch,
      discount,
      hemsida,
      isApproved,
      orgNr,
      ort,
      postnummer,
      telefonnummer,
      titel,
      typeOfOrg
    };
  });
  const options = {
    rowDelimiter: ';'
  };

  jsonexport(minimizedSeminars, options, (err, csv) => {
    if (err) return console.error(err);
    const csvblob = new Blob([`\ufeff${csv}`], {
      type: 'text/csv;charset=utf-8'
    });
    saveAs(csvblob, 'organizationContact.csv');
  });
};

export default exportData;
