/**
 *
 * LoaderDots
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import { LoaderDots } from '../../styledComponents';

function Loader({ text }) {
  return (
    <LoaderDots>
      {text && <span>{text}</span>}
      <span className="dot one" />
      <span className="dot two" />
      <span className="dot three" />
    </LoaderDots>
  );
}

Loader.propTypes = { text: PropTypes.string };

export default Loader;
