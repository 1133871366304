import React from 'react';
import copy from 'copy-to-clipboard';
import { Button, Popup } from 'semantic-ui-react';

function KontaktaWorkman() {
  const buttonEl = (
    <Button
      primary
      basic
      labelPosition="right"
      content="Kontakta Workman"
      icon="mail"
      onClick={() => {
        copy('monterservice@workman.se');
      }}
      as="a"
      href="mailto:monterservice@workman.se"
    />
  );

  return (
    <Popup trigger={buttonEl}>
      <span>E-post kopierad vid klick!</span> <br />
      <a href="mailto:monterservice@workman.se">monterservice@workman.se</a>
    </Popup>
  );
}

export default KontaktaWorkman;
