import styled from 'styled-components';

import { vars } from '../../styledComponents';

const booked = '#5e5b61';

const GalleryContainer = styled.div`
  @media ${vars.desktopAndUp} {
    min-width: 40%;
    max-width: 40%;
    margin-left: 10%;
  }
`;
const CoArrangeContainer = styled.div`
  padding: 10px 0px 10px 0px;
  padding-top: 40px;
  border-top: 0px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  &:first-child {
    border-top: 0;
    padding-top: 10px;
  }
`;
const BookingContainer = styled.div`
  width: 100%;
  & > ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 18px 0px;
    box-sizing: border-box;
    p {
      font-weight: 450;
      margin-bottom: 5px;
      font-size: var(--font-size--label) !important;
      font-style: normal;
      font-weight: 450;
      letter-spacing: 0.06em !important;
      line-height: 160% !important;
      color: ${vars.jarvaTextDark};
      text-transform: uppercase;
      margin-top: 1rem;
    }
  }
  .gallery {
    max-width: 50%;
    margin-left: 10%;
  }
  .bookables {
    display: flex;
  }
  .select {
    flex-grow: 1;
    padding: 14px 15px;
    border: 1px solid rgba(9, 0, 0, 0.2);
    display: inline-block;
    margin-right: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #fff;
    span {
      color: inherit;
    }
    @media ${vars.desktopAndUp} {
      padding: 14px 28px;
    }
    &.full {
      cursor: inherit;
      color: #fff;
      background-color: ${booked};
      border-color: ${vars.jarvaBlue};
      pointer-events: none;
    }
    &:hover {
      background-color: rgba(0, 60, 181, 0.5);
      color: #fff;
    }
    &.user-selected {
      color: #fff;
      background-color: ${vars.jarvaBlue};
      border-color: ${vars.jarvaBlue};
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    @media ${vars.desktopAndUp} {
      flex-direction: row;
    }
    p {
      font-size: 1.23rem;
      line-height: 1.3rem;
      max-width: 560px;
      margin: 0;
      padding: 0;
      padding-bottom: 10px;
      @media ${vars.desktopAndUp} {
        padding-bottom: 10px;
      }
    }
  }
  span.booked {
    &:before {
      background-color: ${booked};
      border-color: ${vars.jarvaBlue};
    }
  }
  span.free {
    &:before {
      background-color: #fff;
    }
  }
  span.chosen {
    &:before {
      background-color: ${vars.jarvaBlue};
      border-color: ${vars.jarvaBlue};
    }
  }
  .color-nav {
    padding-bottom: 20px;
    margin-top: -5px;
    margin-left: auto;
    min-width: 40%;
    display: flex;
    span {
      display: flex;
      flex-grow: 1;
      align-items: center;
      &:before {
        content: ' ';
        border: 1px solid rgba(0, 0, 0, 0.2);
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
`;

export { BookingContainer, CoArrangeContainer, GalleryContainer };
