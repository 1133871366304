import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Form, Button, Message } from 'semantic-ui-react';

import { ORGANISATION_OPTIONS } from '../../configs';
import { StyledFormContainer } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import MyFallbackComponent from '../MyFallbackComponent';

function CompanyDetails(props) {
  const {
    handleInputChange,
    nextStep,
    prevStep,
    authError,
    handleCancelClick,
    values = {},
    title = 'Fyll i organisationsuppgifter'
  } = props;

  const { orgName, orgNr, step, totalSteps, typeOfOrg, hemsida } = values;

  return (
    <div>
      <StyledFormContainer>
        <h3>{title}</h3>
        <a href="#" onClick={prevStep}>
          Föregående
        </a>
        <span style={{ float: 'right' }}>
          Steg {step} av {totalSteps}
        </span>
        <br />
        <br />
        <Form error={Boolean(authError)} onSubmit={nextStep}>
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            required
            id="orgName"
            name="orgName"
            label="Organisationsnamn"
            value={orgName}
            type="text"
            autoFocus
          />
          <Form.Input
            onChange={handleInputChange}
            maxLength="20"
            label="Organisationsnummer (i formatet 802481–1658)"
            // pattern="[0-9]{6}-[0-9]{4}"
            placeholder="802481–1658"
            required
            id="orgNr"
            name="orgNr"
            type="text"
            value={orgNr}
          />
          <Form.Select
            onChange={handleInputChange}
            required
            id="typeOfOrg"
            name="typeOfOrg"
            label="Typ av organisation"
            options={ORGANISATION_OPTIONS}
            placeholder="Välj ett alternativ"
            value={typeOfOrg}
          />
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            id="hemsida"
            name="hemsida"
            label="Hemsida"
            type="text"
            value={hemsida}
            placeholder="hemsida.se"
          />
          <br />
          <Message
            error
            header={authError && authError.code}
            content={authError && authError.message}
          />
          <Button
            type="submit"
            fluid
            primary
            disabled={!(orgName.length && orgNr.length)}
          >
            Fortsätt
          </Button>
        </Form>
        <br />
        <div
          style={{
            margin: '15px 0',
            marginBottom: 0
          }}
        >
          <a
            href="mailto:info@jarvaveckan.se"
            target="_blank"
            rel="noopener noreferrer"
            data-info="info@jarvaveckan.se"
          >
            Hjälp?
          </a>
          <a href="#" style={{ float: 'right' }} onClick={handleCancelClick}>
            Avbryt
          </a>
        </div>
      </StyledFormContainer>
    </div>
  );
}

CompanyDetails.propTypes = {
  values: PropTypes.object,
  authError: PropTypes.object,
  handleCancelClick: PropTypes.func,
  handleInputChange: PropTypes.func,
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  title: PropTypes.string
};
export default withErrorBoundary(
  CompanyDetails,
  MyFallbackComponent,
  errorCallback
);
