import styled from 'styled-components';

import { ContentContainer } from '../../styledComponents';

const StyledContentContainer = styled(ContentContainer)`
  pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .hide {
    ${({ hide }) => hide && 'display: none !important'};
  }

  .input {
    > .label {
      text-transform: capitalize;
    }
  }

  .info {
    max-width: max-content;
    padding: 2px 7px;
    background-color: yellow;
  }

  .warn {
    color: red;
  }

  summary {
    cursor: pointer;
  }
`;
export { StyledContentContainer };
