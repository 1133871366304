import React from 'react';

import { ASSETS } from '../../configs';

function TermsLinks() {
  return (
    <div>
      <br />
      <a href={ASSETS.kopvillkor} target="_blank" rel="noopener noreferrer">
        Köpvillkor och GDPR
      </a>
      <span
        style={{
          color: 'black',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}
      >
        |
      </span>
      <a
        href={ASSETS.varderingarUppforandekod}
        target="_blank"
        rel="noopener noreferrer"
      >
        Värderingar och Uppförandekod
      </a>
    </div>
  );
}

export default TermsLinks;
