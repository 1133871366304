import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';

import ContentEditor from '../../components/ContentEditor';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import { WhiteBox, JarvaDivider } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import { BookingContainer } from './SeminarStyles';

function RenderBookingSection(props) {
  const { days, makeSeminarOptions } = props;

  return (
    <WhiteBox>
      <BookingContainer>
        <div className="header">
          <ContentEditor
            inline
            contentId="page-user-book-seminar-booking-title"
          >
            {
              'För att boka, klicka på en eller flera lediga tider och välj sedan <i>Nästa steg</i> för att gå vidare.'
            }
          </ContentEditor>
          <div className="color-nav">
            <span className="free">Ledig tid</span>
            <span className="chosen">Vald tid</span>
            <span className="booked">Fullbokad tid</span>
          </div>
        </div>
        <JarvaDivider />
        <ul>
          {days &&
            days?.map((day, index) => (
              <li key={day}>
                <p>{day}</p>
                <div className="bookables">{makeSeminarOptions(index)}</div>
              </li>
            ))}
        </ul>
      </BookingContainer>
    </WhiteBox>
  );
}
RenderBookingSection.propTypes = {
  days: PropTypes.array.isRequired,
  makeSeminarOptions: PropTypes.func.isRequired
};

export default withErrorBoundary(
  RenderBookingSection,
  MyFallbackComponent,
  errorCallback
);
