/* eslint-disable no-alert */
/**
 *
 * ResetDatabase
 *
 */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';

import PropTypes from 'prop-types';
import debounceRender from 'react-debounce-render';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid, Input } from 'semantic-ui-react';

import MyFallbackComponent from '../../components/MyFallbackComponent';
import { PRODUCTS, STATS } from '../../configs';
import { WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import { saveToServer } from '../../utils/saveToServer';
import { StyledContentContainer } from './styles';

import { updatePeAccountProducts } from '../../api';

const PASSWORD = 'acc';

function ResetDatabase(props) {
  // console.log('props', props);

  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (password === PASSWORD) {
      setIsDisabled(false);
    } else if (!isDisabled) {
      setIsDisabled(true);
    }
  }, [password]);

  const handleChange = (e, { value }) => setPassword(value);

  // console.log(props?.collections?.products);

  const updateProductsPeIds = async () => {
    const { claims } = props;
    const products = props?.collections?.products || {};

    const promiseArray = map(products, (item) => {
      const dataToSave = {
        ...item,
        editedBy: claims?.email
      };
      const omitState = ['createAt', 'updateAt'];
      const selectedState = {
        ...omit(dataToSave, omitState),
        updateAt: new Date().toISOString()
      };

      return updatePeAccountProducts({
        headers: {
          user_id: claims.user_id
        },
        data: selectedState
      });
    });

    await Promise.all(promiseArray);
  };

  const handleSubmit =
    ({
      path,
      data,
      isFromCodeProductsArray,
      isStatsObject,
      isResetProductsCount
    }) =>
    async () => {
      // clearing the password
      setPassword('');

      if (
        !(
          path &&
          window.confirm(`Do you want to overwrite ${path} in database?`)
        )
      ) {
        return console.info('\n', path, 'update canceled\n');
      }

      const { firestore, collections } = props;

      // create and update products from config file
      try {
        if (data && isFromCodeProductsArray) {
          const promiseArray = data.map((item) => {
            const { productId } = item;

            // Get the products from database and update the products
            const product = collections?.products?.[productId] || {};
            let dataFromServer = {};
            if (product) {
              console.info(
                `Product with ID ${productId} from firebase\n`,
                product
              );
              const omitState = ['createAt', 'updateAt'];
              dataFromServer = omit(product, omitState) || {};
            }

            const dataToSave = {
              ...dataFromServer,
              ...item,
              createAt: firestore.FieldValue.serverTimestamp()
            };

            return saveToServer({
              path: `${path}/${productId}`,
              data: dataToSave,
              firestore
            });
          });

          // Adds new products to collection
          await Promise.all(promiseArray);
          await updateProductsPeIds();
          console.info(`${path} upload finish`);
        }
      } catch (error) {
        console.error(`Error uploading products: ${error.message}`);
      }

      // stats object from code
      try {
        if (data && isStatsObject) {
          const promiseArray = map(data, (item, key) => {
            return saveToServer({
              path: `${path}/${key}`,
              data: {
                ...item,
                updateAt: firestore.FieldValue.serverTimestamp()
              },
              firestore
            });
          });

          await Promise.all(promiseArray);
          return console.info(path, 'upload finish');
        }
      } catch (error) {
        console.error(`Error uploading stats reset: ${error.message}`);
      }

      // stats object from code
      try {
        if (isResetProductsCount) {
          const promiseArray = map(collections?.products || {}, (item, key) => {
            const dataToSave = {
              ...item,
              maxQuantity: 0,
              max: 0,
              count: 0
            };
            const omitState = ['createAt', 'updateAt'];
            const selectedState = {
              ...omit(dataToSave, omitState),
              updateAt: firestore.FieldValue.serverTimestamp()
            };

            return saveToServer({
              path: `${path}/${key}`,
              data: selectedState,
              firestore
            });
          });

          await Promise.all(promiseArray);
          return console.info(path, 'upload finish');
        }
      } catch (error) {
        console.error(`Error uploading products count reset: ${error.message}`);
      }
    };

  return (
    <StyledContentContainer>
      <section>
        <WhiteBox>
          <h1>Reset Products Database</h1>

          <Grid celled>
            {/* password to edit the form */}
            <Grid.Row>
              <Grid.Column>
                <Input
                  fluid
                  labelPosition="left"
                  label="Developer Password"
                  value={password}
                  name="password"
                  type="text"
                  placeholder={PASSWORD}
                  onChange={handleChange}
                />
                <p className="note info">**enter password to edit details</p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Products */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  Products
                  <span className="info" style={{ color: 'red' }}>
                    ** don't do it after bookings started
                  </span>
                </h4>

                <p>Creates products in database</p>

                <details>
                  <summary>Products Data in code</summary>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    <p>
                      <b>Products</b> update and created in code.
                    </p>
                    <pre>{JSON.stringify(PRODUCTS, null, 2)}</pre>
                  </div>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={handleSubmit({
                    path: '/products',
                    data: PRODUCTS,
                    isFromCodeProductsArray: true
                  })}
                  disabled={isDisabled}
                  content="Update Products"
                />
                <p className="note warn">
                  ** This action will delete and overwrite data
                </p>
              </Grid.Column>
            </Grid.Row>

            {/* Overrites Stats */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  Reset products booking count in database
                  <span className="info" style={{ color: 'red' }}>
                    ** don't do it after bookings started
                  </span>
                </h4>
                <p>
                  Reset stats in database including <b>products</b> collection
                </p>

                <details>
                  <summary>Stats Data</summary>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    <p>Stats Data to reset to</p>
                    <pre>{JSON.stringify(STATS, null, 2)}</pre>
                  </div>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={() => {
                    // rests stats
                    handleSubmit({
                      path: '/stats',
                      data: STATS,
                      isStatsObject: true
                    })();
                  }}
                  disabled={isDisabled}
                  content="Resets stats"
                />
                <span className="info" style={{ color: 'red' }}>
                  ** ** This action will delete and overwrite data
                </span>
              </Grid.Column>
            </Grid.Row>

            {/* Reset Products Count */}
            <Grid.Row>
              <Grid.Column>
                <h4>
                  Reset <b>products</b> booking count in products collection
                </h4>
                <br />

                <details>
                  <summary>Products Data</summary>
                  <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
                    <p>
                      <b>products</b> collection in database
                    </p>
                    <pre>
                      {JSON.stringify(
                        get(props, 'collections.products', {}),
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </details>
                <br />

                <Button
                  icon="upload"
                  primary
                  basic
                  labelPosition="left"
                  type="button"
                  onClick={() => {
                    // reset products booking count
                    handleSubmit({
                      path: '/products',
                      isResetProductsCount: true
                    })();
                  }}
                  disabled={isDisabled}
                  content="Resets booking count"
                />
                <span className="info" style={{ color: 'red' }}>
                  ** ** This action will delete and overwrite data
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </WhiteBox>
      </section>
    </StyledContentContainer>
  );
}

ResetDatabase.propTypes = {
  firestore: PropTypes.object,
  collections: PropTypes.object,
  claims: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  collections: (state) => ({
    assets: get(state, 'firestore.data.assets'),
    seminars: get(state, 'firestore.data.seminars'),
    seminarsLive: get(state, 'firestore.data.seminarsLive'),
    products: get(state, 'firestore.data.products')
  })
});

const withConnect = connect(mapStateToProps);

const getFirebaseCollections = () => [
  'assets',
  'seminars',
  'seminarsLive',
  'products'
];

export default compose(
  withConnect,
  firestoreConnect((props) => getFirebaseCollections(props))
)(
  withErrorBoundary(
    debounceRender(ResetDatabase, 100),
    MyFallbackComponent,
    errorCallback
  )
);
