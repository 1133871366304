import styled from 'styled-components';

import { vars } from '../../styledComponents';

const SeminarStyles = styled.ul`
  padding-bottom: 40px;
  .bookables {
    .select {
      border: 1px solid ${vars.jarvaBlue};
      margin: 10px 0;
      h5 {
        padding: 6px;
        border: 0;
        margin: 0;
        font-weight: 300;
        font-size: 0.8rem;
        &:after {
          content: '';
          display: none;
        }
      }
      text-align: center;
      .selectedGrid {
        display: flex;
        span {
          height: 0px;
          width: 100%;
          padding-bottom: 10%;
          border: 1px solid ${vars.jarvaBlue};
          border-right: 0;
          border-bottom: 0;
          transition: all 0.3s ease;
          &:last-child {
            border-right: 0;
          }
          &:first-child {
            border-left: 0;
          }
          background-color: ${vars.jarvaBlueLight};
          &.booked {
            cursor: pointer;
            background-color: ${vars.jarvaBlue};
            &:hover {
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }
`;
export { SeminarStyles };
