import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function FileOrImagePreview({ img }) {
  const [previewSrc, setPreviewSrc] = useState('');

  useEffect(() => {
    if (img instanceof FileList) {
      // Use FileReader to read the file and set the previewSrc state
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(img[0]); // Read the first file in FileList as Data URL
    } else if (typeof img === 'string') {
      // If img is a string, set it directly to previewSrc
      setPreviewSrc(img);
    }
  }, [img]);

  if (!previewSrc) {
    return null;
  }

  return (
    <img className="uploads-preview" src={previewSrc} alt="upload media" />
  );
}

FileOrImagePreview.propTypes = {
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(FileList)])
};

export default FileOrImagePreview;
