import transformNgoText from './transformNgoText';
import getDateFromTimeStamp from './getDateFromTimeStamp';

const handleTableDataTransform = (dataString) => {
  if (dataString === 'ngo') {
    return transformNgoText('ngo');
  }
  if (typeof dataString === 'object') {
    // check if firebase time stamp object {seconds: 1234567890, nanoseconds: 1234567890}
    if (dataString?.seconds) {
      return getDateFromTimeStamp(dataString);
    }
    return '';
  }
  return dataString;
};

export default handleTableDataTransform;
