import dateRangeStrings from '../utils/dateRangeStrings';

const IMAGE_UPLOAD_CONFIG = {
  maxWidth: 1200,
  quality: 0.9
};

const REGISTER_TABLE_SUM_ROWS = [
  'tent',
  'seminar',
  'seminarLive',
  'sponsor',
  'sponsor2',
  'totalCost',
  'quantity',
  'period1count',
  'period2count',
  'sum',
  'discount'
];

// key word for PRODUCTS_FOR_NGO tents ngo
const TYPE_OF_ORG_3X3_ARRAY = [
  'ngo',
  'Ideell förening/organisation: svag ekonomiskt ställning (årsredovisning krävs)',
  'Ideell förening: svag ekonomisk ställning (årsredovisning krävs)'
];

const PRODUCTS_FOR_NGO = ['tent2_5x2_5'];
const ALL_PRODUCTS_IDS = [
  'tent2_5x2_5',
  'tent5x2_5',
  'tent5x5',
  'sponsor',
  'sponsor2',
  'seminars',
  'seminarsLive'
];

const DAYS_TO_INVOICE = 5; // 5days

// multiple
const MAX_SELECT = 5;
const OPTIONS_ARRAY_TO_LIMIT = ['amne', 'tillganglighet', 'sprak'];

// TODO: update the year and days and month
const EVENT_YEAR = 2025;
const EVENT_START_MONTH = 6;
const EVENT_END_MONTH = 6;
const EVENT_START_DAY = 11;
const EVENT_END_DAY = 14;

const IS_EVENT_SAME_MONTH = EVENT_START_MONTH === EVENT_END_MONTH;

/*
[
  {
    day: 11,
    month: 'juni',
    dayMonth: '11 juni',
    full: 'Onsdag 11 juni',
    timeStamp: 1749592800000
  }
]
*/

const dateRangeStringsArray = dateRangeStrings(
  { day: EVENT_START_DAY, month: EVENT_START_MONTH },
  { day: EVENT_END_DAY, month: EVENT_END_MONTH },
  EVENT_YEAR
);

// eslint-disable-next-line import/no-mutable-exports
let EVENT_DURATION_STRING;
let period1Label;
let period2Label;
let fullPeriodLabel;

if (IS_EVENT_SAME_MONTH) {
  period1Label = `${dateRangeStringsArray[0].day} - ${dateRangeStringsArray[1].day}  ${dateRangeStringsArray[0].month} (2 dagar)`;
  period2Label = `${dateRangeStringsArray[2].day} - ${dateRangeStringsArray[3].day}  ${dateRangeStringsArray[0].month} (2 dagar)`;
  fullPeriodLabel = `${dateRangeStringsArray[0].day} - ${dateRangeStringsArray[3].day}  ${dateRangeStringsArray[0].month} (4 dagar)`;

  EVENT_DURATION_STRING = `${EVENT_START_DAY}-${EVENT_END_DAY} ${dateRangeStringsArray[0].month} ${EVENT_YEAR}`;
} else {
  period1Label = `${dateRangeStringsArray[0].dayMonth} - ${dateRangeStringsArray[1].dayMonth} (2 dagar)`;
  period2Label = `${dateRangeStringsArray[2].dayMonth} - ${dateRangeStringsArray[3].dayMonth} (2 dagar)`;
  fullPeriodLabel = `${dateRangeStringsArray[0].dayMonth} - ${dateRangeStringsArray[3].dayMonth} (4 dagar)`;

  EVENT_DURATION_STRING = `${EVENT_START_DAY} ${dateRangeStringsArray[0].month} -${EVENT_END_DAY} ${dateRangeStringsArray[3].month} ${EVENT_YEAR}`;
}

const DAY_LABELS = {
  labelDay1: dateRangeStringsArray[0].dayMonth, // index of week array from monday to sunday
  labelDay2: dateRangeStringsArray[1].dayMonth,
  labelDay3: dateRangeStringsArray[2].dayMonth,
  labelDay4: dateRangeStringsArray[3].dayMonth,
  period1Label,
  period2Label,
  fullPeriodLabel
};

const EVENT_DAYS_ARRAY = ['day1', 'day2', 'day3', 'day4'];

const AVAILABLE_TIME_SLOTS = {
  // day1: [0, 0, 0, 0, 0]
  [EVENT_DAYS_ARRAY[0]]: [0, 0, 0, 0, 0],
  [EVENT_DAYS_ARRAY[1]]: [0, 0, 0, 0, 0],
  [EVENT_DAYS_ARRAY[2]]: [0, 0, 0, 0, 0],
  [EVENT_DAYS_ARRAY[3]]: [0, 0, 0, 0, 0]
};

const EVENT_DATES_ARRAY = [
  dateRangeStringsArray[0].full,
  dateRangeStringsArray[1].full,
  dateRangeStringsArray[2].full,
  dateRangeStringsArray[3].full
];

const EVENT_TIME_SLOTS = [
  '11.30 – 12.45', // longer seminar product id seminars75min
  '13.15 – 14.00',
  '14.30 – 15.15',
  '15.45 – 16.30',
  '17.00 – 17.45'
];

const EVENT_TIME_SLOTS_LIVE = [
  '12.00 – 12.45',
  '13.15 – 14.00',
  '14.30 – 15.15',
  '15.45 – 16.30',
  '17.00 – 17.45'
];

const SEMINAR_DETAILS = {
  days: EVENT_DATES_ARRAY,
  timeSlots: EVENT_TIME_SLOTS
};

const SEMINAR_DETAILS_LIVE = {
  days: EVENT_DATES_ARRAY,
  timeSlots: EVENT_TIME_SLOTS_LIVE
};

const PRODUCT_CATEGORIES = {
  tents: ['tent'],
  seminars: ['seminars'],
  sponsor: ['sponsor'],
  tillagg: ['tillagg'],
  torghandel: ['torghandel'],
  foodtruck: ['foodtruck']
};

const PRODUCT_CATEGORIES_OPTIONS = [
  { value: 'tillagg', text: 'Tillägg' },
  { value: 'seminars', text: 'Seminars' },
  { value: 'sponsor', text: 'Sponsor' },
  { value: 'tent', text: 'Tent' },
  { value: 'torghandel', text: 'Torghandel' },
  { value: 'foodtruck', text: 'Foodtruck' }
];
const PRODUCTS = [
  {
    productId: 'tent2_5x2_5',
    order: '1',
    title: 'Tältyta för ideella organisationer med svag ekonomi',
    pris: 18750,
    taltyta: '2,5m x 2,5m',
    category: PRODUCT_CATEGORIES.tents,
    fullPeriodDiscount: 0,
    ...DAY_LABELS,
    typeOfOrg: 'ngo'
  },
  {
    productId: 'tent5x2_5',
    order: '1',
    title: 'Tältyta 5m x 2,5m',
    pris: 37500,
    taltyta: '5m x 2,5m',
    category: PRODUCT_CATEGORIES.tents,
    fullPeriodDiscount: 0,
    ...DAY_LABELS
  },
  {
    productId: 'tent5x5',
    order: '1',
    title: 'Tältplats 5m x 5m',
    pris: 75000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES.tents,
    fullPeriodDiscount: 0,
    ...DAY_LABELS
  },
  {
    productId: 'sponsor',
    order: '1',
    title: 'Stödorganisation',
    pris: 300000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES.sponsor,
    ...DAY_LABELS
  },
  {
    productId: 'sponsor2',
    order: '1',
    title: 'Partner',
    pris: 500000,
    taltyta: '5m x 5m',
    category: PRODUCT_CATEGORIES.sponsor,
    ...DAY_LABELS
  },
  {
    productId: 'seminars',
    order: '1',
    title: 'Boka scentid – utan livesändning',
    pris: 22500,
    category: PRODUCT_CATEGORIES.seminars,
    ...SEMINAR_DETAILS
  },
  {
    productId: 'seminars75min', // just first timeslot of seminars
    order: '1',
    title: 'Boka scentid – utan livesändning 75min',
    pris: 30000,
    category: PRODUCT_CATEGORIES.seminars,
    ...SEMINAR_DETAILS
  },
  {
    productId: 'seminarsLive',
    order: '1',
    title: 'Boka scentid – med livesändning & inspelning',
    pris: 40000,
    category: PRODUCT_CATEGORIES.seminars,
    ...SEMINAR_DETAILS_LIVE
  },
  // should use new logic to handle all new products
  {
    productId: 'torghandel',
    order: '1',
    title: 'Utställare Torghandel',
    pris: 25000,
    // count: 0,
    max: 0,
    category: PRODUCT_CATEGORIES.torghandel,
    collection: 'productBookings',
    path: 'currentUserProductBookings',
    ...DAY_LABELS
  },
  {
    productId: 'foodtruck',
    order: '1',
    title: 'Foodtruck',
    pris: 10500,
    // count: 0,
    max: 0,
    category: PRODUCT_CATEGORIES.foodtruck,
    collection: 'productBookings',
    path: 'currentUserProductBookings',
    ...DAY_LABELS
  }
];

const STATS = {
  seminars: {
    availableTimeSlots: AVAILABLE_TIME_SLOTS
  },
  seminarsLive: {
    availableTimeSlots: AVAILABLE_TIME_SLOTS
  },
  tent2_5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  tent5x2_5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  tent5x5: {
    p1Count: 0,
    p2Count: 0,
    p1Max: 0,
    p2Max: 0
  },
  sponsor: {
    count: 0,
    max: 0
  },
  sponsor2: {
    count: 0,
    max: 0
  }
};

const HAS_COUNT_IN_PRODUCT = true;

export * from './options';
export * from './assets';

export {
  REGISTER_TABLE_SUM_ROWS,
  OPTIONS_ARRAY_TO_LIMIT,
  MAX_SELECT,
  TYPE_OF_ORG_3X3_ARRAY,
  DAYS_TO_INVOICE,
  PRODUCTS_FOR_NGO,
  ALL_PRODUCTS_IDS,
  EVENT_YEAR,
  EVENT_DAYS_ARRAY,
  DAY_LABELS,
  AVAILABLE_TIME_SLOTS,
  SEMINAR_DETAILS,
  PRODUCTS,
  STATS,
  IMAGE_UPLOAD_CONFIG,
  EVENT_DURATION_STRING,
  EVENT_DATES_ARRAY,
  EVENT_TIME_SLOTS,
  EVENT_TIME_SLOTS_LIVE,
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_OPTIONS,
  HAS_COUNT_IN_PRODUCT
};
