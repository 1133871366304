/**
 *
 * UserManagement
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Message } from 'semantic-ui-react';

// import styled from 'styled-components';

function UserManagement(props) {
  // console.log('%c props', 'color:red; background:black;', props);
  const { location, firebase, history } = props;

  const getParameterByName = (args) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(args);
  };

  const actionCode = getParameterByName('oobCode');
  // const mode = getParameterByName('mode');
  // const continueUrl = getParameterByName('continueUrl');
  // const lang = getParameterByName('lang') || 'en';

  // TODO: update email

  const [inputValues, setvalue] = useState({
    email: '',
    password: '',
    repeatPassword: '',
    newEmail: ''
  });
  const [submittingPassword, setSubmittingPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleInputChange = (_, { name, value }) => {
    setvalue({ ...inputValues, [name]: value });
  };

  useEffect(() => {
    const email = window.localStorage.getItem('email') || '';
    if (email) setvalue({ ...inputValues, email });
  }, []);

  const { email, password, repeatPassword } = inputValues;

  const handleResetPassword = (e) => {
    e.preventDefault();
    setSubmittingPassword(true);
    if (password === repeatPassword) {
      firebase
        .confirmPasswordReset(actionCode, password)
        .then(() => {
          firebase
            .login({
              email,
              password
            })
            .then(() => {
              setSubmittingPassword(false);
              history.push('/');
            })
            .catch((err) => {
              console.error(err);
              setError({ ...err });
              setSubmittingPassword(false);
            });
        })
        .catch((err) => {
          console.error('error', error);
          setError({ ...err });
          setSubmittingPassword(false);
        });
    }
  };

  return (
    <Form
      style={{ maxWidth: 400, margin: 'auto', padding: 30 }}
      onSubmit={handleResetPassword}
    >
      <h3>UserManagement</h3>
      <Form.Input
        maxLength="120"
        type="email"
        onChange={handleInputChange}
        required
        id="loginOrgNr"
        name="email"
        placeholder="E-post"
        label="E-post"
        value={email}
        autoComplete="username"
      />
      <Form.Input
        maxLength="40"
        onChange={handleInputChange}
        value={password}
        required
        label="Nytt lösenord"
        type="password"
        id="Nytt lösenord"
        name="password"
        autoComplete="new-password"
        placeholder="Nytt lösenord"
      />
      <Form.Input
        maxLength="40"
        onChange={handleInputChange}
        value={repeatPassword}
        required
        label="Upprepa nytt lösenord"
        type="password"
        id="Upprepa nytt lösenord"
        name="repeatPassword"
        autoComplete="new-password"
        placeholder="Upprepa nytt lösenord"
      />
      {error && <Message negative header={error.code} list={[error.message]} />}
      <Button
        secondary
        basic
        icon="cancel"
        labelPosition="right"
        content="Avbryt"
        type="button"
        onClick={() => history.push('/')}
      />
      <Button
        type="submit"
        primary
        loading={submittingPassword}
        content="Bekräfta"
        labelPosition="right"
        icon="checkmark"
        disabled={
          password !== repeatPassword ||
          password === '' ||
          repeatPassword === '' ||
          password.length < 6 ||
          repeatPassword.length < 6 ||
          submittingPassword
        }
      />
    </Form>
  );
}

UserManagement.propTypes = {
  location: PropTypes.object,
  firebase: PropTypes.object,
  history: PropTypes.object
};

export default UserManagement;
