/**
 *
 * AccDataTable
 *
 */
import React from 'react';
import extend from 'lodash/extend';
import get from 'lodash/get';
import keys from 'lodash/keys';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';

import { REGISTER_TABLE_SUM_ROWS } from '../../configs';
import { errorCallback } from '../../utils/index';
import MyFallbackComponent from '../MyFallbackComponent';
import { TableContainer } from './styles';

function getKeys(obj) {
  return keys(extend.apply({}, Object.values(obj)));
}

function totalValueByKey(dataObj) {
  const totals = {};

  // Iterate over each key we're interested in
  REGISTER_TABLE_SUM_ROWS.forEach((key) => {
    totals[key] = sumBy(dataObj, (item) => {
      const value = item[key];

      if (typeof value === 'undefined') {
        return 0;
      }

      if (typeof value === 'string') {
        return 0; // or return some default value or handle differently
      }

      if (typeof value === 'number') {
        return value;
      }

      // If it's a string representation of a number
      const parsedValue = parseFloat(value);
      return Number.isNaN(parsedValue) ? 0 : parsedValue;
    });
  });

  return totals;
}

class AccDataTable extends React.Component {
  state = {
    dataObj: {},
    dataKeys: [],
    dataArray: [],
    sortBy: '',
    sortReverse: false
  };

  componentDidMount() {
    this.updateStateFromProps();
  }

  componentDidUpdate() {
    this.updateStateFromProps();
  }

  updateStateFromProps = () => {
    const { obj, columns, sort } = this.props;
    const { dataObj, sortBy } = this.state;

    if (obj !== dataObj) {
      const dataArray = Array.isArray(obj) ? obj : Object.values(obj);
      this.setState({
        dataObj: obj,
        dataArray,
        dataKeys: columns || getKeys(obj)
      });
    }
    if (sortBy === '' && sort && sortBy !== sort) {
      this.setState({
        sortBy: sort
      });
    }
  };

  handleSortClick = (name, sortBy) => () => {
    this.setState((prev) => ({
      sortBy: name,
      sortReverse: sortBy === name ? !prev.sortReverse : false
    }));
  };

  renderHeaderRow = () => {
    const { dataKeys, sortBy, sortReverse } = this.state;
    return dataKeys?.map((cell) => {
      // ['tent', 'Tältyta']
      const name = Array.isArray(cell) ? cell[0] : cell; // 'tent'
      const label = Array.isArray(cell) ? cell[1] : cell; // 'Tältyta'
      return (
        <th
          key={name}
          className={
            (sortBy === name ? 'sorted' : '') +
            (sortBy === name && sortReverse ? ' reversed' : '')
          }
          role="presentation"
          onClick={this.handleSortClick(name, sortBy)}
        >
          {label}
        </th>
      );
    });
  };

  renderRow = (rowObj) => {
    const { dataKeys } = this.state;
    const { transform, columnClick, addClass } = this.props;

    return dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;

      const func = get(columnClick, name);

      const dataString =
        typeof transform === 'function' && rowObj[name]
          ? transform(rowObj[name])
          : rowObj[name];

      const classes =
        typeof addClass === 'function' ? addClass(dataString) : '';

      return (
        <td
          key={name}
          onClick={(e) => {
            e.preventDefault();
            if (typeof func === 'function') {
              func(rowObj);
            }
          }}
          className={
            typeof func === 'function' ? `hasClick ${classes || ''}` : classes
          }
        >
          {dataString && typeof dataString !== 'object' ? dataString : ''}
        </td>
      );
    });
  };

  // sums up the values in the footer row
  renderFooterRow = (dataObj) => {
    const { dataKeys } = this.state;

    const totals = totalValueByKey(dataObj); // gets totals
    const footerRowData = dataKeys?.map((cell) => {
      const name = Array.isArray(cell) ? cell[0] : cell;
      return (
        <th key={name} style={{ fontSize: '1rem' }}>
          <b>{totals[name] ? totals[name].toLocaleString('sv-SE') : ''}</b>
        </th>
      );
    });

    footerRowData[0] = (
      <th key="Sammanfattning" style={{ fontSize: '1rem' }}>
        <b>
          <i>Sammanfattning</i>
        </b>
      </th>
    );

    return footerRowData;
  };

  renderTable = () => {
    const { dataArray, sortBy, sortReverse } = this.state;
    const { filter } = this.props;

    let filteredArrayData = [...dataArray];

    //  filter data
    if (typeof filter === 'function') {
      filteredArrayData = filteredArrayData?.filter(filter);
    }
    //  sort data
    if (sortBy !== '') {
      if (sortReverse) {
        filteredArrayData.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1));
      } else {
        filteredArrayData.sort((a, b) => (a[sortBy] >= b[sortBy] ? 1 : -1));
      }
    }

    // todo: pass this filteredArrayData to download button

    const { uniqKey = 'authId' } = this.props;

    if (filteredArrayData.length > 0) {
      return (
        <TableContainer className="acc-data-table">
          <table>
            <thead>
              <tr>{this.renderHeaderRow()}</tr>
            </thead>
            <tbody>
              {filteredArrayData?.map((rowData, index) => (
                <tr key={`${rowData[uniqKey]}-${index}`}>
                  {this.renderRow(rowData)}
                </tr>
              ))}
            </tbody>
            <thead>
              <tr>{this.renderHeaderRow()}</tr>
            </thead>
            <tfoot>
              <tr>{this.renderFooterRow(filteredArrayData)}</tr>
            </tfoot>
          </table>
        </TableContainer>
      );
    }

    return (
      <TableContainer className="acc-data-table">
        <table>
          <thead>
            <tr>{this.renderHeaderRow()}</tr>
          </thead>
          <tfoot>
            <tr>{this.renderFooterRow(filteredArrayData)}</tr>
          </tfoot>
        </table>
      </TableContainer>
    );
  };

  render() {
    return this.renderTable();
  }
}

AccDataTable.propTypes = {
  obj: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  filter: PropTypes.func,
  sort: PropTypes.string,
  transform: PropTypes.func,
  columnClick: PropTypes.object,
  addClass: PropTypes.func,
  uniqKey: PropTypes.string
};

export default withErrorBoundary(
  AccDataTable,
  MyFallbackComponent,
  errorCallback
);
