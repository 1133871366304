import styled from 'styled-components';

import { JarvaButtonBasic } from '../../styledComponents';

const SelectProducts = styled.div`
  display: flex;
  gap: 1rem;

  a {
    ${JarvaButtonBasic};
    background-color: var(--button-grey);
    color: var(--jarva-blue);
    font-size: var(--font-size--subheading);
    &:hover {
      background-color: var(--jarva-blue);
      color: white;
    }
  }
`;

export { SelectProducts };
