/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';

import { editReducer } from './components/ContentEditToggle/reducers';
import customClaimsProvider from './containers/App/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    claims: customClaimsProvider,
    edit: editReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    ...injectedReducers
  });

  return rootReducer;
}
