/**
 *
 * SideBar
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { vars } from '../../styledComponents';
import {
  BookingIcon,
  IosCalendarOutline,
  IosContactOutline,
  IosHomeOutline,
  IosInformationCircleOutline,
  IosSettingsOutline
} from '../Icons/index';

const BokaRegex = /^\/user\/book(\/.*)?$/;

const Menu = styled.nav`
  padding-top: 2rem;
  @media ${vars.ipadAndUp} {
    padding-top: 3.85rem;
  }

  background-color: var(--jarva-light-back);

  a {
    display: inline-flex;
    padding: 10px 10px;
    color: ${vars.textGreyLight};
    overflow: hidden;
    position: relative;
    font-size: var(--font-size--subheading);
    text-decoration: none;
    gap: 0.63rem;
    @media ${vars.desktopAndUp} {
      display: flex;
      padding: 13px 1.21rem;
      padding-right: 90px;
    }

    &.active {
      background-color: var(--button-grey);
    }
    :hover {
      background-color: var(--button-grey);
      text-decoration: none;
    }
  }
`;

const DropdownContainer = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  & > a {
    @media ${vars.desktopAndUp} {
      padding-left: 40px;
    }
  }
`;

function UserSideBar(props) {
  const {
    home,
    account,
    minaAccount,
    book,
    currentPath,
    information,
    isApproved
  } = props;

  return (
    <Menu>
      <NavLink exact to={home}>
        <IosHomeOutline />
        Hem
      </NavLink>
      <NavLink exact to={account}>
        <IosContactOutline />
        Mitt konto
      </NavLink>
      <NavLink exact to={minaAccount}>
        <IosCalendarOutline />
        Mina bokningar
      </NavLink>
      <NavLink exact to={book}>
        <BookingIcon />
        Boka
      </NavLink>
      <DropdownContainer show={BokaRegex.test(currentPath) && isApproved}>
        <NavLink to={`${book}/tent`}>Tältyta</NavLink>
        <NavLink to={`${book}/seminar`}>Seminarium – utan livesändning</NavLink>
        <NavLink to={`${book}/seminar-live`}>
          Seminarium – med livesändning
        </NavLink>
        <NavLink to={`${book}/sponsor`}>Partnerskap</NavLink>
        <NavLink to={`${book}/torghandel`}>Torghandel</NavLink>
        <NavLink to={`${book}/foodtruck`}>Foodtruck</NavLink>
        <NavLink to={`${book}/tillagg`}>Övriga produkter och tjänster</NavLink>
      </DropdownContainer>
      <NavLink to={information}>
        <IosInformationCircleOutline />
        Information
      </NavLink>
    </Menu>
  );
}

UserSideBar.propTypes = {
  home: PropTypes.string,
  account: PropTypes.string,
  minaAccount: PropTypes.string,
  information: PropTypes.string,
  book: PropTypes.string,
  currentPath: PropTypes.string,
  isApproved: PropTypes.bool
};

function AdminSideBar({
  home,
  applicantsUrl,
  applicants,
  register,
  bookings,
  seminarlist,
  seminarlivelist,
  settings,
  resetDatabase,
  productsSettings
}) {
  return (
    <Menu>
      <NavLink exact to={home}>
        <IosHomeOutline />
        Hem
      </NavLink>
      <NavLink to={applicantsUrl}>
        <IosContactOutline />
        Ansökningar{' '}
        {applicants > 0 ? (
          <span className="notification-circle">{applicants}</span>
        ) : (
          ''
        )}
      </NavLink>
      <NavLink to={register}>
        <IosContactOutline />
        Register
      </NavLink>
      <NavLink to={bookings}>
        <BookingIcon />
        Bokningar
      </NavLink>
      <NavLink to={seminarlist}>
        <BookingIcon />
        Seminarlist
      </NavLink>
      <NavLink to={seminarlivelist}>
        <BookingIcon />
        Seminarlivelist
      </NavLink>
      <NavLink to={settings}>
        <IosInformationCircleOutline />
        Inställningar
      </NavLink>
      <NavLink to={productsSettings}>
        <IosSettingsOutline />
        Products Settings
      </NavLink>
      <NavLink to={resetDatabase}>
        <IosSettingsOutline />
        Reset Products DB
      </NavLink>
    </Menu>
  );
}

AdminSideBar.propTypes = {
  home: PropTypes.string,
  applicantsUrl: PropTypes.string,
  register: PropTypes.string,
  settings: PropTypes.string,
  applicants: PropTypes.number,
  productsSettings: PropTypes.string,
  resetDatabase: PropTypes.string,
  seminarlist: PropTypes.string,
  seminarlivelist: PropTypes.string,
  bookings: PropTypes.string
};

export { UserSideBar, AdminSideBar };
