/**
 *
 * AdminHome
 *
 */
import React, { Fragment } from 'react';
import { groupBy } from 'lodash';
import constant from 'lodash/constant';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import times from 'lodash/times';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { isLoaded } from 'react-redux-firebase';
import { Button, Modal } from 'semantic-ui-react';

import MyFallbackComponent from '../../components/MyFallbackComponent';
import SeminarStats from '../../components/SeminarStats';
import { ContentContainer, WhiteBox, DataBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import { AdminHomeContainer } from './adminHomeStyles';
import RenderBookings from './RenderBookings';

export class AdminHome extends React.Component {
  state = {
    modalOpen: false,
    modalContent: null
  };

  render() {
    const {
      users,
      history,
      bookings,
      tillaggBookings,
      productBookings,
      sponsorBookings,
      seminarsBookings,
      seminarsLiveBookings,
      stats,
      products
    } = this.props;

    const { modalOpen, modalContent } = this.state;

    //  #region  seminar
    let maxSeminars = 1;
    const availableTimeSlots = get(stats, 'seminars.availableTimeSlots');
    if (availableTimeSlots) {
      maxSeminars = reduce(
        availableTimeSlots,
        (acc, curr) =>
          curr.reduce((acc2, curr2) => {
            const totalValue = acc2 + curr2;
            return totalValue;
          }, acc),
        0
      );
    }
    //  #endregion seminar

    //  #region  seminarLive
    let maxSeminarsLive = 1;

    const availableTimeSlotsLive = get(
      stats,
      'seminarsLive.availableTimeSlots'
    );

    if (availableTimeSlotsLive) {
      maxSeminarsLive = reduce(
        availableTimeSlotsLive,
        (acc, curr) =>
          curr.reduce((acc2, curr2) => {
            const totalValue = acc2 + curr2;
            return totalValue;
          }, acc),
        0
      );
    }
    //  #endregion seminarLive

    const allTents = { ...bookings };

    const tentsPerPeriod = {
      tent2_5x2_5_P1: [],
      tent2_5x2_5_P2: [],
      tent5x2_5_P1: [],
      tent5x2_5_P2: [],
      tent5x5_P1: [],
      tent5x5_P2: []
    };

    const tentsPerPeriodTotal = {
      totalPeriod1_tent2_5x2_5: 0,
      totalPeriod2_tent2_5x2_5: 0,
      totalPeriod1_tent5x2_5: 0,
      totalPeriod2_tent5x2_5: 0,
      totalPeriod1_tent5x5: 0,
      totalPeriod2_tent5x5: 0
    };

    //  #region stats max
    const p1MaxTent2_5x2_5 = get(stats, 'tent2_5x2_5.p1Max');
    const p2MaxTent2_5x2_5 = get(stats, 'tent2_5x2_5.p2Max');

    const p1MaxTent5x2_5 = get(stats, 'tent5x2_5.p1Max');
    const p2MaxTent5x2_5 = get(stats, 'tent5x2_5.p2Max');

    const p1MaxTent5x5 = get(stats, 'tent5x5.p1Max');
    const p2MaxTent5x5 = get(stats, 'tent5x5.p2Max');

    // sponsorBookings loop and group with product id
    const { sponsor: sponsorBookings1, sponsor2: sponsorBookings2 } =
      groupBy(sponsorBookings, 'productId') || {};

    const maxSponsor = get(stats, 'sponsor.max');
    const totalSponsor1 = size(sponsorBookings1);

    const maxSponsor2 = get(stats, 'sponsor2.max');
    const totalSponsor2 = size(sponsorBookings2);
    // #endregion

    if (isLoaded(bookings)) {
      if (allTents) {
        Object.values(allTents).forEach((item) => {
          if (isEmpty(item)) {
            return;
          }

          const { productId, quantity, period1, period2 } = item || {};

          if (period1) {
            const selectId = `${productId}_P1`;
            tentsPerPeriod[selectId].push(item);

            const totalId = `totalPeriod1_${productId}`;
            const currentTotal = tentsPerPeriodTotal[totalId];
            tentsPerPeriodTotal[totalId] = +currentTotal + quantity * 1;
          }

          if (period2) {
            const selectId = `${productId}_P2`;
            tentsPerPeriod[selectId].push(item);

            const totalId = `totalPeriod2_${productId}`;
            const currentTotal = tentsPerPeriodTotal[totalId];
            tentsPerPeriodTotal[totalId] = +currentTotal + quantity * 1;
          }
        });
      }
    }

    // console.log('tentsPerPeriod', tentsPerPeriod);
    const renderModel = () => (
      <Modal
        dimmer="inverted"
        open={modalOpen}
        closeOnDimmerClick
        closeOnDocumentClick
        size="large"
        style={{ maxWidth: 400 }}
        onClose={() => {
          this.setState({
            modalContent: null,
            modalOpen: false
          });
        }}
      >
        <Modal.Content scrolling>
          <WhiteBox style={{ padding: '10px', margin: 0 }}>
            <DataBox>{modalContent}</DataBox>
          </WhiteBox>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              this.setState({
                modalContent: null,
                modalOpen: false
              });
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );

    //  stats
    const renderStats = () => (
      <ul>
        <li>
          <div className="label">
            <h6>Organisationer: </h6>
            {size(users)}
          </div>
        </li>
        <li>
          <div className="label">
            <h6>Bokningar: </h6>
            <ul className="extra">
              <li>
                Seminarietider: ({size(seminarsBookings) || 0} av {maxSeminars})
              </li>
              <li>
                Seminarietider Live: ({size(seminarsLiveBookings) || 0} av{' '}
                {maxSeminarsLive})
              </li>
              <li>
                Stödorganisation: ({size(totalSponsor1) || 0} av {maxSponsor})
              </li>
              <li>
                Partner: ({size(totalSponsor2) || 0} av {maxSponsor2})
              </li>
            </ul>
          </div>
        </li>
      </ul>
    );

    // seminar bookings
    const renderSeminars = () => (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <br />
            <br />
            <br />
            <h4>Bokade seminarietider</h4>
          </li>
        </ul>
        <SeminarStats
          seminars={{ ...seminarsBookings }}
          {...get(this, 'props.products.seminars')}
          available={{ ...get(stats, 'seminars.availableTimeSlots', []) }}
          updateModal={(stateToUpdate) => {
            this.setState(stateToUpdate);
          }}
        />
      </>
    );

    // seminar live bookings
    const renderSeminarsLive = () => (
      <>
        <ul>
          <li style={{ border: 0, paddingBottom: 0 }}>
            <h4>Bokade seminarietider Live</h4>
          </li>
        </ul>
        <SeminarStats
          seminars={{ ...seminarsLiveBookings }}
          {...get(this, 'props.products.seminarsLive')}
          available={{ ...get(stats, 'seminarsLive.availableTimeSlots', []) }}
          updateModal={(stateToUpdate) => {
            this.setState(stateToUpdate);
          }}
        />
      </>
    );

    const renderButtons = () => (
      <div className="flex no-gap">
        <Button
          primary
          basic
          icon={{ name: 'home' }}
          labelPosition="right"
          content="Verifiera ansökningar"
          onClick={() => history.push('/admin/applicants')}
        />
        <Button
          primary
          basic
          icon={{ name: 'calendar' }}
          labelPosition="right"
          content=" Se i registret"
          onClick={() => history.push('/admin/register')}
        />
        <Button
          primary
          basic
          icon={{ name: 'setting' }}
          labelPosition="right"
          content="Inställningar"
          onClick={() => history.push('/admin/settings')}
        />
      </div>
    );

    const renderTillaggBookings = () => {
      // tillaggBookingsGroupedById

      if (isEmpty(tillaggBookings)) {
        return null;
      }

      const tillaggBookingsGroupedById = groupBy(tillaggBookings, 'productId');

      const renderProductListBookings = (item, productMax) => {
        return map(item, (item2, key2) => {
          const { quantity = 1, title, userId, organizerName } = item2 || {};

          //  repeat with quantity
          const output = [];

          for (let i = 0; i < quantity; i += 1) {
            output.push(
              <div
                key={`${key2}-${i}`}
                role="button"
                tabIndex={0}
                className="tent"
                onClick={() => {
                  if (!item2) return;
                  const content = (
                    <div>
                      <ul aria-label="Bokning">
                        <li
                          aria-label={`Tillägg (${get(
                            item2,
                            'quantity',
                            1
                          )} st)`}
                        >
                          {title}
                        </li>
                        <li aria-label="Organisationsnamn">{organizerName}</li>
                        <li aria-label="User Id">{userId}</li>
                        <li aria-label="Quantity">
                          {`${get(item2, 'quantity', 1)}`}
                        </li>
                        <li aria-label="Pris">
                          {Number(get(item2, 'pris', '')).toLocaleString('sv')}{' '}
                          kr exkl. moms
                        </li>
                        <li aria-label="Total Pris">
                          {Number(get(item2, 'totalPrice', '')).toLocaleString(
                            'sv'
                          )}{' '}
                          kr exkl. moms
                        </li>
                      </ul>
                    </div>
                  );
                  this.setState({
                    modalOpen: true,
                    modalContent: content
                  });
                }}
              />
            );
          }

          times(productMax - quantity, constant(null))?.forEach((a, i) => {
            output.push(<div className="tent empty" key={i} />);
          });

          return output;
        });
      };

      const renderProductList = map(tillaggBookingsGroupedById, (item, key) => {
        // get title from first item
        const { title } = item[0] || {};

        const productMax = get(products, `${key}.max`, 0);
        const booked = get(products, `${key}.count`, 0);

        return (
          <Fragment key={key}>
            <h5>{title}</h5>
            <p>Maximun allowed= {productMax}</p>
            <p>Current booked= {booked}</p>
            {renderProductListBookings(item, productMax)}
          </Fragment>
        );
      });

      return (
        <>
          <ul>
            <li style={{ border: 0, paddingBottom: 0 }}>
              <h4>Bokade Tillägg</h4>
            </li>
          </ul>
          {renderProductList}
        </>
      );
    };

    const renderproductBookings = () => {
      // productBookingsGroupedById

      if (isEmpty(productBookings)) {
        return null;
      }

      const productBookingsGroupedById = groupBy(productBookings, 'productId');

      const renderProductListBookings = (item, productMax) => {
        return map(item, (item2, key2) => {
          const { quantity = 1, title, userId, organizerName } = item2 || {};

          //  repeat with quantity
          const output = [];

          for (let i = 0; i < quantity; i += 1) {
            output.push(
              <div
                key={`${key2}-${i}`}
                role="button"
                tabIndex={0}
                className="tent"
                onClick={() => {
                  if (!item2) return;
                  const content = (
                    <div>
                      <ul aria-label="Bokning">
                        <li
                          aria-label={`Tillägg (${get(item2, 'quantity', 1)} st)`}
                        >
                          {title}
                        </li>
                        <li aria-label="Organisationsnamn">{organizerName}</li>
                        <li aria-label="User Id">{userId}</li>
                        <li aria-label="Quantity">
                          {`${get(item2, 'quantity', 1)}`}
                        </li>
                        <li aria-label="Pris">
                          {Number(get(item2, 'pris', '')).toLocaleString('sv')}{' '}
                          kr exkl. moms
                        </li>
                        <li aria-label="Total Pris">
                          {Number(get(item2, 'totalPrice', '')).toLocaleString(
                            'sv'
                          )}{' '}
                          kr exkl. moms
                        </li>
                      </ul>
                    </div>
                  );
                  this.setState({
                    modalOpen: true,
                    modalContent: content
                  });
                }}
              />
            );
          }

          times(productMax - quantity, constant(null))?.forEach((a, i) => {
            output.push(<div className="tent empty" key={i} />);
          });

          return output;
        });
      };

      const renderProductList = map(productBookingsGroupedById, (item, key) => {
        // get title from first item
        const { title } = item[0] || {};

        const productMax = get(products, `${key}.max`, 0);
        const booked = get(products, `${key}.count`, 0);

        return (
          <Fragment key={key}>
            <h5>{title}</h5>
            <p>Maximun allowed= {productMax}</p>
            <p>Current booked= {booked}</p>
            {renderProductListBookings(item, productMax)}
          </Fragment>
        );
      });

      return (
        <>
          <ul>
            <li style={{ border: 0, paddingBottom: 0 }}>
              <h4>Bokade Products</h4>
            </li>
          </ul>
          {renderProductList}
        </>
      );
    };

    return (
      <ContentContainer>
        <section className="pink">
          <WhiteBox>
            <h2>Välkommen till Järvaveckans admin!</h2>
            <hr className="h5" />
            <AdminHomeContainer>
              {renderStats()}
              <br />
              <br />
              <ul>
                <li>
                  <h4>Tältyta period 1</h4>
                </li>
                <li>
                  <h4>Tältyta period 2</h4>
                </li>
              </ul>

              <RenderBookings
                {...{
                  setState: this.setState,
                  size: '2,5x2,5',
                  p1Max: p1MaxTent2_5x2_5,
                  p2Max: p2MaxTent2_5x2_5,
                  tentsPerPeriod1: tentsPerPeriod.tent2_5x2_5_P1,
                  tentsPerPeriod2: tentsPerPeriod.tent2_5x2_5_P2,
                  tentsPerPeriod1Total:
                    tentsPerPeriodTotal.totalPeriod1_tent2_5x2_5,
                  tentsPerPeriod2Total:
                    tentsPerPeriodTotal.totalPeriod2_tent2_5x2_5
                }}
              />

              <RenderBookings
                {...{
                  setState: this.setState,
                  size: '5x2,5',
                  p1Max: p1MaxTent5x2_5,
                  p2Max: p2MaxTent5x2_5,
                  tentsPerPeriod1: tentsPerPeriod.tent5x2_5_P1,
                  tentsPerPeriod2: tentsPerPeriod.tent5x2_5_P2,
                  tentsPerPeriod1Total:
                    tentsPerPeriodTotal.totalPeriod1_tent5x2_5,
                  tentsPerPeriod2Total:
                    tentsPerPeriodTotal.totalPeriod2_tent5x2_5
                }}
              />

              <RenderBookings
                {...{
                  setState: this.setState,
                  size: '5x5',
                  p1Max: p1MaxTent5x5,
                  p2Max: p2MaxTent5x5,
                  tentsPerPeriod1: tentsPerPeriod.tent5x5_P1,
                  tentsPerPeriod2: tentsPerPeriod.tent5x5_P2,
                  tentsPerPeriod1Total:
                    tentsPerPeriodTotal.totalPeriod1_tent5x5,
                  tentsPerPeriod2Total: tentsPerPeriodTotal.totalPeriod2_tent5x5
                }}
              />

              <RenderBookings
                {...{
                  title: 'Stödorganisation ',
                  setState: this.setState,
                  size: '5x5',
                  p1Max: maxSponsor,
                  p2Max: maxSponsor,
                  tentsPerPeriod1: sponsorBookings1,
                  tentsPerPeriod2: sponsorBookings1,
                  tentsPerPeriod1Total: totalSponsor1,
                  tentsPerPeriod2Total: totalSponsor1
                }}
              />

              <RenderBookings
                isSponsor
                {...{
                  title: 'Partner ',
                  setState: this.setState,
                  size: '5x5',
                  p1Max: maxSponsor2,
                  p2Max: maxSponsor2,
                  tentsPerPeriod1: sponsorBookings2,
                  tentsPerPeriod2: sponsorBookings2,
                  tentsPerPeriod1Total: totalSponsor2,
                  tentsPerPeriod2Total: totalSponsor2
                }}
              />
              {renderTillaggBookings()}
              {renderproductBookings()}
              {renderSeminars()}
              {renderSeminarsLive()}
            </AdminHomeContainer>
            {renderButtons()}
          </WhiteBox>
        </section>
        {renderModel()}
      </ContentContainer>
    );
  }
}

AdminHome.propTypes = {
  users: PropTypes.object,
  history: PropTypes.object,
  bookings: PropTypes.object,
  tillaggBookings: PropTypes.object,
  productBookings: PropTypes.object,
  sponsorBookings: PropTypes.object,
  seminarsBookings: PropTypes.object,
  seminarsLiveBookings: PropTypes.object,
  stats: PropTypes.object,
  products: PropTypes.object
};

export default withErrorBoundary(AdminHome, MyFallbackComponent, errorCallback);
