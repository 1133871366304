import styled from 'styled-components';

import { JarvaDivider } from '../../styledComponents';

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 20px 0;
`;

const StyledTextWrapper = styled.div`
  > p {
    padding: 20px 20px 20px 0px;
    max-width: 850px;
    margin-bottom: 0;
    svg {
      margin-bottom: 20px;
    }
  }
`;
const PaddedJarvaDivider = styled(JarvaDivider)`
  margin: 20px 0;
`;

const Paragraph = styled.p`
  padding: 50px 30px 50px 0px;
  max-width: 850px;
`;

export { StyledTextWrapper, PaddedJarvaDivider, Paragraph, ButtonContainer };
