/**
 *
 * AdminDashBoard
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import debounceRender from 'react-debounce-render';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { firestoreConnect, withFirestore } from 'react-redux-firebase';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import MyFallbackComponent from '../../components/MyFallbackComponent';
import { AdminSideBar } from '../../components/SideBar';
import { errorCallback } from '../../utils/index';
import AdminHome from '../AdminHome';
import AdminRegister from '../AdminRegister';
import AdminSettings from '../AdminSettings';
import {
  getAuth,
  getNotApprovedUsers,
  getOrderedUsers,
  getProfile,
  getStats,
  getOrderedBookings,
  getOrderedTillaggBookings,
  getOrderedSeminarBookings,
  getOrderedSeminarLiveBookings,
  getOrderedSponsorBookings,
  getUsers,
  getBookings,
  getTillaggBookings,
  getSeminarsBookings,
  getSeminarsLiveBookings,
  getSponsorBookings,
  getProducts,
  getProductBookings,
  getOrderedProductBookings
} from '../App/selectors';
import Applicants from '../Applicants';
import BookingRegister from '../BookingRegister';
import ResetDatabase from '../ResetDatabase';
import ProductsSettings from '../ProductsSettings/index';
import SeminarLivePlacementList from '../SeminarLivePlacementList';
import SeminarPlacementList from '../SeminarPlacementList';

export class AdminDashBoard extends React.Component {
  render() {
    // console.log('AdminDashBoard props', this.props);

    const {
      match: { path: _path, url },
      applicants,
      firestore,
      claims,
      orderedSeminarBookings,
      orderedSeminarLiveBookings,
      products,
      stats
    } = this.props;

    return claims.admin ? (
      <div className="columns">
        <AdminSideBar
          className="sidenav"
          home={`${url}`}
          applicantsUrl={`${url}/applicants`}
          book={`${url}/book`}
          register={`${url}/register`}
          settings={`${url}/settings`}
          resetDatabase={`${url}/reset-database`}
          seminarlist={`${url}/seminarlist`}
          seminarlivelist={`${url}/seminarlivelist`}
          bookings={`${url}/bookings`}
          productsSettings={`${url}/products-settings`}
          applicants={applicants.length}
        />
        <div className="main">
          <Switch>
            <Route
              exact
              path={`${_path}`}
              render={(props) => <AdminHome {...props} {...this.props} />}
            />
            <Route
              path={`${_path}/applicants`}
              render={() => (
                <Applicants applicants={applicants} firestore={firestore} />
              )}
            />
            <Route
              path={`${_path}/register`}
              render={(props) => <AdminRegister {...props} {...this.props} />}
            />
            <Route
              path={`${_path}/bookings`}
              render={(props) => <BookingRegister {...props} {...this.props} />}
            />
            <Route
              path={`${_path}/seminarlist`}
              render={() => (
                <SeminarPlacementList
                  orderedSeminarBookings={orderedSeminarBookings}
                  firestore={firestore}
                />
              )}
            />
            <Route
              path={`${_path}/seminarlivelist`}
              render={() => (
                <SeminarLivePlacementList
                  orderedSeminarLiveBookings={orderedSeminarLiveBookings}
                  firestore={firestore}
                />
              )}
            />
            <Route
              path={`${_path}/settings`}
              render={() => (
                <AdminSettings
                  stats={stats}
                  products={products}
                  firestore={firestore}
                />
              )}
            />
            <Route
              path={`${_path}/reset-database`}
              render={() => <ResetDatabase claims={claims} />}
            />
            <Route
              path={`${_path}/products-settings`}
              render={() => <ProductsSettings />}
            />
            <Redirect to={`${_path}`} />
          </Switch>
        </div>
      </div>
    ) : (
      <Redirect to="/user" />
    );
  }
}

AdminDashBoard.propTypes = {
  match: PropTypes.object,
  claims: PropTypes.object,
  history: PropTypes.object,
  firestore: PropTypes.object,
  applicants: PropTypes.array,
  stats: PropTypes.object,
  orderedUsers: PropTypes.array,
  orderedBookings: PropTypes.array,
  orderedSeminarBookings: PropTypes.array,
  orderedSeminarLiveBookings: PropTypes.array,
  orderedSponsorBookings: PropTypes.array,
  products: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  profile: getProfile,
  auth: getAuth,
  applicants: getNotApprovedUsers,
  users: getUsers,
  bookings: getBookings,
  tillaggBookings: getTillaggBookings,
  seminarsBookings: getSeminarsBookings,
  seminarsLiveBookings: getSeminarsLiveBookings,
  sponsorBookings: getSponsorBookings,
  productBookings: getProductBookings,
  orderedUsers: getOrderedUsers,
  orderedBookings: getOrderedBookings,
  orderedTillaggBookings: getOrderedTillaggBookings,
  orderedProductBookings: getOrderedProductBookings,
  orderedSeminarBookings: getOrderedSeminarBookings,
  orderedSeminarLiveBookings: getOrderedSeminarLiveBookings,
  orderedSponsorBookings: getOrderedSponsorBookings,
  stats: getStats,
  products: getProducts
});

const withConnect = connect(mapStateToProps);

const getFirebaseCollections = () => [
  'users',
  'bookings',
  'tillaggBookings',
  'seminarBookings',
  'seminarLiveBookings',
  'sponsorBookings',
  {
    collection: 'products',
    orderBy: ['order', 'asc']
  },
  'stats',
  'seminarPlacementList',
  'seminarLivePlacementList',
  'productBookings'
];

export default compose(
  withFirestore,
  withConnect,
  firestoreConnect((props) => getFirebaseCollections(props))
)(
  withErrorBoundary(debounceRender(AdminDashBoard, 100)),
  MyFallbackComponent,
  errorCallback
);
