/**
 *
 * Account
 *
 */
import React from 'react';
import { isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import debounceRender from 'react-debounce-render';
import { withErrorBoundary } from 'react-error-boundary';
import { Button, Form, Grid, Label, Message, Modal } from 'semantic-ui-react';

import { uploadFileToStorage } from '../../api';
import ContentEditor from '../../components/ContentEditor';
import FileOrImagePreview from '../../components/FileOrImagePreview/FileOrImagePreview';
import { IosContactOutline } from '../../components/Icons';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import { ORGANISATION_OPTIONS } from '../../configs';
import {
  ContentContainer,
  DataBox,
  PasswordError,
  WhiteBox
} from '../../styledComponents';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';
import { errorCallback, transformNgoText } from '../../utils/index';
import UserBookings from '../UserBookings';
import Wrapper from './style';

const INITIAL_STATE = {
  readOnlyCurrentUser: '',
  stateCurrentUser: {},
  isDeleteClicked: false,
  isSaveClicked: false,
  uploading: false,
  password: '',
  repeatPassword: '',
  submittingPassword: false,
  disableInput: false,
  isPasswordSuccess: false,
  email: '',
  oldPassword: '',
  cosponsors: {},
  loading: false
};

export class Account extends React.Component {
  state = {
    ...INITIAL_STATE,
    tentsOpen: {},
    seminarOpen: {},
    seminarLiveOpen: {},
    profileOpen: false,
    userOpen: false,
    sponsorOpen: false,
    modalOpen: false,
    uploadingCurrentUserBookings: false
  };

  isAdmin = get(this, 'props.claims.admin', false);

  componentDidUpdate() {
    const { stateCurrentUser, loading } = this.state;

    if (loading) {
      return;
    }

    const { currentUser } = this.props;

    if (isEmpty(stateCurrentUser) && !isEmpty(currentUser)) {
      this.setState({ stateCurrentUser: { ...currentUser } });
    }
  }

  toggleState = (stateKey, key) => {
    if (key) {
      this.setState((prev) => ({
        [stateKey]: {
          ...prev[stateKey],
          [key]: !prev[stateKey][key]
        }
      }));
    } else {
      this.setState((prev) => ({
        [stateKey]: !prev[stateKey]
      }));
    }
  };

  toggleReadOnlyMode =
    (readOnlyId, bookingID = '') =>
    () => {
      const { isSaveClicked } = this.state;

      if (isSaveClicked) {
        this.setState({
          isSaveClicked: false
        });
      }

      if (bookingID && readOnlyId !== 'readOnlyCurrentUser') {
        this.setState((prev) => ({
          [readOnlyId]: {
            ...prev[readOnlyId],
            [bookingID]: !prev[readOnlyId][bookingID]
          }
        }));
      } else {
        this.setState((prev) => ({
          [readOnlyId]: !prev[readOnlyId]
        }));
      }
    };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handlePasswordInputChange = ({ target: { name, value } }) =>
    this.setState({ [name]: value });

  changePassword = () => {
    this.setState({ disableInput: true, submittingPassword: true });
    const { password, repeatPassword, oldPassword } = this.state;
    const {
      firebase,
      currentUser: { email }
    } = this.props;
    const user = firebase.auth().currentUser;
    if (password !== repeatPassword) {
      this.setState({
        passwordError: {
          code: 'Password not right',
          message: 'Must have 6 char'
        }
      });
      return;
    }

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );

    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        user
          .updatePassword(repeatPassword)
          .then(() => {
            this.setState({
              isPasswordSuccess: true,
              submittingPassword: false
            });
            setTimeout(this.handleClose, 2000);
          })
          .catch((error) => {
            this.setState({
              passwordError: error,
              isPasswordError: true,
              disableInput: false,
              submittingPassword: false
            });
          });
      })
      .catch((error) => {
        this.setState({
          passwordError: error,
          isPasswordError: true,
          disableInput: false,
          submittingPassword: false
        });
      });
  };

  //  #region saveToDatabase
  saveToDatabase = (args) => async () => {
    const {
      databasePath,
      bookingId,
      reduxPath,
      data,
      stateDataId,
      readOnlyId
    } = args;

    this.toggleReadOnlyMode(readOnlyId, bookingId)();
    const { [stateDataId]: isDataChanged } = this.state;

    if (databasePath && !isEmpty(isDataChanged)) {
      const { firestore, firebase } = this.props;

      // console.log('data to be saved', data);

      this.setState({
        loading: true,
        isSaveClicked: true
      });

      const cleanedData = clearDateBeforeSaving(data);

      const { discount, userId } = data;

      const newDiscount = discount && Math.abs(discount);

      const newDataToSave = {
        ...cleanedData,
        discount: newDiscount || 0,
        updateAt: firestore.FieldValue.serverTimestamp()
      };

      const fileListItems = [];
      // Assuming 'data' is an object that includes all the file inputs
      Object.entries(data).forEach(([name, value]) => {
        if (value instanceof FileList) {
          fileListItems.push({ name, value });
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const item of fileListItems) {
        if (!item) {
          return;
        }

        const { name, value } = item;

        try {
          let downloadURL = '';

          // for user profile there is no userId
          if (name === 'logo' && !userId) {
            const tempUserId = userId || bookingId; // for user profile there is no userId
            // eslint-disable-next-line no-await-in-loop
            downloadURL = await uploadFileToStorage({
              uploadPath: `uploads/company_logo/${tempUserId}`,
              uid: tempUserId,
              fileList: value,
              firebase
            });
          } else {
            // eslint-disable-next-line no-await-in-loop
            downloadURL = await uploadFileToStorage({
              uid: userId,
              fileList: value,
              firebase
            });
          }

          if (downloadURL) {
            newDataToSave[name] = downloadURL || '';
          }
        } catch (error) {
          console.error(name, error);
        } finally {
          console.info(name, 'request finished');
        }
      }

      // console.log('Account newDataToSave', newDataToSave);

      // firebase save function
      firestore
        .update(
          {
            collection: databasePath,
            doc: bookingId,
            path: reduxPath || databasePath
          },
          newDataToSave
        )
        .then(() => {
          console.info('saved successfully');
          /* TODO:check if it causes any issues */
          // this.setState({ [stateDataId]: {} });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error });
        })
        .finally(() => {
          this.setState({
            loading: false
          });

          const { authId } = this.props;

          if (reduxPath === 'currentUserSeminarsBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSeminarsLiveBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }

          if (reduxPath === 'currentUserSponsorBookings') {
            firestore
              .get({
                collection: databasePath,
                path: reduxPath,
                where: ['userId', '==', authId]
              })
              .catch((err) => console.error(err));
          }
        });
    }
  };
  //  #endregion

  //  #region handleCancel
  handleCancel = () => {
    const { registerState } = this.props;
    this.setState(
      () => ({
        ...INITIAL_STATE
      }),
      () => {
        if (registerState) registerState();
      }
    );
  };
  //  #endregion

  //  #region handleInputChange
  handleInputChange = (readOnlyId) => {
    const { isSaveClicked } = this.state;
    if (isSaveClicked) {
      this.setState({
        isSaveClicked: false
      });
    }

    return (event) => {
      const {
        target: { name, value, files, checked }
      } = event;

      if (checked) {
        return this.setState((state) => {
          return {
            [readOnlyId]: {
              ...state[readOnlyId],
              [name]: checked
            }
          };
        });
      }

      if (name === 'fullName') {
        const [firstName = '', secondName = ''] = value.trim().split(' ');
        return this.setState((state) => ({
          [readOnlyId]: {
            ...state[readOnlyId],
            firstName,
            secondName,
            fullName: value
          }
        }));
      }

      this.setState((state) => {
        return {
          [readOnlyId]: {
            ...state[readOnlyId],
            [name]: files || value
          }
        };
      });
    };
  };
  //  #endregion

  //  #region renderUserDetails
  renderUserDetails = (currentUser) => {
    if (isEmpty(currentUser)) return;
    const {
      stateCurrentUser,
      readOnlyCurrentUser: stateReadonly,
      isSaveClicked,
      loading
    } = this.state;
    const { authId, isFromUser } = this.props;
    const readOnlyCurrentUser = !stateReadonly;
    const databasePath = 'users';
    const reduxPath = isFromUser ? 'currentUser' : '';

    const newData = {
      ...currentUser,
      ...stateCurrentUser
    };

    const {
      email = '',
      fakturaEmail = '',
      fakturaadress = '',
      fakturamottagare = '',
      firstName = '',
      orgName = '',
      orgNr = '',
      ort = '',
      postnummer = '',
      secondName = '',
      telefonnummer = '',
      titel = '',
      typeOfOrg = '',
      fullName = '',
      organizerName = '',
      beskriv = '',
      hemsida,
      fb,
      instagram,
      linkedin,
      twitter,
      logo
      // isPublishedOnWeb = true
    } = newData;

    return (
      <WhiteBox key={email}>
        <DataBox>
          <h3
            onClick={(e) => {
              e.preventDefault();
              this.toggleState('userOpen');
            }}
          >
            <IosContactOutline />
            Kontouppgifter - ej synliga utåt
          </h3>
          <Form
            loading={loading}
            className="horizontal-container"
            onSubmit={this.saveToDatabase({
              databasePath,
              reduxPath,
              data: newData,
              bookingId: authId,
              stateDataId: 'stateCurrentUser',
              readOnlyId: 'readOnlyCurrentUser'
            })}
          >
            <Grid stackable columns="equal" stretched>
              <Grid.Row>
                <Grid.Column>
                  <ul aria-label="Kontaktuppgifter">
                    <li aria-label="Namn (Kontaktperson)">
                      <Form.Input
                        maxLength="120"
                        type="text"
                        name="fullName"
                        value={fullName || `${firstName} ${secondName}`}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="E-post (Konto)">
                      <Form.Input
                        maxLength="90"
                        type="email"
                        name="email"
                        value={email || ''}
                        // readOnly={
                        //   this.isAdmin && !isFromUser
                        //     ? readOnlyCurrentUser
                        //     : true
                        // }
                        // readOnly={readOnlyCurrentUser}
                        readOnly
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Befattning / Titel">
                      <Form.Input
                        maxLength="90"
                        type="text"
                        name="titel"
                        value={titel || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Telefon">
                      <Form.Input
                        maxLength="20"
                        type="text"
                        name="telefonnummer"
                        value={telefonnummer || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Organisationsuppgifter">
                    <li aria-label="Företag">
                      <Form.Input
                        maxLength="90"
                        type="text"
                        name="orgName"
                        value={orgName || ''}
                        readOnly={
                          this.isAdmin && !isFromUser
                            ? readOnlyCurrentUser
                            : true
                        }
                        // readOnly={readOnlyCurrentUser}
                        // readOnly
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Organisationsnummer">
                      <Form.Input
                        maxLength="20"
                        aria-label="Organisationsnummer (i formatet 802481–1658)"
                        required
                        id="orgNr"
                        name="orgNr"
                        type="text"
                        value={orgNr || ''}
                        readOnly={
                          this.isAdmin && !isFromUser
                            ? readOnlyCurrentUser
                            : true
                        }
                        // readOnly={readOnlyCurrentUser}
                        // readOnly
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Organisationstyp">
                      <Label>{transformNgoText(typeOfOrg)}</Label>
                      <Form.Select
                        onChange={(_, target) => {
                          const { name, value } = target;

                          this.setState((prevState) => {
                            const updateState = {
                              stateCurrentUser: {
                                ...prevState.stateCurrentUser,
                                [name]: value
                              }
                            };
                            return updateState;
                          });
                        }}
                        required
                        id="typeOfOrg"
                        name="typeOfOrg"
                        options={ORGANISATION_OPTIONS}
                        value={transformNgoText(typeOfOrg) || ''}
                        disabled={readOnlyCurrentUser}
                      />
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Fakturauppgifter">
                    <li aria-label="Er referens">
                      <Form.Input
                        required
                        maxLength="120"
                        type="text"
                        name="fakturamottagare"
                        value={fakturamottagare || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Fakturaadress">
                      <Form.Input
                        maxLength="160"
                        type="text"
                        name="fakturaadress"
                        value={fakturaadress || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Postnummer">
                      <Form.Input
                        maxLength="120"
                        type="text"
                        name="postnummer"
                        value={postnummer || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Ort">
                      <Form.Input
                        maxLength="120"
                        type="text"
                        name="ort"
                        value={ort || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="E-post för PDF faktura">
                      <Form.Input
                        maxLength="120"
                        type="email"
                        required
                        name="fakturaEmail"
                        value={fakturaEmail || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <br />
                  <br />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h3>
                    <ContentEditor
                      inline
                      contentId="page-user-account-section-2-title"
                    >
                      Uppgifter som kommer synas och presenteras i digitala och
                      tryckta kanaler
                    </ContentEditor>
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <ul aria-label="Organisationsuppgifter">
                    <li aria-label="Namn på arrangör/organisation (max 70 tecken)">
                      <Form.Input
                        maxLength="120"
                        required
                        type="text"
                        name="organizerName"
                        value={organizerName || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                    <li aria-label="Beskrivning av er organisation (max 800 tecken)">
                      <Form.TextArea
                        maxLength="800"
                        name="beskriv"
                        value={beskriv || ''}
                        readOnly={readOnlyCurrentUser}
                        onChange={this.handleInputChange('stateCurrentUser')}
                      />
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Social media">
                    <li aria-label="Hemsida (hela URL - https:// )">
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange('stateCurrentUser')}
                        readOnly={readOnlyCurrentUser}
                        id="hemsida"
                        name="hemsida"
                        // type="url"
                        value={hemsida || ''}
                      />
                    </li>
                    <li aria-label="Facebook (hela URL - https:// )">
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange('stateCurrentUser')}
                        readOnly={readOnlyCurrentUser}
                        id="fb"
                        name="fb"
                        // type="url"
                        value={fb || ''}
                      />
                    </li>
                    <li aria-label="Instagram (hela URL - https:// )">
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange('stateCurrentUser')}
                        readOnly={readOnlyCurrentUser}
                        id="instagram"
                        name="instagram"
                        // type="url"
                        value={instagram || ''}
                      />
                    </li>
                    <li aria-label="Linkedin (hela URL - https:// )">
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange('stateCurrentUser')}
                        readOnly={readOnlyCurrentUser}
                        id="linkedin"
                        name="linkedin"
                        // type="url"
                        value={linkedin || ''}
                      />
                    </li>
                    <li aria-label="X (hela URL - https:// )">
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange('stateCurrentUser')}
                        readOnly={readOnlyCurrentUser}
                        id="twitter"
                        name="twitter"
                        // type="url"
                        value={twitter || ''}
                      />
                    </li>
                  </ul>
                </Grid.Column>
                <Grid.Column>
                  <ul aria-label="Organisationens logotyp">
                    <li aria-label="Logo">
                      <Form.Field>
                        <label htmlFor="logo">
                          <span className="upload-note">
                            Lägg upp organisationens logotyp
                          </span>
                          <span className="upload-note">
                            * allowed: svg,png - max size 1MB
                          </span>
                          <Form.Input
                            type="file"
                            disabled={readOnlyCurrentUser}
                            id="logo"
                            name="logo"
                            accept="image/*,.svg"
                            onChange={this.handleInputChange(
                              'stateCurrentUser'
                            )}
                          />
                        </label>
                        <FileOrImagePreview img={logo} />
                      </Form.Field>
                    </li>
                  </ul>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <br />
                  <div className="flex no-gap">
                    {readOnlyCurrentUser ? (
                      <>
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleReadOnlyMode(
                              'readOnlyCurrentUser',
                              '',
                              'stateCurrentUser'
                            )();
                          }}
                          content="Redigera uppgifter"
                          primary
                          labelPosition="right"
                          icon="edit outline"
                        />
                        {isFromUser ? (
                          <Button
                            primary
                            type="button"
                            basic
                            content="Ändra lösenord"
                            icon="pencil alternate"
                            labelPosition="right"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleOpen();
                              this.setState({
                                disableInput: false,
                                password: '',
                                repeatPassword: ''
                              });
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={isSaveClicked}
                          primary
                          content="Spara"
                          labelPosition="right"
                          icon="checkmark"
                        />
                        <Button
                          basic
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                          }}
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                      </>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </DataBox>
      </WhiteBox>
    );
  };
  //  #endregion

  render() {
    const {
      currentUser = '',
      handleCancelPreview = '',
      isFromUser
    } = this.props;

    const {
      modalOpen,
      submittingPassword,
      password,
      repeatPassword,
      isPasswordError,
      disableInput,
      passwordError,
      oldPassword,
      isPasswordSuccess,
      loading
    } = this.state;

    if (!currentUser) return <div>Loading...</div>;

    return (
      <>
        <Wrapper>
          <ContentContainer>
            <section className="pink">
              <WhiteBox transparent>
                <ContentEditor contentId="page-user-account-main-title">
                  {`<h1>Mitt konto</h1>
                <p>
                  För att redigera dina uppgifter, klicka på knappen
                  <b>Redigera uppgifter</b> längst ner på sidan.
                </p>`}
                </ContentEditor>
              </WhiteBox>
              <WhiteBox
                transparent
                style={{
                  paddingBottom: 0,
                  paddingTop: 0
                }}
              >
                {handleCancelPreview && (
                  <Button
                    type="button"
                    icon="arrow left"
                    labelPosition="left"
                    primary
                    content="Tillbaka till register"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCancelPreview();
                    }}
                  />
                )}
              </WhiteBox>
              {this.renderUserDetails(currentUser)}
            </section>

            <br />
            {modalOpen && (
              <Modal
                open={modalOpen}
                onClose={this.handleClose}
                size="mini"
                closeOnDimmerClick={false}
                dimmer="inverted"
              >
                <Modal.Content scrolling>
                  <WhiteBox>
                    <DataBox>
                      <div>
                        {isPasswordSuccess ? (
                          <Message
                            success
                            positive
                            content="Ditt lösenord har ändrats"
                          />
                        ) : (
                          <Form loading={loading}>
                            <ul aria-label="Bekräfta lösenord">
                              <li style={{ display: 'none' }}>
                                <Form.Input
                                  noOutline
                                  maxLength="120"
                                  type="email"
                                  required
                                  name="email"
                                  autoComplete="username"
                                />
                              </li>
                              <li aria-label="Gammalt lösenord">
                                <Form.Input
                                  noOutline
                                  maxLength="40"
                                  onChange={this.handlePasswordInputChange}
                                  value={oldPassword || ''}
                                  required
                                  type="password"
                                  name="oldPassword"
                                  autoComplete="password"
                                />
                              </li>
                              <li aria-label="Nytt lösenord">
                                <Form.Input
                                  noOutline
                                  type="password"
                                  name="password"
                                  value={password || ''}
                                  readOnly={disableInput}
                                  onChange={this.handlePasswordInputChange}
                                  autoComplete="new-password"
                                  required
                                />
                              </li>
                              <li aria-label="Upprepa nytt lösenord">
                                <Form.Input
                                  noOutline
                                  type="password"
                                  name="repeatPassword"
                                  autoComplete="new-password"
                                  readOnly={disableInput}
                                  value={repeatPassword || ''}
                                  onChange={this.handlePasswordInputChange}
                                  required
                                />
                              </li>
                            </ul>
                          </Form>
                        )}
                      </div>
                      <PasswordError isPasswordError={isPasswordError}>
                        <div>
                          {isPasswordError && (
                            <Message
                              error
                              header={passwordError.code}
                              content={passwordError.message}
                            />
                          )}
                        </div>
                      </PasswordError>
                    </DataBox>
                  </WhiteBox>
                </Modal.Content>
                {isPasswordSuccess ? (
                  ''
                ) : (
                  <Modal.Actions>
                    <div className="flex no-gap">
                      <Button
                        basic
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleClose();
                        }}
                        primary
                        content="Avbryt"
                        icon="cancel"
                        labelPosition="right"
                      />
                      <Button
                        type="submit"
                        loading={loading}
                        primary
                        content="Bekräfta"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={this.changePassword}
                        disabled={
                          password !== repeatPassword ||
                          password === '' ||
                          repeatPassword === '' ||
                          password.length < 6 ||
                          repeatPassword.length < 6 ||
                          oldPassword === '' ||
                          oldPassword.length < 6 ||
                          submittingPassword
                        }
                      />
                    </div>
                  </Modal.Actions>
                )}
              </Modal>
            )}
          </ContentContainer>
        </Wrapper>
        {this.isAdmin && !isFromUser ? <UserBookings {...this.props} /> : null}
      </>
    );
  }
}

Account.propTypes = {
  firestore: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  authId: PropTypes.string.isRequired,
  handleCancelPreview: PropTypes.func,
  registerState: PropTypes.func,
  isFromUser: PropTypes.bool
};

// export default Account;
export default withErrorBoundary(
  debounceRender(Account, 100),
  MyFallbackComponent,
  errorCallback
);
