import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import { clearDateBeforeSaving } from '../../utils/clearDateBeforeSaving';

function RenderUserDetails(props) {
  const { currentUser, authId, firestore } = props;

  const [user, setUser] = useState();
  const [originaluser, setOriginaluser] = useState();
  const [loading, setloading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [disableButton, setdisableButton] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isEmpty(user)) {
      const {
        fakturaEmail = '',
        fakturaadress = '',
        fakturamottagare = '',
        ort = '',
        postnummer = ''
      } = currentUser || {};

      setOriginaluser({
        fakturaEmail,
        fakturaadress,
        fakturamottagare,
        ort,
        postnummer
      });

      setUser({
        fakturaEmail,
        fakturaadress,
        fakturamottagare,
        ort,
        postnummer
      });
    }
  }, [currentUser]);

  const handleInputChange = ({ target: { name, value } }) => {
    if (disableButton) setdisableButton(false);
    setUser({ ...user, [name]: value });
  };

  // const handleInputChange = ({ target: { name, value } }) =>
  //   console.log(name, value) || setUser(set(user, [name], value));

  const saveToDatabase = (e) => {
    e.preventDefault();
    if (isEqual(user, originaluser)) {
      setloading(false);
      setReadOnly(true);
      return;
    }
    // console.count('submit');
    setReadOnly(true);
    const databasePath = 'users';
    const reduxPath = 'currentUser';
    const bookingId = authId;

    setloading(true);
    setdisableButton(true);

    const cleanedData = clearDateBeforeSaving(user);

    firestore
      .update(
        {
          collection: databasePath,
          doc: bookingId,
          path: reduxPath
        },
        {
          ...cleanedData,
          updateAt: firestore.FieldValue.serverTimestamp()
        }
      )
      .then(() => {
        console.info('saved successfully');
      })
      .catch((fbError) => {
        console.error(fbError);
        setError({ error });
        setUser(null);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const {
    fakturaEmail = '',
    fakturaadress = '',
    fakturamottagare = '',
    ort = '',
    postnummer = ''
  } = user || {};

  const styles = {
    display: 'block'
  };

  // console.count('RenderUserDetails');
  return (
    <Form loading={loading} onSubmit={saveToDatabase} style={styles}>
      <br />
      <br />
      <ul aria-label="Fakturauppgifter">
        <li aria-label="Er referens">
          <Form.Input
            required
            maxLength="120"
            type="text"
            name="fakturamottagare"
            value={fakturamottagare}
            readOnly={readOnly}
            onChange={handleInputChange}
          />
        </li>
        <li aria-label="Fakturaadress">
          <Form.Input
            maxLength="160"
            type="text"
            name="fakturaadress"
            value={fakturaadress}
            readOnly={readOnly}
            onChange={handleInputChange}
          />
        </li>
        <li aria-label="Postnummer">
          <Form.Input
            maxLength="120"
            type="text"
            name="postnummer"
            value={postnummer}
            readOnly={readOnly}
            onChange={handleInputChange}
          />
        </li>
        <li aria-label="Ort">
          <Form.Input
            maxLength="120"
            type="text"
            name="ort"
            value={ort}
            readOnly={readOnly}
            onChange={handleInputChange}
          />
        </li>
        <li aria-label="E-post för PDF faktura">
          <Form.Input
            maxLength="120"
            type="email"
            required
            name="fakturaEmail"
            value={fakturaEmail}
            readOnly={readOnly}
            onChange={handleInputChange}
          />
        </li>
      </ul>
      <br />
      {readOnly ? (
        <Button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setReadOnly(false);
          }}
          content="Redigera uppgifter"
          primary
          labelPosition="right"
          icon="edit outline"
        />
      ) : (
        <div className="flex no-gap">
          <Button
            type="submit"
            loading={loading}
            disabled={disableButton}
            primary
            content="Spara"
            labelPosition="right"
            icon="checkmark"
          />
          <Button
            basic
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setReadOnly(true);
              setUser(currentUser);
            }}
            primary
            content="Avbryt"
            icon="cancel"
            labelPosition="right"
          />
        </div>
      )}
    </Form>
  );
}

RenderUserDetails.propTypes = {
  firestore: PropTypes.object,
  currentUser: PropTypes.object,
  authId: PropTypes.string
};

export default RenderUserDetails;
