import React from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';

function RenderCosponsor(props) {
  const { children, cosponsors, deleteCosponsors, handleInputChange } = props;

  const inputElements = map(cosponsors, (value, key) => {
    const { hemsida, organisationsnamn, organisationsnummer } = value || {};

    return (
      <fieldset key={key}>
        <legend>Medarrangör</legend>
        <div style={{ textAlign: 'right' }}>
          <Icon
            name="trash alternate outline"
            color="red"
            size="large"
            link
            onClick={() => {
              deleteCosponsors(key);
            }}
          />
        </div>
        <Form.Input
          label="Organisationsnamn (max 70 tecken inkl. blanksteg)"
          maxLength="120"
          onChange={handleInputChange(key)}
          name="organisationsnamn"
          value={organisationsnamn || ''}
        />

        <Form.Input
          label="Organisationsnummer (i formatet 802481–1658)"
          onChange={handleInputChange(key)}
          maxLength="20"
          // pattern="[0-9]{6}-[0-9]{4}"
          placeholder="802481–1658"
          name="organisationsnummer"
          value={organisationsnummer || ''}
        />

        <Form.Input
          label="Hemsida (max 160 tecken inkl. blanksteg)"
          maxLength="160"
          onChange={handleInputChange(key)}
          name="hemsida"
          value={hemsida || ''}
        />
        <br />
        <br />
      </fieldset>
    );
  });

  return (
    <>
      {inputElements}
      {children}
    </>
  );
}

RenderCosponsor.propTypes = {
  children: PropTypes.any,
  cosponsors: PropTypes.object.isRequired,
  deleteCosponsors: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired
};

export default RenderCosponsor;
