/**
 *
 * Applicants
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { IosPaperOutline } from '../../components/Icons';
import MyFallbackComponent from '../../components/MyFallbackComponent';
// export default Applicants;
import RenderCompanyListItem from '../../components/RenderCompanyListItem';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';

import '../../components/RenderCompanyListItem/styles.css';

class Applicants extends Component {
  handleAccept = (id) => {
    const { firestore } = this.props;

    firestore
      .update(`/users/${id}`, {
        isApproved: true,
        pending: false,
        isRejected: false
      })
      .then()
      .catch((err) => console.error(err));

    firestore
      .set(`/users/${id}/private/status`, {
        isApproved: true,
        pending: false,
        isRejected: false
      })
      .then()
      .catch((err) => console.error(err));
  };

  handleReject = (id) => {
    const { firestore } = this.props;
    // eslint-disable-next-line no-alert
    const reason = window.prompt('Förklaring:', '');

    if (reason !== null) {
      firestore
        .update(`/users/${id}`, {
          isApproved: false,
          isRejected: true,
          pending: false
        })
        .then()
        .catch((err) => console.error(err));

      firestore
        .update(`/users/${id}/private/status`, {
          pending: false,
          isApproved: false,
          isRejected: true,
          rejectInfo: reason
        })
        .then()
        .catch((err) => console.error(err));
    }
  };

  render() {
    const { applicants } = this.props;
    // console.log(applicants);
    return (
      <ContentContainer>
        <section className="pink">
          {applicants.length === 0 ? (
            <WhiteBox>
              <h3>
                <IosPaperOutline /> Ansökningar
              </h3>
              <p>Inga obesvarade ansökningar.</p>
            </WhiteBox>
          ) : (
            ''
          )}
          <TransitionGroup>
            {applicants?.map((a) => (
              <CSSTransition key={a.id} timeout={500} classNames="fade">
                <RenderCompanyListItem
                  {...a}
                  handleAccept={this.handleAccept}
                  handleReject={this.handleReject}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </section>
      </ContentContainer>
    );
  }
}

Applicants.propTypes = {
  applicants: PropTypes.array,
  firestore: PropTypes.object
};

export default withErrorBoundary(
  Applicants,
  MyFallbackComponent,
  errorCallback
);
