import styled from 'styled-components';

const ContentEditorContainer = styled.div`
  .tox-promotion {
    display: none;
  }

  .editable-content {
    position: relative;
  }

  .editable-content--edit-button {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
  }

  /* admin styles only */
  &.isAdmin {
    .editable-content {
      position: relative;

      &:hover {
        outline: dashed rgba(193, 187, 187, 0.5) 1px;
      }
    }
  }
`;

export { ContentEditorContainer };
