// cspell:disable
// Ämne
const AMNE_OPTIONS = [
  {
    text: 'Barn och unga',
    value: 'Barn och unga'
  },
  {
    text: 'Demokrati och politik',
    value: 'Demokrati och politik'
  },
  {
    text: 'Klimat och miljö',
    value: 'Klimat och miljö'
  },
  {
    text: 'Kultur',
    value: 'Kultur'
  },
  {
    text: 'Samhälle och bostad',
    value: 'Samhälle och bostad'
  },
  {
    text: 'Arbetsmarknad och näringsliv',
    value: 'Arbetsmarknad och näringsliv'
  },
  {
    text: 'Vård, skola, omsorg',
    value: 'Vård, skola, omsorg'
  },
  {
    text: 'Teknik och vetenskap',
    value: 'Teknik och vetenskap'
  },
  {
    text: 'Övriga ämnen',
    value: 'Övriga ämnen'
  }
];

// tillganglighet
const TILLGANGLIGHET_OPTIONS = [
  {
    text: 'Vi har teckenspråkstolk',
    value: 'Vi har teckenspråkstolk'
  },
  {
    text: 'Vi har syntolkning (vid livesändning eller inspelning)',
    value: 'Vi har syntolkning (vid livesändning eller inspelning)'
  },
  {
    text: 'Vi har textning (vid livesändning eller inspelning) ',
    value: 'Vi har textning (vid livesändning eller inspelning) '
  },
  {
    text: 'Vi har inget av ovanstående',
    value: 'Vi har inget av ovanstående'
  },
  {
    text: 'Annat',
    value: 'Annat'
  }
];

// sprak
const SPRAK_OPTIONS = [
  {
    text: 'Svenskt tal',
    value: 'Svenskt tal'
  },
  {
    text: 'Engelskt tal',
    value: 'Engelskt tal'
  },
  {
    text: 'Annat språk',
    value: 'Annat språk'
  }
];

// ORGANISATION_OPTIONS
const ORGANISATION_OPTIONS = [
  {
    text: 'Facklig organisation',
    value: 'Facklig organisation'
  },
  {
    text: 'Lärosäte/Skola',
    value: 'Lärosäte/Skola'
  },
  {
    text: 'Företag',
    value: 'Företag'
  },
  {
    text: 'Ideell organisation',
    value: 'Ideell organisation'
  },
  {
    text: 'Ideell förening: svag ekonomisk ställning (årsredovisning krävs)',
    value: 'Ideell förening: svag ekonomisk ställning (årsredovisning krävs)'
  },
  {
    text: 'Intresseorganisation',
    value: 'Intresseorganisation'
  },
  {
    text: 'Massmedier',
    value: 'Massmedier'
  },
  {
    text: 'Näringslivsorganisation',
    value: 'Näringslivsorganisation'
  },
  {
    text: 'Politisk organisation',
    value: 'Politisk organisation'
  },
  {
    text: 'Stat/Kommun/Region',
    value: 'Stat/Kommun/Region'
  },
  {
    text: 'Riksdagsparti',
    value: 'Riksdagsparti'
  },
  {
    text: 'Kommun/Region parti',
    value: 'Kommun/Region parti'
  },
  {
    text: 'Övriga partier',
    value: 'Övriga partier'
  },
  {
    text: 'Annan',
    value: 'Annan'
  }
];

export {
  AMNE_OPTIONS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  ORGANISATION_OPTIONS
};
