/**
 *
 * UserDashBoard
 *
 */
import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  firestoreConnect,
  isLoaded,
  withFirestore
} from 'react-redux-firebase';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Information from '../../components/Information';
import PrivateRoute from '../../components/PrivateRoute';
import { UserSideBar } from '../../components/SideBar';
import Account from '../Account';
import {
  getAuthId,
  getCurrentPath,
  getCurrentUser,
  getCurrentUserBookings,
  getCurrentUserTillaggBookings,
  getCurrentUserSeminarsBookings,
  getCurrentUserSeminarsLiveBookings,
  getCurrentUserSponsorBookings,
  getCurrentUserProductBookings,
  getProfile,
  getTotalCurrentSeminarBookings,
  getTotalCurrentTentBookings,
  getTotalCurrentTillaggBookings,
  getTotalCurrentSponsorBookings,
  getTotalCurrentProductBookings,
  isLoadingbookings,
  getStats,
  getAssets,
  getTotalCurrentSeminarLiveBookings,
  getOrderedSeminarBookings,
  getOrderedSeminarLiveBookings,
  getProducts
} from '../App/selectors';
import Book from '../Book';
import HomePage from '../HomePage';
import Seminar from '../Seminar';
import SeminarLive from '../SeminarLive';
import Sponsor from '../Sponsor';
import Tent from '../Tent';
import Tillagg from '../Tillagg';
import UserBookings from '../UserBookings';
import ProductBookings from '../ProductBookings';

class UserDashBoard extends React.Component {
  render() {
    const {
      match: { path: _path, url },
      currentUser,
      currentUserBookings,
      location,
      profile,
      firebase,
      products
    } = this.props;

    // check this login
    const isApproved =
      get(currentUser, 'private.status.isApproved', false) ||
      get(profile, 'private.status.isApproved', false);

    // console.log('%c isApproved', 'color:white; background:black; size:30px', isApproved);

    return (
      <div className="columns">
        <UserSideBar
          home={`${url}`}
          account={`${url}/account`}
          minaAccount={`${url}/bookings`}
          book={`${url}/book`}
          information={`${url}/information`}
          tillagg={`${url}/tillagg`}
          torghandel={`${url}/torghandel`}
          foodtruck={`${url}/foodtruck`}
          currentPath={location?.pathname}
          isApproved={isApproved}
        />
        <div className="main">
          <Switch location={location}>
            <Route
              exact
              path={`${_path}`}
              render={(props) => (
                <HomePage
                  {...props}
                  isApproved={isApproved}
                  isApprovedLoaded={isLoaded(currentUser)}
                  isBookingsLoaded={isLoaded(currentUserBookings)}
                />
              )}
            />
            <Route
              exact
              path={`${_path}/account`}
              render={(props) => (
                <Account {...props} {...this.props} isFromUser />
              )}
            />
            <Route
              exact
              path={`${_path}/bookings`}
              render={(props) => (
                <UserBookings {...props} {...this.props} isFromUser />
              )}
            />
            <PrivateRoute
              path={`${_path}/book`}
              isApproved={isApproved}
              {...this.props}
              isApprovedLoaded={isLoaded(currentUser)}
              isBookingsLoaded={isLoaded(currentUserBookings)}
            >
              <Switch>
                <Route
                  exact
                  path={`${_path}/book`}
                  render={(props) => <Book {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/tent`}
                  render={(props) => <Tent {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/seminar`}
                  render={(props) => <Seminar {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/seminar-live`}
                  render={(props) => <SeminarLive {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/sponsor`}
                  render={(props) => <Sponsor {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/tillagg`}
                  render={(props) => <Tillagg {...props} {...this.props} />}
                />
                <Route
                  exact
                  path={`${_path}/book/torghandel`}
                  render={(props) => (
                    <ProductBookings
                      {...props}
                      {...this.props}
                      product={get(products, 'torghandel', {})}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${_path}/book/foodtruck`}
                  render={(props) => (
                    <ProductBookings
                      {...props}
                      {...this.props}
                      product={get(products, 'foodtruck', {})}
                    />
                  )}
                />
              </Switch>
            </PrivateRoute>
            <Route
              exact
              path={`${_path}/information`}
              render={(props) => <Information {...props} firebase={firebase} />}
            />
            <Redirect to={`${_path}`} />
          </Switch>
        </div>
      </div>
    );
  }
}

UserDashBoard.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  firebase: PropTypes.object,
  tents: PropTypes.object,
  currentUser: PropTypes.object,
  currentUserBookings: PropTypes.object,
  currentUserTillaggBookings: PropTypes.object,
  currentUserProductBookings: PropTypes.object,
  currentUserSeminars: PropTypes.object,
  currentPath: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  totalCurrentSponsorBookings: PropTypes.number,
  totalCurrentTentBookings: PropTypes.number,
  products: PropTypes.array
};

const mapStateToProps = createStructuredSelector({
  profile: getProfile,
  currentUser: getCurrentUser,
  currentUserBookings: getCurrentUserBookings,
  currentUserTillaggBookings: getCurrentUserTillaggBookings,
  currentUserSeminars: getCurrentUserSeminarsBookings,
  currentUserSeminarsLive: getCurrentUserSeminarsLiveBookings,
  currentUserSponsor: getCurrentUserSponsorBookings,
  currentUserProductBookings: getCurrentUserProductBookings,
  totalCurrentTentBookings: getTotalCurrentTentBookings,
  totalCurrentTillaggBookings: getTotalCurrentTillaggBookings,
  totalCurrentSeminarBookings: getTotalCurrentSeminarBookings,
  totalCurrentSeminarLiveBookings: getTotalCurrentSeminarLiveBookings,
  totalCurrentSponsorBookings: getTotalCurrentSponsorBookings,
  totalCurrentProductBookings: getTotalCurrentProductBookings,
  seminars: getOrderedSeminarBookings,
  liveSeminars: getOrderedSeminarLiveBookings,
  currentPath: getCurrentPath,
  authId: getAuthId,
  assets: getAssets,
  stats: getStats,
  loading: isLoadingbookings,
  products: getProducts
  // store: (store) => store
});

const withConnect = connect(mapStateToProps);

const createFirestoreConnect = (props) => {
  const uid = get(props, 'auth.uid');

  if (uid) {
    return [
      // current user specific data
      {
        collection: 'bookings',
        where: ['userId', '==', uid],
        path: 'currentUserBookings'
      },
      {
        collection: 'tillaggBookings',
        where: ['userId', '==', uid],
        path: 'currentUserTillaggBookings'
      },
      {
        collection: 'seminarBookings',
        where: ['userId', '==', uid],
        path: 'currentUserSeminarsBookings'
      },
      {
        collection: 'seminarLiveBookings',
        where: ['userId', '==', uid],
        path: 'currentUserSeminarsLiveBookings'
      },
      {
        collection: 'sponsorBookings',
        where: ['userId', '==', uid],
        path: 'currentUserSponsorBookings'
      },
      {
        collection: 'productBookings',
        where: ['userId', '==', uid],
        path: 'currentUserProductBookings'
      },
      {
        collection: `users/${uid}/private`,
        path: 'currentUser/private'
      },
      {
        collection: 'users',
        doc: uid,
        path: 'currentUser'
      },
      // all collections
      {
        collection: 'seminarBookings'
      },
      {
        collection: 'seminarLiveBookings'
      },
      {
        collection: 'products'
      },
      {
        collection: 'assets'
      },
      {
        collection: 'stats'
      }
    ];
  }
  return [];
};

export default compose(
  withFirestore,
  withConnect,
  withRouter,
  firestoreConnect((props) => createFirestoreConnect(props))
)(UserDashBoard);
