/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import ReactImageGallery from 'react-image-gallery';
import ImageUploading from 'react-images-uploading';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Modal } from 'semantic-ui-react';

import { uploadSingleFileToStorage } from '../../api';
import {
  getClaims,
  getContent,
  getIsEdit
} from '../../containers/App/selectors';
import { JarvaButtonSecondary } from '../../styledComponents';
import { errorCallback } from '../../utils';
import { saveToServer } from '../../utils/saveToServer';
import MyFallbackComponent from '../MyFallbackComponent';
import Wrapper from './style';

function renderImageGallery(images) {
  return images?.length ? (
    <ReactImageGallery
      lazyLoad
      autoPlay={false}
      showFullscreenButton
      showPlayButton
      showNav
      items={images.map(({ data_url }) => ({
        original: data_url,
        thumbnail: data_url
      }))}
    />
  ) : null;
}

function GalleryEditor(props) {
  const maxNumber = 20;
  const { contentId, firestore, content, claims } = props;
  const isAdmin = claims?.admin || false;

  const [images, setImages] = useState(() => {
    return content?.[contentId]?.content ?? [];
  });

  const [isEdit, setIsEdit] = useState(false);

  const saveChangesToServer = async () => {
    try {
      // upload to server and get urls
      const uploadPromises = images.map(async (item) => {
        const { file, data_url } = item;

        if (file) {
          return uploadSingleFileToStorage({
            uploadPath: 'uploads/content-gallery',
            file
          });
        }

        return data_url;
      });

      const uploadUrls = await Promise.all(uploadPromises);

      const structuresUrls = uploadUrls.map((item) => ({
        data_url: item
      }));

      // structuresUrls
      setImages(structuresUrls);

      const dateToSave = {
        content: structuresUrls,
        updateAt: firestore.FieldValue.serverTimestamp(),
        editedBy: claims?.email
      };

      try {
        await saveToServer({
          path: `content/${contentId}`,
          data: dateToSave,
          firestore
        });
        console.info('Data saved successfully.', `content/${contentId}`);
      } catch (error) {
        console.error('Error saving data:', error);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  if (!isAdmin) {
    return renderImageGallery(images);
  }

  return (
    <>
      {renderImageGallery(images)}
      <br />
      <br />
      <JarvaButtonSecondary
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsEdit(!isEdit);
        }}
      >
        Add/Edit Image Gallery
      </JarvaButtonSecondary>
      {isEdit && (
        <Modal
          open={isEdit}
          onClose={() => setIsEdit(false)}
          closeOnDimmerClick={false}
          dimmer="inverted"
        >
          <Modal.Content>
            <Wrapper>
              <ImageUploading
                multiple
                value={images}
                onChange={setImages}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  // onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  dragProps
                }) => (
                  <>
                    <Button
                      compact
                      circular
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onImageUpload();
                      }}
                      {...dragProps}
                    >
                      Add/Edit Images
                    </Button>

                    <Button
                      compact
                      circular
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        saveChangesToServer();
                        setIsEdit(false);
                      }}
                    >
                      Save Changes
                    </Button>

                    <Button
                      compact
                      circular
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsEdit(false);
                      }}
                    >
                      Avbryt
                    </Button>

                    <div className="images-container">
                      {imageList?.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} width="200" alt="" />
                          <div>
                            <Button
                              compact
                              circular
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onImageUpdate(index);
                              }}
                            >
                              Update
                            </Button>
                            <Button
                              className="danger"
                              compact
                              circular
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onImageRemove(index);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </ImageUploading>
            </Wrapper>
          </Modal.Content>
          <Modal.Actions>
            <Button
              compact
              circular
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEdit(false);
              }}
            >
              Avbryt
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}

GalleryEditor.propTypes = {
  contentId: PropTypes.string.isRequired,
  firestore: PropTypes.object.isRequired,
  content: PropTypes.object,
  claims: PropTypes.object,
  children: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  claims: getClaims,
  content: getContent,
  isEdit: getIsEdit
});

const withConnect = connect(mapStateToProps);

export default compose(
  withFirestore,
  withConnect
)(withErrorBoundary(GalleryEditor, MyFallbackComponent, errorCallback));
