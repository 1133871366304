/**
 *
 * App
 *
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Header from '../../components/Header';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import SpinnerLoader from '../../components/SpinnerLoader';
import UserManagement from '../../components/UserManagement';
import { errorCallback } from '../../utils/index';
import AdminDashBoard from '../AdminDashBoard';
import LandingPage from '../LandingPage';
import UserDashBoard from '../UserDashBoard';
import { AppContainer } from './appStyles';
import { getAuth, getClaims, getProfile } from './selectors';

export class App extends Component {
  state = {
    hasUpdatedLogin: false
  };

  componentDidUpdate() {
    this.updateLoginState();
  }

  checkCustomClaims = async ({ firebase, dispatch, claims }) => {
    if (!isEmpty(claims)) return;

    const user = firebase.auth().currentUser;
    if (!user) return;
    const { claims: newClaims } = await user.getIdTokenResult();
    dispatch({ type: 'CLAIMS_UPDATE', payload: newClaims });
  };

  updateLoginState() {
    const { firestore, auth } = this.props;
    const { hasUpdatedLogin } = this.state;

    if (firestore && auth && auth.uid && !hasUpdatedLogin) {
      this.setState({ hasUpdatedLogin: true });
    }
  }

  renderPublicRoutes() {
    return (
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route
          path="/__/auth/"
          render={(props) => <UserManagement {...props} {...this.props} />}
        />
        <Route
          exact
          path="/admin"
          render={(props) => <LandingPage {...props} toPath="/admin" admin />}
        />
        <Redirect to="/" />
      </Switch>
    );
  }

  renderProtectedRoutes() {
    const { firebase, dispatch, claims, auth, profile } = this.props;

    this.checkCustomClaims({ firebase, dispatch, claims });

    if (!isLoaded(auth) || !isLoaded(profile)) return null;
    if (isEmpty(claims)) return null;

    return (
      <AppContainer>
        <Header auth={auth} dispatch={dispatch} firebase={firebase} />
        <Switch>
          <Route
            path="/user"
            render={(props) => <UserDashBoard {...props} {...this.props} />}
          />
          <Route
            path="/admin"
            render={(props) =>
              claims.admin ? (
                <AdminDashBoard {...props} {...this.props} />
              ) : (
                <Redirect to="/user" />
              )
            }
          />
          <Redirect to="/user" />
        </Switch>
      </AppContainer>
    );
  }

  render() {
    const { auth, content } = this.props;
    const isAuthLoadedAndEmpty = isLoaded(auth) && isEmpty(auth);
    const shouldShowLoader = isEmpty(content) || !isLoaded(content);

    return (
      <>
        {isAuthLoadedAndEmpty
          ? this.renderPublicRoutes()
          : this.renderProtectedRoutes()}
        <SpinnerLoader show={shouldShowLoader} />
      </>
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  firebase: PropTypes.object.isRequired,
  firestore: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  claims: PropTypes.object,
  history: PropTypes.object,
  content: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  auth: getAuth,
  profile: getProfile,
  claims: getClaims,
  content: (state) => state.firestore.data.content
});

const enhance = compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: 'assets' }, { collection: 'content' }])
);

export default withErrorBoundary(
  enhance(App),
  MyFallbackComponent,
  errorCallback
);
