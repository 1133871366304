/*
 *
 * customClaimsProvider reducer
 *
 */

function customClaimsProvider(state = {}, action) {
  switch (action.type) {
    case 'CLAIMS_UPDATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default customClaimsProvider;
