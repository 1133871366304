/**
 *
 * SpinnerLoader
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerLoaderWrapper } from './styles';

function SpinnerLoader({ show }) {
  if (!show) return null;
  return (
    <SpinnerLoaderWrapper>
      <span className="SpinnerLoader" />
    </SpinnerLoaderWrapper>
  );
}

SpinnerLoader.propTypes = {
  show: PropTypes.bool
};

export default SpinnerLoader;
