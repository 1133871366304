import 'react-image-gallery/styles/css/image-gallery.css';
import { createGlobalStyle, css } from 'styled-components';

import { vars } from './styledComponents/index';

const globalCss = css`
  :root {
    --font-size--xsmall: 0.76rem;
    --font-size--small: 0.86rem;
    --font-size--label: 0.93rem;
    --font-size--subheading: 1rem;
    --font-size--medium: 1rem;
    --font-size--midsize: 1.2rem;
    --font-size--heading: 1.42rem;
    --font-size--large: 2.14rem;
    --font-size--x-large: 4.57rem;
    --font-size--xx-large: 15.5rem;
    --margin-40: 2.86rem;
    --border-color: #bfc3c8;
    --button-grey: rgba(35, 55, 236, 0.05);
    --jarva-light-back: #fbfafa;
    --jarva-blue: ${vars.jarvaBlue};
    --jarva-text: ${vars.jarvaTextDark};
    font-size: clamp(13px, 0.95vw, 16.5px);
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #app {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'neue-haas-grotesk-display', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1rem;
  }

  body.fontLoaded {
    font-family: 'neue-haas-grotesk-display', sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: 'neue-haas-grotesk-display', sans-serif !important;
    color: ${vars.jarvaTextDark};
    font-weight: 450;
  }

  h6 {
    font-size: var(--font-size--label) !important;
    letter-spacing: 0.06em !important;
    line-height: 160% !important;
    color: ${vars.jarvaTextDark};
    font-style: normal;
    font-weight: 450;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
  }

  h1 {
    font-size: 1.75rem;
    font-weight: 450;
  }

  h2 {
    font-size: 2.25rem;
    line-height: 2.375rem;
    padding-bottom: 20px;
  }

  p,
  label {
    line-height: 1.5em;
  }

  p,
  .p,
  button,
  span,
  label,
  input,
  textarea,
  select {
    letter-spacing: 0.03em;
    font-weight: 450;
    color: var(--jarva-text);
    font-size: 1rem;
  }

  p,
  .p,
  ul,
  ol,
  label {
    max-width: 90ch;
  }

  p,
  .p,
  label {
    color: ${vars.textColorGray};
  }

  .editable-content {
    ul,
    ol {
      color: ${vars.textColorGray};
    }
  }

  b {
    font-weight: bold;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .form-like {
    font-size: 1.2rem;
  }

  .link-underline {
    border-bottom: 1px solid currentcolor;
    display: inline-block;
    margin-top: 1rem;
  }

  .link-underline + .link-underline {
    margin-left: 10px;
  }

  .note {
    font-style: italic !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
  }

  .info {
    &.label {
      font-size: 13px !important;
      font-weight: 400 !important;
      color: black !important;
      background-color: rgba(254, 241, 96, 0.5) !important;
      margin-bottom: 1rem !important;
    }
    > a {
      opacity: 1 !important;
      text-decoration: underline !important;
    }
  }

  .hightlight {
    background-color: rgba(254, 241, 96, 0.5) !important;
    line-height: 1.2 !important;
    padding: 1rem;
  }

  ul {
    margin: 0;
    + ul {
      margin-top: 1.5rem;
    }
  }

  .list-small-space {
    padding-left: 1rem;
    li + li {
      margin-top: 0.5em;
    }
  }

  .blue-notes {
    margin-top: 1rem !important;
    font-size: 1rem;
    color: ${vars.textGreyLight};
  }

  [readonly] {
    border: none !important;
    padding-left: 0 !important;
  }

  textarea {
    padding: 9px 14px;
    min-height: 130px !important;
    &.short {
      min-height: 50px !important;
    }
  }

  textarea[readonly] {
    resize: none !important;
    min-height: unset !important;
    &.short {
      min-height: unset !important;
    }
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid currentColor;
  }

  .uploads-preview {
    max-width: 100px;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    padding: 0;
    margin: 0;
    display: flex;
  }

  .underline {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }

  .max-content-width {
    max-width: fit-content;
  }

  textarea:empty,
  input[value=''],
  input:not([value]) {
    &[readonly] {
      display: none !important;
    }
  }

  .no-break {
    white-space: nowrap;
  }

  .image-gallery-content {
    &.fullscreen {
      .image-gallery-image {
        height: 100vh !important;
      }
    }

    .image-gallery-play-button,
    .image-gallery-fullscreen-button,
    .image-gallery-left-nav,
    .image-gallery-right-nav {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(7px);
    }

    .image-gallery-icon {
      padding: 5px;
    }

    .image-gallery-svg {
      height: 30px;
      width: 30px;
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      padding: 0;
    }
  }

  fieldset {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
  }

  .remove-space-bottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    &:nth-child(1) {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  ul + p {
    margin-top: 0.5rem;
  }

  li {
    line-height: 1.1;
  }

  li + li {
    margin-top: 0.5rem;
  }

  .info {
    display: block;
    font-size: 14px;
    font-style: italic;
  }

  .upload-note {
    display: block;
    font-size: 14px;
    font-style: italic;
  }

  .hidden {
    display: none;
  }

  .notification-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f6577c;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    line-height: 1em;
    font-size: 12px;
    font-weight: bold;
  }

  .p {
    padding: 1rem 0.5rem 1rem 0px;
    max-width: 850px;
  }
  a.basic.primary.button {
    display: flex;
    width: auto;

    max-width: max-content;
  }
  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .edit-button {
    color: ${vars.jarvaTextDark};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.28);
    &:hover {
      color: #c28cae;
      cursor: pointer;
    }

    display: flex;
    gap: 10px;

    background-color: white;
    padding: 3px 5px;
    outline: solid currentcolor 1px;

    &.icon-only {
      outline: none;
      width: 30px;
      height: 30px;
      padding: 0;
      transform: scale(0.8) translateX(75%) translateY(-75%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        transform: translateX(2px);
      }
    }

    &.disabled {
      pointer-events: none;
      border-color: transparent;
      background-color: #cccccc;
      color: #666666;
    }
  }

  button {
    p {
      margin-bottom: 0 !important;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .inline {
    display: inline-flex;
    gap: 0.5rem;
    .editable-content--edit-button {
      right: -1rem;
    }
  }

  .flex {
    display: flex;
    gap: 0.5rem;

    &.no-gap {
      gap: 0;
    }

    &.align-v-center {
      align-items: center;
    }
  }

  .search-input {
    background: transparent;
    border: none;
    color: var(--jarva-text, #000);
  }

  .isButton {
    .editable-content--edit-button {
      right: -3rem;
    }
  }

  .no-underline {
    text-decoration: none;
  }

  .hover-current-color {
    --jarva-blue: currentcolor;
  }

  .reset-text-tranform {
    text-transform: none !important;
  }

  .checkbox-padding {
    padding-left: 1.85714em;
  }

  .fz-4rem_desktop {
    @media ${vars.desktopAndUp} {
      font-size: 4rem !important;
    }
  }

  .titleCase {
    text-transform: capitalize;
  }

  .custom-form {
    input,
    optgroup,
    select,
    textarea {
      width: 100%;
      padding: 5px;
      margin-top: 5px;
      display: block;
      border-width: 1px;
    }

    fieldset {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 0rem !important;
    }

    &::after {
      content: 'Note: * fields are required';
      color: #858484;
      font-size: small;
      display: block;
      margin-bottom: 1rem;
    }
  }

  .product-booking-form {
    input,
    optgroup,
    select,
    textarea {
      width: 100%;
      padding: 5px;
      margin-top: 5px;
      display: block;
      border-width: 1px;
    }

    fieldset {
      border: none;
      margin: 0 !important;
      display: grid;
      gap: 1.5rem;
    }
  }

  .input-label {
    font-weight: 800;
  }

  input {
    &.button {
      max-width: fit-content;
      padding-left: 1.5em !important;
      padding-right: 1.5em !important;
    }
  }

  .border {
    max-width: 54.875rem;

    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    margin-bottom: 2.9rem;
  }

  .no-underline {
    text-decoration: none;
    &::after {
      display: none !important;
    }
  }

  .center-text {
    justify-content: space-between;
    align-items: center;
  }

  .space-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
    &.icon-button {
      padding: 7px !important;
      aspect-ratio: 1;
    }

    .img-icon {
      height: 100%;
      filter: invert(100%) sepia(0%) saturate(952%) hue-rotate(199deg)
        brightness(112%) contrast(112%);
    }

    &:hover {
      .img-icon {
        filter: invert(19%) sepia(99%) saturate(4719%) hue-rotate(238deg)
          brightness(90%) contrast(106%);
      }
    }
  }

  .count {
    min-width: 2ch;
    text-align: center;
  }

  .quantity-button-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .button {
      margin: 0 !important;
    }
  }

  .pencil {
    cursor: pointer;
  }

  input[readonly] {
    &:focus-visible {
      outline: unset;
    }
  }

  input[type='checkbox'] {
    width: auto;
    transform: scale(1.5) translateX(2px);
    margin: 4px 0;
  }

  .offset-bottom-3px {
    transform: translateY(3px);
  }

  /* media-text */
  .media-text {
    display: flex;
    gap: 2rem;

    > :nth-child(1) {
      flex-basis: 70%;
    }
    > :nth-child(2) {
      flex-basis: 30%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .medium-image,
    .image-gallery-image {
      img {
        display: block;
        object-fit: contain;
        object-position: center;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .medium-image {
      img {
        margin-left: auto;
      }
    }
  }

  img,
  video,
  iframe {
    max-inline-size: 100%;
    block-size: auto;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .red {
    color: red;
  }

  .placeholder-text {
    color: #c8c8c8;
  }
`;

const GlobalStyle = createGlobalStyle`${globalCss}`;

export default GlobalStyle;
