import styled from 'styled-components';

import { DataBox, pinkGradient, vars } from '../../styledComponents';

// #region RegisterDataBox
const RegisterDataBox = styled(DataBox)`
  .filters {
    float: right;
    font-size: 0.6em;
  }
  h3 {
    margin-bottom: 40px;
  }
  .singel-flex {
    position: relative;
    svg {
      transform: scale(1.4) translateY(-2px);
      margin-right: 6px;
      margin-left: 2px;
    }
    &.underlined:after {
      content: ' ';
      width: 100%;
      height: 2px;
      background-color: pink;
      position: absolute;
      bottom: 0px;
      left: 0px;
      ${pinkGradient('45deg', vars.jarvaBlue)}
    }
    .search {
      background-color: ${vars.jarvaBlue};
      border-radius: 90px;
      display: inline-block;
      color: #fff;
      svg {
        margin-left: 10px;
        transform: scale(1.1);
      }

      input {
        outline: none !important;
      }
    }
    .filter-label {
      font-size: 0.8em;
      color: ${vars.jarvaBlue} !important;
      letter-spacing: 0.8px;
      display: none;
    }
    .filter {
      font-size: 0.8em;
      color: ${vars.jarvaBlue} !important;
      letter-spacing: 0.8px;
      text-transform: capitalize;
      display: inline-flex;
      align-items: center;
      gap: 5px;
      position: relative;
      cursor: pointer;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border: 2px solid currentcolor;
      }
      &.selected {
        &::before {
          background-color: currentcolor;
        }
      }
      &:hover {
        &::before {
          background-color: currentcolor;
        }
      }
    }
    textarea:focus,
    input:focus {
      outline: none;
    }
    input {
      color: #fff;
      padding: 10px;

      border-radius: 90px;
      text-align: left;
    }
  }
  .filter-row {
    display: flex;
    gap: 1rem;
  }

  .horizontal-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`;
// #endregion

export { RegisterDataBox };
