import get from 'lodash/get';

function priceOfProductBookings(product) {
  const { productId, pris } = product;
  const quantity = get(product, `quantity_${productId}`, 1);

  let totalPrice = 0;
  let productQuantity = 1;

  productQuantity = quantity;
  totalPrice = pris * productQuantity;

  return {
    productQuantity,
    quantity,
    totalPrice
  };
}

export default priceOfProductBookings;
