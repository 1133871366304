import firebase from 'firebase/app';

import { DAYS_TO_INVOICE } from '../configs/index';

/**
 * Calculates the number of days left from creation to the invoice date.
 *
 * @param {Object} createdAt - The creation timestamp object, expected to have a 'seconds' property.
 * @returns {number | null} - The number of days left or null if input is invalid.
 */
function daysLeft(createdAt) {
  // Validating the input.
  if (!(createdAt instanceof firebase.firestore.Timestamp)) {
    console.error('Invalid input: "createdAt" is missing or malformed.');
    return null;
  }

  const SECONDS_IN_A_DAY = 24 * 3600;
  const adjustmentDays = DAYS_TO_INVOICE + 1;

  // Calculating the remaining days.
  const timeLeft = createdAt.seconds + adjustmentDays * SECONDS_IN_A_DAY;
  const timeNow = Math.floor(Date.now() / 1000);
  const resultDate = Math.floor((timeLeft - timeNow) / SECONDS_IN_A_DAY);

  return resultDate;
}

export default daysLeft;
