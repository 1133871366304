/**
 *
 * MyFallbackComponent
 *
 */
import React from 'react';

import { WhiteBox } from '../../styledComponents';

// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function MyFallbackComponent() {
  return (
    <WhiteBox transparent>
      <section>
        <h2>
          <strong>Oj! Något har blivit fel. Prova ladda om sidan.</strong>
        </h2>
      </section>
    </WhiteBox>
  );
}

export default MyFallbackComponent;
