/**
 *
 * Book
 * path : /user/book
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';

import flagSvg from '../../assets/flag.svg';
import torghandel from '../../assets/torghandel.svg';
import foodtruck from '../../assets/foodtruck.svg';
import seminarSvg from '../../assets/seminar.svg';
import starSvg from '../../assets/start.svg';
import tentSvg from '../../assets/tent.svg';
import ContentEditor from '../../components/ContentEditor';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import TextCopy from '../../components/TextCopy/TextCopy';
import { ContentContainer, WhiteBox } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import {
  ImgContainer,
  CardContainer,
  JarvaButtonPrimaryWithMessage
} from './styledBook';

function Book(props) {
  const disabledMessage = 'OBS Vänta tills din ansökan är beviljad!';
  const { disableButtons = false, history } = props;
  return (
    <ContentContainer>
      <section>
        <WhiteBox transparent>
          <ContentEditor contentId="user-book-main-content" />
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={tentSvg} alt="tent" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-tent-title"
                >
                  Tältyta
                </ContentEditor>
              </h3>
              <ContentEditor contentId="user-book-content-bookings-tent">
                Boka en av våra tältytor och kom i kontakt med Järvaveckans
                besökare. Vi erbjuder 2,5x2,5m för endast ideella organisationer
                med svag ekonomisk ställning. För övriga organisationer,
                myndigheter och ideella föreningar med stark ekonomisk ställning
                gäller 5x2,5m eller 5x5. Önskar ni ännu större yta, kontakta
                oss.
              </ContentEditor>
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/tent');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-tent-button"
                >
                  Läs mer och boka Tältyta
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={seminarSvg} alt="seminar" width="33%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-seminar-title"
                >
                  Boka mindre scen & seminarium
                </ContentEditor>
              </h3>
              <TextCopy name="seminar-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/seminar');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-seminar-button"
                >
                  Läs mer och boka Seminarietid
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer>
              <img src={seminarSvg} alt="seminar" width="33%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-seminar-live-title"
                >
                  Boka mindre scen & seminarium – för digital livesändning &
                  inspelning
                </ContentEditor>
              </h3>
              <TextCopy name="seminarLive-description" />
              <br />

              <JarvaButtonPrimaryWithMessage
                basic
                aria-label={disabledMessage}
                disabled={disableButtons}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/seminar-live');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-Seminar-live-button"
                >
                  Läs mer och boka Seminarietid
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large">
              <img src={flagSvg} alt="flag" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-partner-title"
                >
                  Partnerskap
                </ContentEditor>
              </h3>
              <TextCopy name="partner-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/sponsor');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-partner-button"
                >
                  Läs mer och bli partner
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large">
              <img src={torghandel} alt="start" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-torghandel-title"
                >
                  Utställare Torghandel
                </ContentEditor>
              </h3>
              <TextCopy name="torghandel-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/torghandel');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-torghandel-button"
                >
                  Läs mer och boka Utställare Torghandel
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large">
              <img src={foodtruck} alt="start" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-foodtruck-title"
                >
                  Foodtruck
                </ContentEditor>
              </h3>
              <TextCopy name="foodtruck-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/foodtruck');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-foodtruck-button"
                >
                  Läs mer och boka Foodtruck
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>

        <WhiteBox>
          <CardContainer>
            <ImgContainer className="large" bgColor="var(--jarva-light-back)">
              <img src={starSvg} alt="start" width="90%" />
            </ImgContainer>
            <div>
              <h3>
                <ContentEditor
                  inline
                  contentId="user-book-content-bookings-tillagg-title"
                >
                  Övriga produkter och tjänster
                </ContentEditor>
              </h3>
              <TextCopy name="tillagg-description" />
              <br />
              <JarvaButtonPrimaryWithMessage
                basic
                disabled={disableButtons}
                aria-label={disabledMessage}
                onClick={() => {
                  if (disableButtons) return;
                  history.push('/user/book/tillagg');
                }}
              >
                <ContentEditor
                  inline
                  isButton
                  contentId="user-book-content-bookings-tillagg-button"
                >
                  Läs mer och boka tillägg
                </ContentEditor>
              </JarvaButtonPrimaryWithMessage>
            </div>
          </CardContainer>
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

Book.propTypes = {
  history: PropTypes.object.isRequired,
  disableButtons: PropTypes.bool
};
export default withErrorBoundary(Book, MyFallbackComponent, errorCallback);
