/**
 * Saves data to the server using Firestore.
 *
 * @param {Object} options - The options for saving to the server.
 * @param {string} options.path - The path to the document in Firestore.
 * @param {string} [options.method='set'] - The method to use for saving (default: 'set').
 * @param {Object} options.data - The data to save to the server.
 * @param {Object} options.firestore - The Firestore instance.
 * @param {boolean} [options.merge=false] - Whether to merge the data (only applicable for 'set' method).
 * @returns {Promise} A promise that resolves when the data is successfully saved to the server.
 * @throws {Error} If the Firestore instance is not provided or if an invalid method is specified.
 */
const saveToServer = ({
  path,
  method = 'set',
  data,
  firestore,
  merge = false
}) => {
  if (!firestore) {
    throw new Error('Firestore instance required!');
  }

  const docRef = firestore.doc(path);

  switch (method) {
    case 'set':
      return docRef.set(data, { merge });
    case 'update':
      return docRef.update(data);
    case 'add':
      return firestore.collection(path).add(data);
    default:
      throw new Error(
        `Invalid method: ${method}. Supported methods are 'set', 'update', and 'add'.`
      );
  }
};

export { saveToServer };
