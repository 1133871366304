function getDateFromTimeStamp(timeStamp) {
  // Return fallback text for invalid or empty timestamps
  if (!timeStamp || Number.isNaN(new Date(timeStamp).getTime())) {
    return 'Aldrig inloggad';
  }

  // Check for Firebase timestamp object with 'seconds' property
  const date = timeStamp?.seconds
    ? new Date(timeStamp.seconds * 1000)
    : new Date(timeStamp);

  return date.toLocaleDateString('sv-SE');
}

export default getDateFromTimeStamp;
