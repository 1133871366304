// reducers.js
import { SET_EDIT_MODE } from './actionTypes';

const initialState = {
  isEdit: false
};

export const editReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EDIT_MODE:
      return {
        ...state,
        isEdit: action.payload
      };
    default:
      return state;
  }
};
