import { get } from 'lodash';
import jsonexport from 'jsonexport';
import { saveAs } from 'file-saver';

import { DAYS_TO_INVOICE } from '../../configs/index';
import tentsTotalPrice from '../../utils/tentsTotalPrice';
import transformNgoText from '../../utils/transformNgoText';
import handleTableDataTransform from '../../utils/handleTableDataTransform';

// construct user object with all bookings
const addUserToBooking = (bookArray, users, filterBy) => {
  const dataObj = { ...bookArray };
  const newObj = {};

  Object.keys(dataObj).forEach((bookingId) => {
    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const {
      userId,
      taltyta,
      quantity,
      isFullPeriod,
      period1,
      period2,
      priceDiscount = 0,
      discount = 0
    } = currentBooking;

    const period1count = period1 ? 1 * quantity : '';
    const period2count = period2 ? 1 * quantity : '';

    const fakturaDatum = get(currentBooking, 'sentInvoice', false)
      ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
      : `Skickas efter ${handleTableDataTransform({
          seconds:
            parseInt(get(currentBooking, 'createAt.seconds'), 10) +
            60 * 60 * 24 * DAYS_TO_INVOICE
        })}`;

    const periodString = isFullPeriod ? '1 & 2' : period1 ? '1' : '2';

    if (filterBy(dataObj[bookingId])) {
      newObj[bookingId] = {
        ...users[userId],
        ...dataObj[bookingId],
        authId: userId,
        tent: taltyta,
        periods: periodString,
        period1count,
        period2count,
        typeOfOrg: transformNgoText(get(users[userId], 'typeOfOrg')),
        sum: tentsTotalPrice(currentBooking),
        discount: (discount + priceDiscount) * -1,
        fakturaDatum
      };
    }
  });
  return newObj;
};

// construct user object with all bookings
const addUserToSeminar = (seminarArray, users) => {
  const dataObj = { ...seminarArray };
  const newObj = {};
  Object.keys(dataObj).forEach((bookingId) => {
    if (!bookingId) {
      return;
    }

    const currentBooking = get(dataObj, bookingId);

    if (!currentBooking) {
      return;
    }

    const bookingUserId = get(currentBooking, 'userId');

    const fakturaDatum = get(currentBooking, 'sentInvoice', false)
      ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
      : `Skickas efter ${handleTableDataTransform({
          seconds:
            parseInt(get(currentBooking, 'createAt.seconds'), 10) +
            60 * 60 * 24 * DAYS_TO_INVOICE
        })}`;

    newObj[bookingId] = {
      ...users[bookingUserId],
      ...dataObj[bookingId],
      authId: bookingUserId,
      sum:
        parseInt(get(currentBooking, 'pris'), 10) -
        get(currentBooking, 'discount', 0),
      discount: -get(currentBooking, 'discount', 0),
      typeOfOrg: transformNgoText(get(users[bookingUserId], 'typeOfOrg')),
      fakturaDatum
    };
  });
  return newObj;
};

const addUserToAllBookings = (bookArray, users) => {
  return bookArray.map((currentBooking) => {
    const {
      id,
      userId,
      quantity,
      isFullPeriod,
      period1,
      period2,
      category,
      priceDiscount = 0,
      discount = 0
    } = currentBooking;

    if (category.includes('seminar')) {
      const fakturaDatum = get(currentBooking, 'sentInvoice', false)
        ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
        : `Skickas efter ${handleTableDataTransform({
            seconds:
              parseInt(get(currentBooking, 'createAt.seconds'), 10) +
              60 * 60 * 24 * DAYS_TO_INVOICE
          })}`;

      return {
        ...users[userId],
        ...currentBooking,
        authId: userId,
        bookingId: id,
        sum:
          parseInt(get(currentBooking, 'pris'), 10) -
          get(currentBooking, 'discount', 0),
        discount: -get(currentBooking, 'discount', 0),
        typeOfOrg: transformNgoText(get(users[userId], 'typeOfOrg')),
        fakturaDatum
      };
    }

    const period1count = period1 ? 1 * quantity : '';
    const period2count = period2 ? 1 * quantity : '';

    const fakturaDatum = get(currentBooking, 'sentInvoice', false)
      ? `Skickad ${handleTableDataTransform(get(currentBooking, 'invoiceCreatedAt')) || ''}`
      : `Skickas efter ${handleTableDataTransform({
          seconds:
            parseInt(get(currentBooking, 'createAt.seconds'), 10) +
            60 * 60 * 24 * DAYS_TO_INVOICE
        })}`;

    const periodString = isFullPeriod ? '1 & 2' : period1 ? '1' : '2';

    return {
      ...users[userId],
      ...currentBooking,
      authId: userId,
      bookingId: id,
      periods: periodString,
      period1count,
      period2count,
      typeOfOrg: transformNgoText(get(users[userId], 'typeOfOrg')),
      sum: tentsTotalPrice(currentBooking),
      discount: (discount + priceDiscount) * -1,
      fakturaDatum
    };
  });
};

// Function to save as CSV
function saveAsCSV(dataToExport) {
  jsonexport(
    dataToExport,
    {
      rowDelimiter: ';'
    },
    (err, csv) => {
      if (err) return console.error(err);
      const csvblob = new Blob([`\ufeff${csv}`], {
        type: 'text/csv;charset=utf-8'
      });
      saveAs(csvblob, 'bookings.csv');
    }
  );
}

// Function to save as JSON
function saveAsJSON(dataToExport) {
  const jsonString = JSON.stringify(dataToExport, null, 2);
  const jsonBlob = new Blob([jsonString], {
    type: 'application/json;charset=utf-8'
  });
  saveAs(jsonBlob, 'bookings.json');
}

// Function to export data in both formats
function exportData(dataToExport, fileFormat) {
  if (fileFormat === 'json') {
    return saveAsJSON(dataToExport);
  }
  return saveAsCSV(dataToExport);
}

export { addUserToBooking, addUserToSeminar, addUserToAllBookings, exportData };
