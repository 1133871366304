/**
 *
 * RenderCompanyListItem
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';

import {
  DataBox,
  JarvaButtonPrimary,
  JarvaButtonSecondary,
  WhiteBox
} from '../../styledComponents';
import { errorCallback, transformNgoText } from '../../utils/index';
import { IosContactOutline } from '../Icons/index';
import MyFallbackComponent from '../MyFallbackComponent';

class RenderCompanyListItem extends React.PureComponent {
  render() {
    const {
      handleAccept,
      handleReject,
      bransch,
      email,
      fakturaEmail,
      fakturaadress,
      fakturamottagare,
      firstName,
      hemsida,
      orgName,
      orgNr,
      ort,
      postnummer,
      secondName,
      telefonnummer,
      titel,
      typeOfOrg,
      id
    } = this.props;

    return (
      <WhiteBox>
        <DataBox>
          <h3>
            <IosContactOutline /> {orgName}
          </h3>
          <div className="horizontal-container">
            <ul aria-label="Kontaktuppgifter">
              <li aria-label="Namn">{`${firstName} ${secondName}`}</li>
              <li aria-label="E-post">{email}</li>
              <li aria-label="Befattning / Titel">{titel}</li>
              <li aria-label="Telefon">{telefonnummer}</li>
            </ul>
            <ul aria-label="Organisationsuppgifter">
              <li aria-label="Organisation">{orgName}</li>
              <li aria-label="Organisationsnummer">{orgNr}</li>
              <li aria-label="Organisationstyp">
                {transformNgoText(typeOfOrg)}
              </li>
              <li aria-label="Bransch">{bransch}</li>
              <li aria-label="Hemsida">{hemsida}</li>
            </ul>
            <ul aria-label="Fakturauppgifter">
              <li aria-label="Fakturamottagare / referens">
                {fakturamottagare}
              </li>
              <li aria-label="Fakturaadress">{fakturaadress}</li>
              <li aria-label="Postnummer">{postnummer}</li>
              <li aria-label="Ort">{ort}</li>
              <li aria-label="E-post för PDF faktura">{fakturaEmail}</li>
            </ul>
          </div>
          <div className="buttons-padded">
            <JarvaButtonPrimary
              onClick={() => {
                handleAccept(id);
              }}
            >
              Godkänn
            </JarvaButtonPrimary>
            <JarvaButtonSecondary
              onClick={() => {
                handleReject(id);
              }}
            >
              Avböj
            </JarvaButtonSecondary>
          </div>
        </DataBox>
      </WhiteBox>
    );
  }
}

RenderCompanyListItem.propTypes = {
  handleAccept: PropTypes.any,
  handleReject: PropTypes.any,
  bransch: PropTypes.any,
  email: PropTypes.any,
  fakturaEmail: PropTypes.any,
  fakturaadress: PropTypes.any,
  fakturamottagare: PropTypes.any,
  firstName: PropTypes.any,
  hemsida: PropTypes.any,
  orgName: PropTypes.any,
  orgNr: PropTypes.any,
  ort: PropTypes.any,
  postnummer: PropTypes.any,
  secondName: PropTypes.any,
  telefonnummer: PropTypes.any,
  titel: PropTypes.any,
  typeOfOrg: PropTypes.any,
  id: PropTypes.any
};
export default withErrorBoundary(
  RenderCompanyListItem,
  MyFallbackComponent,
  errorCallback
);
