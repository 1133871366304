import styled from 'styled-components';

const ProductBookingsContainer = styled.div`
  .price-row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    p {
      margin: 0 0 4px 0;
      padding: 0;
      color: rgba(8 21 44, 0.5);
      color: var(--jarva-text);
    }
  }

  .ui.button {
    margin: 0;
  }

  hr {
    color: rgba(0, 0, 0, 0.1);
  }
`;

export { ProductBookingsContainer };
