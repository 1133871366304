import React from 'react';
import PropTypes from 'prop-types';

function Spacer(props) {
  const { height = '1rem' } = props;

  return <div style={{ height }} />;
}

Spacer.propTypes = {
  height: PropTypes.string
};

export default Spacer;
