import styled from 'styled-components';

const Wrapper = styled.div`
  button.button {
    font-size: 0.8rem;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 2rem;
    border: currentcolor 2px solid !important;
    background: none !important;

    &.danger {
      color: #951a1acc;
    }
  }

  .images-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
  }
`;

export default Wrapper;
