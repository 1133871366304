import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { EVENT_DURATION_STRING } from '../../configs';
import { JarvaButtonBasic, vars } from '../../styledComponents';

const HeaderContainer = styled.nav`
  padding: 1rem 1.5rem;

  background-color: var(--jarva-light-back);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  a {
    text-decoration: none;
  }
`;

const SiteLogo = styled.h1`
  position: relative;
  z-index: 2;
  color: ${vars.jarvaTextDark};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: var(--font-size--label);
  padding: 0;
  margin: 0;
  span {
    font-weight: 450;
    margin-left: 0.6rem;
    font-size: var(--font-size--label);
  }
`;

const LogOutButton = styled.a`
  ${JarvaButtonBasic};
  background-color: var(--button-grey);
  color: var(--jarva-blue);
  font-size: var(--font-size--subheading);
  &:hover {
    background-color: var(--jarva-blue);
    color: white;
  }
`;

export class Header extends PureComponent {
  handleLogout = (e) => {
    e.preventDefault();
    const { firebase, dispatch } = this.props;
    firebase.logout().finally(() => {
      dispatch({
        type: 'RESET'
      });
      window.location.reload();
    });
  };

  render() {
    return (
      <HeaderContainer>
        <SiteLogo>
          <a href="https://jarvaveckan.se">Järvaveckan</a>{' '}
          <span>{EVENT_DURATION_STRING}</span>
        </SiteLogo>
        <LogOutButton onClick={this.handleLogout}>Logga ut</LogOutButton>
      </HeaderContainer>
    );
  }
}

Header.propTypes = {
  firebase: PropTypes.object.isRequired,
  dispatch: PropTypes.func
};

export default withRouter(Header);
