import styled from 'styled-components';

import { vars } from '../../styledComponents';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  background-color: transparent !important;
  border-color: transparent !important;
  table {
    width: 100%;

    thead {
      text-transform: capitalize;
      position: relative;

      border-bottom: 1px solid ${vars.jarvaBlue};

      th,
      td {
        cursor: pointer;
        color: ${vars.jarvaBlue};
        padding: 10px 28px 6px 10px;
        font-size: 0.8em;
        letter-spacing: 0.8px;
        position: relative;
        border-bottom: 0px;

        &:hover {
          background-color: ${vars.jarvaBlueLight};
        }

        &::after {
          content: '';
          border-top: 2px solid ${vars.jarvaBlue};
          border-right: 2px solid ${vars.jarvaBlue};
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%) rotate(135deg);
          height: 7px;
          width: 7px;
          transition: all 0.2s ease;
          opacity: 0;
          @media ${vars.desktopAndUp} {
            right: 14px;
          }
        }

        &.sorted {
          position: relative;
          background-color: rgba(226, 170, 204, 0.1);
          color: ${vars.jarvaBlue} !important;

          &::after {
            opacity: 1;
          }
        }

        &.reversed {
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    tr:nth-child(even) {
      background-color: rgb(247, 248, 252);
    }

    td,
    th {
      min-width: fit-content;
      white-space: nowrap;
    }

    td {
      padding: 17px 12px 15px 12px;
      padding-right: 6px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 0px;
      transition: all 0.3s ease;

      &.hasClick {
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }

      &:last-child {
        border-right: 0px solid #fff;
      }
    }

    td:first-child {
      color: ${vars.jarvaBlue};
      font-weight: bold;
    }

    tbody {
      tr:last-child {
        border-bottom: 1px solid ${vars.jarvaBlue};
      }
    }

    tfoot {
      td,
      th {
        padding: 1rem 28px 2rem 10px;
        font-weight: normal;
        font-size: 0.8em;
        color: ${vars.jarvaBlue};
        font-weight: 700;
      }
    }
  }
`;

export { TableContainer };
