import firebase from 'firebase/app';

export const clearDateBeforeSaving = (data) => {
  const newData = { ...data }; // Create a shallow copy of the data object

  Object.keys(newData).forEach((key) => {
    if (
      newData[key] instanceof firebase.firestore.Timestamp ||
      newData[key] instanceof FileList
    ) {
      delete newData[key];
    } else if (
      typeof newData[key] === 'object' &&
      !Array.isArray(newData[key])
    ) {
      newData[key] = clearDateBeforeSaving(newData[key]);
    }
  });

  return newData;
};
