/**
 *
 * SignUpContainer
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import CompanyDetails from '../../components/CompanyDetails';
import Invoice from '../../components/Invoice';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import UserDetails from '../../components/UserDetails';
import { errorCallback, sendDataToSlack } from '../../utils/index';

const Wrapper = styled.div`
  width: 100%;
`;

class SignUpContainer extends React.Component {
  state = {
    firstName: '',
    secondName: '',
    titel: '',
    email: '',
    password: '',
    repeatPassword: '',
    passwordError: false,
    formFilled: false,
    orgName: '',
    orgNr: '',
    typeOfOrg: '',
    bransch: '',
    fb: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    godkannKopvillkorOchBekraftelse: false,
    godkannJarvaVekanKod: false,
    disableSubmit: false
  };

  // #region createUser
  createNewUser = () => {
    const {
      repeatPassword,
      passwordError,
      formFilled,
      disableSubmit,
      password,
      email,
      ...data
    } = this.state;

    const { firebase, firestore } = this.props;

    firebase
      .createUser(
        { email, password, signIn: true },
        {
          email,
          isApproved: false,
          isRejected: false,
          pending: true,
          createAt: firestore.FieldValue.serverTimestamp(),
          ...data
        }
      )
      .then((res) => {
        console.info('user created');
        try {
          sendDataToSlack(
            'NEW USER: Successfully sent to DB',
            JSON.stringify(res),
            'https://hooks.slack.com/services/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'
          );
        } catch (e) {
          // console.log('not json to stringify', e);
        }
      })
      .catch((error) => {
        console.error(error);
        try {
          sendDataToSlack(
            'Error during sign-up!',
            JSON.stringify(error),
            'https://hooks.slack.com/services/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'
          );
        } catch (e) {
          // console.log('not json to stringify', e);
        }
      });
  };
  // #endregion

  handleInputChange = (_, { name, value, type, checked }) => {
    const { disableSubmit } = this.state;
    const localValue = type === 'checkbox' ? checked : value;
    this.setState({ [name]: localValue });
    if (disableSubmit) this.setState({ disableSubmit: false });
  };

  /*
  currentUser.getIdToken(true)

  firebase.auth().currentUser.getIdTokenResult()
  .then((idTokenResult) => {
     // Confirm the user is an Admin.
     if (!!idTokenResult.claims.admin) {
       // Show admin UI.
       showAdminUI();
     } else {
       // Show regular user UI.
       showRegularUI();
     }
  })
  .catch((error) => {
    console.log(error);
  });

  */

  // eslint-disable-next-line class-methods-use-this
  setSessionStorage = (state) => {
    window.sessionStorage.setItem(
      'registrationForm',
      JSON.stringify({
        ...state
      })
    );
  };

  nextStep = (e) => {
    e.preventDefault();
    const { handelNextStep } = this.props;
    handelNextStep();
    this.setSessionStorage(this.state);
  };

  prevStep = (e) => {
    e.preventDefault();
    const { handelPrevStep } = this.props;
    handelPrevStep();
    this.setSessionStorage(this.state);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    this.createNewUser();
    window.sessionStorage.removeItem('registrationForm');
  };

  handleCancelClick = () => {
    const { handelReset } = this.props;
    handelReset();
  };

  checkFormFilled = (step) => {
    if (step === 1) {
      const { firstName, secondName, email, password } = this.state;
      if (
        !(
          firstName.length > 0 &&
          secondName.length > 0 &&
          email.length > 0 &&
          password.length > 0
        )
      ) {
        return;
      }
      this.setState({ formFilled: true });
    }
  };

  checkPassword = () => {
    this.setState((state) => ({
      passwordError: !(state.password === state.repeatPassword)
    }));
    this.checkFormFilled(1);
  };

  stepper = () => {
    const { step, totalSteps, authError, goToLogin } = this.props;
    switch (step) {
      case 2:
        return (
          <Wrapper>
            <CompanyDetails
              {...{
                handleInputChange: this.handleInputChange,
                handleCancelClick: this.handleCancelClick,
                nextStep: this.nextStep,
                prevStep: this.prevStep,
                checkFormFilled: this.checkFormFilled,
                values: { ...this.state, totalSteps, step },
                authError,
                title: 'Fyll i organisationsuppgifter'
              }}
            />
          </Wrapper>
        );
      case 3:
        return (
          <Wrapper>
            <Invoice
              {...{
                handleInputChange: this.handleInputChange,
                handleCancelClick: this.handleCancelClick,
                handleSubmit: this.handleSubmit,
                prevStep: this.prevStep,
                values: { ...this.state, totalSteps, step },
                authError,
                title: 'Fyll i organisationens faktureringsuppgifter'
              }}
            />
          </Wrapper>
        );
      case 4:
        return (
          <Wrapper>
            <div>Kontrollera din e-post</div>
          </Wrapper>
        );
      case 1:
      default:
        return (
          <Wrapper>
            <UserDetails
              {...{
                handleInputChange: this.handleInputChange,
                checkPassword: this.checkPassword,
                checkFormFilled: this.checkFormFilled,
                nextStep: this.nextStep,
                goToLogin,
                values: { ...this.state, totalSteps, step },
                title: 'Fyll i nedan för att skapa ett konto',
                authError
              }}
            />
          </Wrapper>
        );
    }
  };

  render() {
    return this.stepper();
  }
}

SignUpContainer.propTypes = {
  firebase: PropTypes.object,
  firestore: PropTypes.object,
  authError: PropTypes.object,
  handelReset: PropTypes.func,
  goToLogin: PropTypes.func,
  handelPrevStep: PropTypes.func,
  handelNextStep: PropTypes.func,
  step: PropTypes.number,
  totalSteps: PropTypes.number
};
const mapStateToProps = createStructuredSelector({
  authError: (state) => state.firebase.authError
});
const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withFirestore,
  withConnect
)(withErrorBoundary(SignUpContainer, MyFallbackComponent, errorCallback));
