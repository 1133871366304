/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'semantic-ui-react';

import ContentEditor from '../../components/ContentEditor';
import Loader from '../../components/Loader';
import { WarnLogo } from '../../components/Logos';
import MyFallbackComponent from '../../components/MyFallbackComponent';
import {
  ContentContainer,
  JarvaButtonPrimary,
  JarvaButtonSecondary,
  JarvaDivider,
  WhiteBox
} from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import {
  getTotalCurrentTentBookings,
  getTotalCurrentSeminarBookings,
  getTotalCurrentSeminarLiveBookings,
  getTotalCurrentSponsorBookings,
  getProfile
} from '../App/selectors';
import {
  ButtonContainer,
  Paragraph,
  StyledTextWrapper
} from './homePageStyles';

function HomePage(props) {
  const {
    history,
    isApproved,
    isApprovedLoaded,
    isBookingsLoaded,
    profile,
    totalCurrentTentBookings,
    totalCurrentSeminarBookings,
    totalCurrentSeminarLiveBookings,
    totalCurrentSponsorBookings
  } = props;

  const { orgName } = profile;
  // #region renderNotApproved
  const renderNotApproved = () => (
    <>
      <Paragraph className="text-wrapper">
        <WarnLogo />
        <ContentEditor contentId="page-user-content-not-approved">
          Vi har inte hunnit gå igenom er ansökan för att verifiera er som
          deltagare på Järvaveckan. Vi kontaktar er via mail så fort vi har
          verifierat era uppgifter. Då får ni tillgång till våra
          bokningsalternativ. Vi återkommer så snart vi kan!
        </ContentEditor>
      </Paragraph>
      <JarvaDivider />

      <br />
      <ButtonContainer>
        <button type="button">
          <JarvaButtonPrimary
            as="a"
            target="_blank"
            rel="noopener"
            href="http://jarvaveckan.se"
          >
            <ContentEditor contentId="page-user-button-tillbaka">
              Tillbaka till Järvaveckan.se
            </ContentEditor>
          </JarvaButtonPrimary>
        </button>
        <JarvaButtonSecondary
          onClick={() => {
            history.push('/user/book');
          }}
        >
          <ContentEditor contentId="page-user-button-bookings">
            Se bokningsalternativ
          </ContentEditor>
        </JarvaButtonSecondary>
      </ButtonContainer>
      <br />
    </>
  );
  // #endregion

  // #region renderApproved
  const renderApproved = () => (
    <>
      <div className="text-wrapper">
        <ContentEditor contentId="page-user-content-1" className="p">
          Vi vill att alla arrangörer fyller i sin information senast den 3 maj
          2024. Men mindre justeringar kan göras fram till och under
          Järvaveckan.
        </ContentEditor>
      </div>
      <JarvaDivider />
      <br />
      <ButtonContainer>
        <JarvaButtonPrimary
          onClick={() => {
            history.push('/user/book');
          }}
        >
          <ContentEditor
            inline
            isButton
            contentId="page-user-content-button-text"
          >
            Boka din plats på Järvaveckan
          </ContentEditor>
        </JarvaButtonPrimary>
      </ButtonContainer>
      <br />
    </>
  );
  // #endregion

  // #region renderOrderSummary
  const renderOrderSummary = () => (
    <StyledTextWrapper className="text-wrapper">
      <p>
        Ni har bokat:
        {totalCurrentTentBookings ? (
          <>
            <br /> {`${totalCurrentTentBookings} tältyta`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSeminarBookings ? (
          <>
            <br /> {`${totalCurrentSeminarBookings} seminarietider`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSeminarLiveBookings ? (
          <>
            <br /> {`${totalCurrentSeminarLiveBookings} seminarietider live`}
          </>
        ) : (
          ''
        )}
        {totalCurrentSponsorBookings ? (
          <>
            <br /> {`${totalCurrentSponsorBookings} sponsorskap`}
          </>
        ) : (
          ''
        )}
      </p>
      <Button
        onClick={() => {
          history.push('/user/bookings');
        }}
        primary
        basic
        content="Se dina bokningar"
      />
      <br />
      <br />

      <WarnLogo />
      <ContentEditor contentId="page-user-content-booking-reminder-info">
        Alla bokningar har 5 dagar från bokningsdatumet på sig att ändra datum
        eller avboka din bokning. Efter det kan du fortfarande ändra
        beskrivningen av ditt tält eller seminarium.
      </ContentEditor>
    </StyledTextWrapper>
  );

  // #endregion

  const hasBookings =
    totalCurrentTentBookings ||
    totalCurrentSeminarBookings ||
    totalCurrentSponsorBookings;
  // const hasBookings =
  //   !isEmpty(currentUserBookings) || !isEmpty(currentUserSeminars) || !isEmpty(currentUserSponsor);

  const renderSteps = () => {
    if (isApproved) {
      if (hasBookings) {
        return renderOrderSummary();
      }
      return renderApproved();
    }
    return renderNotApproved();
  };

  return (
    <ContentContainer>
      <section style={{ paddingBottom: '0px' }}>
        <WhiteBox>
          <div className="text-wrapper">
            {isApprovedLoaded && isBookingsLoaded ? (
              <>
                <h2>
                  {hasBookings ? (
                    <ContentEditor inline contentId="page-user-title-tillbaka">
                      Välkommen tillbaka!
                    </ContentEditor>
                  ) : (
                    <ContentEditor inline contentId="page-user-title-default">
                      Välkommen till Järvaveckans bokningssida
                    </ContentEditor>
                  )}{' '}
                </h2>
                <br />
                <p>
                  Du är inloggad som <b>{orgName}</b>.
                </p>
                <JarvaDivider />
                {renderSteps()}
              </>
            ) : (
              <Loader text="" />
            )}
          </div>
        </WhiteBox>
      </section>
    </ContentContainer>
  );
}

HomePage.propTypes = {
  profile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isBookingsLoaded: PropTypes.bool.isRequired,
  isApprovedLoaded: PropTypes.bool.isRequired,
  totalCurrentTentBookings: PropTypes.number.isRequired,
  totalCurrentSeminarBookings: PropTypes.number.isRequired,
  totalCurrentSeminarLiveBookings: PropTypes.number.isRequired,
  totalCurrentSponsorBookings: PropTypes.number.isRequired
};

const mapStateToProps = createStructuredSelector({
  totalCurrentTentBookings: getTotalCurrentTentBookings,
  totalCurrentSeminarBookings: getTotalCurrentSeminarBookings,
  totalCurrentSeminarLiveBookings: getTotalCurrentSeminarLiveBookings,
  totalCurrentSponsorBookings: getTotalCurrentSponsorBookings,
  profile: getProfile
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(
  withErrorBoundary(HomePage, MyFallbackComponent, errorCallback)
);
