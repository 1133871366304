/**
 * docs https://blog.isquaredsoftware.com/2017/12/idiomatic-redux-using-reselect-selectors/
 */
import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';
import { createSelector } from 'reselect';

//FireStore Data
const getOrderedUsers = (state) => get(state, 'firestore.ordered.users');
const getOrderedBookings = (state) => get(state, 'firestore.ordered.bookings');
const getOrderedTillaggBookings = (state) =>
  get(state, 'firestore.ordered.tillaggBookings');
const getOrderedSeminarBookings = (state) =>
  get(state, 'firestore.ordered.seminarBookings');
const getOrderedSeminarLiveBookings = (state) =>
  get(state, 'firestore.ordered.seminarLiveBookings');
const getOrderedSponsorBookings = (state) =>
  get(state, 'firestore.ordered.sponsorBookings');

const getUsers = (state) => get(state, 'firestore.data.users');
const getBookings = (state) => get(state, 'firestore.data.bookings');
const getTillaggBookings = (state) =>
  get(state, 'firestore.data.tillaggBookings');
const getSeminarsBookings = (state) =>
  get(state, 'firestore.data.seminarBookings');
const getSeminarsLiveBookings = (state) =>
  get(state, 'firestore.data.seminarLiveBookings');
const getSponsorBookings = (state) =>
  get(state, 'firestore.data.sponsorBookings');

const getPrivate = (state) => get(state, 'firestore.data.private');
const getCurrentUser = (state) => get(state, 'firestore.data.currentUser');
const getAuth = (state) => get(state, 'firebase.auth');
const getProfile = (state) => get(state, 'firebase.profile');
const getCurrentPath = (state) => get(state, 'router.location.pathname');

const getCurrentUserBookings = (state) =>
  get(state, 'firestore.data.currentUserBookings');
const getCurrentUserTillaggBookings = (state) =>
  get(state, 'firestore.data.currentUserTillaggBookings');

const getStats = (state) => get(state, 'firestore.data.stats');
const getAssets = (state) => get(state, 'firestore.data.assets', {});
const getContent = (state) => get(state, 'firestore.data.content', {});
const getProducts = (state) => get(state, 'firestore.data.products', {});
const getProductBookings = (state) =>
  get(state, 'firestore.data.productBookings', {});
const getOrderedProductBookings = (state) =>
  get(state, 'firestore.ordered.productBookings');

const getIsEdit = (state) => get(state, 'edit.isEdit', false);
const getClaims = (state) => get(state, 'claims', false);

const getSeminarPlacementList = (state) =>
  get(state, 'firestore.data.seminarPlacementList');

const getSeminarLivePlacementList = (state) =>
  get(state, 'firestore.data.seminarLivePlacementList');

const isLoadingbookings = (state, props) =>
  state.firestore.status.requesting[
    `bookings?where=userId:==:${props.auth.uid}`
  ];

const getCurrentUserSeminarsBookings = (state) =>
  get(state, 'firestore.data.currentUserSeminarsBookings');

const getCurrentUserSeminarsLiveBookings = (state) =>
  get(state, 'firestore.data.currentUserSeminarsLiveBookings');

const getCurrentUserSponsorBookings = (state) =>
  get(state, 'firestore.data.currentUserSponsorBookings');

const getCurrentUserProductBookings = (state) =>
  get(state, 'firestore.data.currentUserProductBookings');

const getOrderedUserProductBookings = (state) =>
  get(state, 'firestore.ordered.currentUserProductBookings');

// Computed State
const getAuthId = createSelector(getAuth, (a) => a.uid);

const getTotalCurrentTentBookings = createSelector(
  getCurrentUserBookings,
  (bookings) => size(bookings)
);

const getTotalCurrentTillaggBookings = createSelector(
  getCurrentUserTillaggBookings,
  (bookings) => size(bookings)
);

const getTotalCurrentSeminarBookings = createSelector(
  getCurrentUserSeminarsBookings,
  (bookings) => size(bookings)
);

const getTotalCurrentSeminarLiveBookings = createSelector(
  getCurrentUserSeminarsLiveBookings,
  (bookings) => size(bookings)
);

const getTotalCurrentSponsorBookings = createSelector(
  getCurrentUserSponsorBookings,
  (bookings) => size(bookings)
);

const getTotalCurrentProductBookings = createSelector(
  getCurrentUserProductBookings,
  (bookings) => size(bookings)
);

const applicantsSelector = createSelector(
  getOrderedUsers,
  getPrivate,
  (orderedUsers, _private) =>
    filter(orderedUsers, (o) => get(_private, `${o.id}.status.pending`))
);

const getNotApprovedUsers = createSelector(
  getOrderedUsers,
  // to get all users who are not approved
  (users) => filter(users, { isApproved: false, isRejected: false })
);

const getUsersFromPropId = createSelector(
  (state, props) => {
    const uid = get(props, 'auth.uid');
    return get(state, `firestore.data.users${[uid]}`);
  },
  (a) => a
);

export {
  getAuth,
  getAuthId,
  getUsers,
  getBookings,
  getTillaggBookings,
  getProfile,
  getPrivate,
  getSeminarsBookings,
  getSeminarsLiveBookings,
  applicantsSelector,
  getNotApprovedUsers,
  getUsersFromPropId,
  getCurrentUser,
  getCurrentPath,
  getCurrentUserBookings,
  getCurrentUserTillaggBookings,
  getCurrentUserSeminarsBookings,
  getCurrentUserSeminarsLiveBookings,
  getCurrentUserSponsorBookings,
  getCurrentUserProductBookings,
  getTotalCurrentTentBookings,
  getTotalCurrentTillaggBookings,
  getTotalCurrentSeminarBookings,
  getTotalCurrentSeminarLiveBookings,
  getTotalCurrentSponsorBookings,
  getTotalCurrentProductBookings,
  getOrderedBookings,
  getOrderedTillaggBookings,
  getOrderedUserProductBookings,
  getOrderedSeminarBookings,
  getOrderedSeminarLiveBookings,
  getOrderedSponsorBookings,
  getSponsorBookings,
  getOrderedUsers,
  isLoadingbookings,
  getStats,
  getAssets,
  getContent,
  getClaims,
  getIsEdit,
  getSeminarPlacementList,
  getSeminarLivePlacementList,
  getProducts,
  getProductBookings,
  getOrderedProductBookings
};
