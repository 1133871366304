import styled from 'styled-components';

import vars from './vars';

const DataBox = styled.div`
  color: ${vars.jarvaTextDark};
  transition: all 0.4s ease;
  .buttons-padded {
    button {
      margin: 5px;
      margin-right: 20px;
      width: 100%;
      height: 35px;
      max-width: 235px;
      max-height: 44px;
    }
  }

  & > div,
  .list-container,
  form {
    display: flex;
    gap: 2rem;
    flex-direction: column-reverse;

    .grid.ui {
      width: 100%;
      .row {
        position: relative;
      }
    }

    ${(props) => {
      return props.columnReverse
        ? 'flex-direction: column-reverse;'
        : 'flex-direction: column;';
    }}

    @media ${vars.desktopAndUp} {
      flex-direction: row;
    }

    /* &.horizontal-container {
      margin: 0 -10px;
    } */

    ul {
      list-style-type: none;
      flex-grow: 1;
      padding: 0;

      &:before {
        content: attr(aria-label);
        font-size: var(--font-size--label) !important;
        font-style: normal;
        font-weight: 450;
        letter-spacing: 0.06em !important;
        line-height: 160% !important;
        color: ${vars.jarvaTextDark};
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      & > li {
        position: relative;
        margin: 10px 0 0 0;
      }

      & > li:first-child:after {
        content: ' ';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      li {
        padding: 10px 0 8px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        &:before {
          margin-bottom: 0px;
          content: attr(aria-label);
          display: block;
          color: ${vars.textColorGray};
          padding-top: 0px;
          padding-bottom: 2px;
          font-size: 0.9em;
        }
      }
      &.withoutLastBorder {
        li:last-child {
          border-bottom: 0px solid rgba(0, 0, 0, 0.08);
        }
      }
    }

    ul.bulletList {
      &:before,
      &:after {
        all: unset;
      }
      li {
        margin: 0;
        font-size: 1rem;
        &:before,
        &:after {
          all: unset;
        }
        border-bottom: 0;
        list-style-type: square;
      }
      display: block;
      list-style-type: square;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 14px;
      margin-right: 0;
      padding-left: 15px;
    }
  }

  // semantic ui override
  .ui {
    &.label {
      margin-bottom: 8px;
    }
  }
`;

export default DataBox;
