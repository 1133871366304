import styled from 'styled-components';

import { vars, JarvaButtonPrimary } from '../../styledComponents';

const ImgContainer = styled.div`
  background-color: ${(props) => props.bgColor || vars.jarvaBlue};
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    max-width: 60%;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
  display: flex;

  > :first-child {
    flex-shrink: 50;
    margin: auto;
  }
  > :last-child {
    padding: 15px 20px;
    padding-right: 0;
    flex-basis: 82%;
  }
`;

const JarvaButtonPrimaryWithMessage = styled(JarvaButtonPrimary)`
  position: relative;
  :disabled:hover::after,
  :disabled:active::after {
    content: attr(aria-label);
    display: inline-block;
    border-radius: 5px;
    padding: 10px;
    background-color: ${vars.jarvaBlue};
    color: white;
    min-width: 200px;
    position: absolute;
    bottom: 120%;
    left: 0;
  }
`;

export { ImgContainer, CardContainer, JarvaButtonPrimaryWithMessage };
