/**
 *
 * @return {string}
 */
import get from 'lodash/get';

import tentsTotalPrice from './tentsTotalPrice';

const totalCost = ({
  OrderedBookings = [],
  OrderedSeminarBookings = [],
  OrderedSeminarLiveBookings = [],
  OrderedSponsorBookings = []
}) => {
  let totalPrice = 0;

  if (OrderedBookings.length > 0) {
    totalPrice += OrderedBookings.reduce(
      (sum, item) => tentsTotalPrice(item) + sum,
      0
    );
  }

  if (OrderedSeminarBookings.length > 0) {
    totalPrice += OrderedSeminarBookings.reduce(
      (sum, item) => parseInt(get(item, 'pris'), 10) + sum,
      0
    );
  }

  if (OrderedSeminarLiveBookings.length > 0) {
    totalPrice += OrderedSeminarLiveBookings.reduce(
      (sum, item) => parseInt(get(item, 'pris'), 10) + sum,
      0
    );
  }

  if (OrderedSponsorBookings.length > 0) {
    totalPrice += OrderedSponsorBookings.reduce(
      (sum, item) => parseInt(get(item, 'pris'), 10) + sum,
      0
    );
  }

  return totalPrice || 0;
};

export { totalCost };
