import { useNetworkState } from '@uidotdev/usehooks';
import { OfflineOverlay } from '../../styledComponents';

function Offline() {
  const network = useNetworkState();

  if (network.online) return null;

  return (
    <OfflineOverlay>
      <p>Du är offline. </p>
      <p>Kontrollera din internetanslutning.</p>
    </OfflineOverlay>
  );
}

export default Offline;
