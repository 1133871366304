/**
 *
 * Sponsor
 *
 */
import React, { Fragment } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import set from 'lodash/set';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Message, Modal, Label } from 'semantic-ui-react';

import { uploadFileToStorage } from '../../api/index';
import BookingConfirm from '../../components/BookingConfirm';
import BookingInformationNote from '../../components/BookingInformationNote';
import ContentEditor from '../../components/ContentEditor';
import Loader from '../../components/Loader';
import RenderCosponsor from '../../components/RenderCosponsor';
import RenderTents from '../../components/RenderTents';
import RenderUserDetails from '../../components/RenderUserDetails/index';
import TermsCheckBox from '../../components/TermsCheckBox';
import TextCopy from '../../components/TextCopy/TextCopy';
import {
  EVENT_YEAR,
  AMNE_OPTIONS,
  TILLGANGLIGHET_OPTIONS,
  SPRAK_OPTIONS,
  MAX_SELECT
} from '../../configs';
import {
  CardContainer,
  ContentContainer,
  WhiteBox,
  DataBox,
  P
} from '../../styledComponents';
import { isOrgTypeNgo } from '../../utils';
import { SelectProducts } from '../Tent/styles';

export class Sponsor extends React.Component {
  state = {
    step: 1,
    fileUploadError: '',
    cosponsorsCount: 0,
    cosponsors: {},
    submitting: false,
    logo: '',
    kopvillkor: '',
    work_man_info: '',
    godkannKopvillkorOchBekraftelse: false,
    godkannJarvaVekanKod: false,
    modalOpen: false
  };

  filterKey = 'sponsor';

  async componentDidMount() {
    const { setStep } = this.props;
    this.setState({ step: setStep || 1 });
  }

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  // #region handleInputChange
  handleInputChange = (cosponsorsId) => (e) => {
    const {
      target: { name, value, type, checked }
    } = e;
    if (cosponsorsId) {
      this.setState((prevState) => {
        const { cosponsors } = prevState;
        const newState = { ...cosponsors };
        newState[cosponsorsId] = {
          ...cosponsors[cosponsorsId],
          [name]: value
        };
        return { cosponsors: newState };
      });
    } else {
      const localValue = type === 'checkbox' ? checked : value;
      this.setState({ [name]: localValue });
    }
  };
  // #endregion

  handleCheckboxChange = (_, { name, value, type, checked }) => {
    const localValue = type === 'checkbox' ? checked : value;
    this.setState({ [name]: localValue });
  };

  // #region deleteCosponsors
  deleteCosponsors = (key) => {
    this.setState((prevState) => {
      const cosponsors = { ...prevState.cosponsors };
      delete cosponsors[key];
      return {
        ...prevState,
        cosponsors,
        cosponsorsCount: prevState.cosponsorsCount - 1
      };
    });
  };
  // #endregion

  handleNextButtonClick = (data) => {
    // console.log(data);
    this.setState((prevState) => ({
      step: prevState.step + 1,
      selectedProduct: { ...data }
    }));
  };

  // #region handleSubmit
  handleSubmit = async () => {
    this.setState({ submitting: true });

    // default values for tent for partners

    const { logo, kontaktProfilbild, kontaktProfilbild2 } = this.state;

    const {
      firebase,
      firestore,
      profile,
      auth: { uid }
    } = this.props;

    const organizerName = get(profile, 'orgName') || '';

    const omitState = [
      'step',
      'kopvillkor',
      'work_man_info',
      'modalOpen',
      'selectedProduct',
      'logo',
      'kontaktProfilbild',
      'kontaktProfilbild2',
      'submitting',
      'fileUploadError',
      'sponsorBookingsError'
    ];

    const selectedState = omit(this.state, omitState);

    const selectedProduct = get(this, 'state.selectedProduct');

    if (!selectedProduct) {
      return 'empty product';
    }

    const filteredProductProperties = omit(selectedProduct, [
      'createAt',
      'updateAt',
      'images'
    ]);

    const { pris } = filteredProductProperties;

    const tentDefaults = {
      quantity: 1,
      productQuantity: 1,
      period1: true,
      period2: true,
      totalPrice: pris // needed to match like tent
    };

    const bookingDataRaw = {
      userId: uid,
      collection: 'sponsorBookings',
      ...tentDefaults,
      sentInvoice: false,
      ...selectedState,
      ...filteredProductProperties,
      createAt: firestore.FieldValue.serverTimestamp(),
      organizerName
    };

    if (logo) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: logo,
          firebase
        });

        if (downloadURL) {
          bookingDataRaw.logo = downloadURL;
        }
      } catch (error) {
        console.error('upload logo error', error);
      } finally {
        console.info('logo uploaded request finished');
      }
    }

    if (kontaktProfilbild) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: kontaktProfilbild,
          firebase
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild', error);
      } finally {
        console.info('kontaktProfilbild request finished');
      }
    }

    if (kontaktProfilbild2) {
      try {
        const downloadURL = await uploadFileToStorage({
          uid,
          fileList: kontaktProfilbild2,
          firebase
        });
        if (downloadURL) {
          bookingDataRaw.kontaktProfilbild2 = downloadURL;
        }
      } catch (error) {
        console.error('kontaktProfilbild2', error);
      } finally {
        console.info('kontaktProfilbild2 request finished');
      }
    }

    const bookingData = omit(bookingDataRaw, isNil);

    firestore
      .add(
        {
          collection: 'sponsorBookings',
          path: 'currentUserSponsorBookings'
        },
        bookingData
      )
      .then(() => {
        this.setState((prevState) => ({ step: prevState.step + 1 }));
      })
      .catch((err) => this.setState({ error: err }))
      .finally(() => {
        firestore
          .get({
            collection: 'sponsorBookings',
            path: 'currentUserSponsorBookings',
            where: ['userId', '==', uid]
          })
          .catch((err) => console.error(err))
          .finally(() => this.setState({ submitting: false }));
      });
  };
  // #endregion

  selectedProducts = (products) =>
    filter(products, (item) => {
      const { category, productId } = item;

      // if not ngo remove 'tent2_5x2_5'
      const isNgoUser = isOrgTypeNgo(get(this.props, 'currentUser.typeOfOrg'));

      if (productId === 'tent2_5x2_5' && !isNgoUser) {
        return false;
      }

      return includes(category, this.filterKey);
    });

  // #region renderSteps
  renderSteps = () => {
    const { step, godkannKopvillkorOchBekraftelse, godkannJarvaVekanKod } =
      this.state;
    const { products, history, loading, firestore, currentUser, authId } =
      this.props;

    const { organizerName } = currentUser || {};

    if (loading) {
      return (
        <ContentContainer>
          <WhiteBox>
            <section>
              <p>
                <Loader text="" />
              </p>
            </section>
          </WhiteBox>
        </ContentContainer>
      );
    }

    // if (totalCurrentSponsorBookings >= 1)
    //   return <BookingConfirm history={history} />;

    switch (step) {
      case 2: {
        const selectedProduct = get(this, 'state.selectedProduct', {});

        if (!selectedProduct) {
          return 'empty product';
        }

        const {
          productId,
          pris,
          taltyta,
          labelDay1,
          labelDay2,
          labelDay3,
          labelDay4,
          fullPeriodLabel
        } = selectedProduct;

        // const { isFullPeriod, totalPrice } =
        //   priceOfSelectedProduct(selectedProduct);

        const {
          error,
          ovrigt,
          cosponsors,
          highlightsDay1,
          highlightsDay2,
          highlightsDay3,
          highlightsDay4,
          submitting,
          modalOpen,
          amne,
          tillganglighet,
          sprak,
          reservationSiteLink,
          kontaktperson,
          kontaktJobbtitel,
          kontaktOrganisation,
          kontaktEmail,
          kontaktTel,
          kontaktperson2,
          kontaktJobbtitel2,
          kontaktOrganisation2,
          kontaktEmail2,
          kontaktTel2
        } = this.state;

        return (
          <Fragment key={productId}>
            <WhiteBox transparent>
              <h2>Information om ditt partnerskappaket</h2>
              <ContentEditor contentId="page-book-sponsor-step-2-info">
                Här kan ni fylla i information kring era aktiviteter på
                Järvaveckan 2024. För att säkra er plats och genomföra bokningen
                behöver ni inledningsvis enbart fylla i uppgifterna under Mitt
                Konto för att kunna göra en bokning.
              </ContentEditor>
              <br />
              <p>
                <b>Period:</b> {fullPeriodLabel} <br />
                <b>Tält:</b> {taltyta}.<br />
                <b>Pris:</b> {Number(pris).toLocaleString('sv')} kr exkl. moms
                <br />
              </p>

              <BookingInformationNote />
            </WhiteBox>
            <WhiteBox>
              <Form
                error={Boolean(error)}
                onSubmit={this.handleOpen}
                loading={submitting}
              >
                <Grid stackable columns="equal">
                  <Grid.Column>
                    <h5>Uppgifter för tältet</h5>
                    <Form.TextArea
                      onChange={this.handleInputChange()}
                      label="Förklara vad ni vill att besökare ska veta om era aktiviteter (max 800 tecken inkl. blanksteg)"
                      maxLength="800"
                      id="ovrigt"
                      name="ovrigt"
                      value={ovrigt || ''}
                    />
                    <h5>Kategorityp</h5>
                    <Form.Select
                      multiple
                      label="Ämne"
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }
                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="amne"
                      name="amne"
                      value={amne || []}
                      options={AMNE_OPTIONS}
                      placeholder="välj ett eller flera"
                    />
                    <Form.Select
                      label="Tillgänglighet"
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }
                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="tillganglighet"
                      name="tillganglighet"
                      value={tillganglighet || []}
                      multiple
                      options={TILLGANGLIGHET_OPTIONS}
                      placeholder="välj"
                    />
                    <Form.Select
                      label="Språk"
                      multiple
                      onChange={(_, target) => {
                        const { name, value } = target;
                        if (value.length > MAX_SELECT) {
                          return alert('Max 5 val');
                        }
                        this.setState((prevState) => ({
                          ...prevState,
                          [name]: value
                        }));
                      }}
                      id="sprak"
                      name="sprak"
                      value={sprak || []}
                      options={SPRAK_OPTIONS}
                      placeholder="välj"
                    />

                    <h5>
                      Extern länk för anmälan via exempelvis Eventbrite eller
                      Invajo.
                    </h5>
                    <Form.Input
                      onChange={this.handleInputChange()}
                      id="reservationSiteLink"
                      name="reservationSiteLink"
                      value={reservationSiteLink || ''}
                      placeholder="https://accomplice.se"
                      label="Fyller du i detta fält
                      så kommer en 'Anmälan' knapp synas på hemsidan. Knappen öppnar
                      den länk du fyller i."
                    />

                    <h5>KONTAKTPERSONER FÖR TÄLTET</h5>
                    <Label className="info">
                      Kontaktinfo (email & tel) kommer synas på vår hemsida
                      offentligt!
                    </Label>
                    {/* contact 1 */}
                    <fieldset>
                      <legend>Kontaktperson: 1</legend>
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktperson"
                        name="kontaktperson"
                        value={kontaktperson || ''}
                        label="Förnamn och efternamn"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktJobbtitel"
                        name="kontaktJobbtitel"
                        value={kontaktJobbtitel || ''}
                        label="Jobbtitel"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktOrganisation"
                        name="kontaktOrganisation"
                        value={kontaktOrganisation || ''}
                        label="Organisation"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktEmail"
                        name="kontaktEmail"
                        label="Email"
                        type="email"
                        value={kontaktEmail || ''}
                        placeholder="name@mail.com"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktTel"
                        name="kontaktTel"
                        label="Tel"
                        type="tel"
                        value={kontaktTel || ''}
                        placeholder="+46123456789"
                      />
                    </fieldset>
                    {/* contact 2 */}
                    <fieldset>
                      <legend>Kontaktperson: 2</legend>
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktperson2"
                        name="kontaktperson2"
                        value={kontaktperson2 || ''}
                        label="Förnamn och efternamn"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktJobbtitel2"
                        name="kontaktJobbtitel2"
                        value={kontaktJobbtitel2 || ''}
                        label="Jobbtitel"
                      />
                      <Form.Input
                        maxLength="120"
                        onChange={this.handleInputChange()}
                        id="kontaktOrganisation2"
                        name="kontaktOrganisation2"
                        value={kontaktOrganisation2 || ''}
                        label="Organisation"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktEmail2"
                        name="kontaktEmail2"
                        label="Email"
                        type="email"
                        value={kontaktEmail2 || ''}
                        placeholder="name@mail.com"
                      />
                      <Form.Input
                        maxLength="140"
                        onChange={this.handleInputChange()}
                        id="kontaktTel2"
                        name="kontaktTel2"
                        label="Tel"
                        type="tel"
                        value={kontaktTel2 || ''}
                        placeholder="+46123456789"
                      />
                    </fieldset>
                  </Grid.Column>

                  <Grid.Column>
                    <h5>Highlights / Händer i tältet</h5>
                    <P>
                      <TextCopy name="highlites-text-copy" />
                      <br />
                      <br />
                    </P>
                    <Form.TextArea
                      maxLength="330"
                      onChange={this.handleInputChange()}
                      value={highlightsDay1 || ''}
                      id="highlightsDay1"
                      name="highlightsDay1"
                      label={`${labelDay1} (max 330 tecken inkl. blanksteg)`}
                    />
                    <br />
                    <Form.TextArea
                      maxLength="330"
                      onChange={this.handleInputChange()}
                      value={highlightsDay2 || ''}
                      id="highlightsDay2"
                      name="highlightsDay2"
                      label={`${labelDay2} (max 330 tecken inkl. blanksteg)`}
                    />
                    <br />
                    <Form.TextArea
                      maxLength="330"
                      onChange={this.handleInputChange()}
                      value={highlightsDay3 || ''}
                      id="highlightsDay3"
                      name="highlightsDay3"
                      label={`${labelDay3} (max 330 tecken inkl. blanksteg)`}
                    />
                    <br />
                    <Form.TextArea
                      maxLength="330"
                      onChange={this.handleInputChange()}
                      value={highlightsDay4 || ''}
                      id="highlightsDay4"
                      name="highlightsDay4"
                      label={`${labelDay4} (max 330 tecken inkl. blanksteg)`}
                    />
                    <br />
                    <br />

                    <TextCopy name="medarrangor-text-copy" />

                    <RenderCosponsor
                      cosponsors={cosponsors}
                      deleteCosponsors={this.deleteCosponsors}
                      handleInputChange={this.handleInputChange}
                    >
                      <Button
                        type="button"
                        secondary
                        size="tiny"
                        icon="user plus"
                        labelPosition="right"
                        content="Lägg till medarrangörer"
                        onClick={() => {
                          this.setState((prevState) => {
                            const newItemKey = `s${
                              prevState.cosponsorsCount + 1
                            }`;
                            set(
                              prevState.cosponsors,
                              [newItemKey, 'id'],
                              newItemKey
                            );
                            return {
                              cosponsors: {
                                ...prevState.cosponsors
                              },
                              cosponsorsCount: prevState.cosponsorsCount + 1
                            };
                          });
                        }}
                        disabled={size(cosponsors) >= 4}
                      />
                    </RenderCosponsor>
                    <br />
                    <br />
                  </Grid.Column>

                  {/* form  error*/}
                  <Grid.Row>
                    {error ? (
                      <Message
                        negative
                        header="Error occurred"
                        content={error}
                      />
                    ) : null}
                  </Grid.Row>

                  {/* buttons */}
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        type="button"
                        basic
                        fluid
                        primary
                        content="Tillbaka"
                        size="large"
                        icon="left arrow"
                        onClick={() => {
                          this.setState((prevState) => ({
                            step: prevState.step - 1
                          }));
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        primary
                        type="submit"
                        icon="checkmark"
                        labelPosition="right"
                        size="large"
                        floated="right"
                        content="Bekräfta bokning"
                        disabled={submitting}
                        loading={submitting}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {modalOpen && (
                  <Modal
                    open={modalOpen}
                    onClose={this.handleClose}
                    size="tiny"
                    closeOnDimmerClick={false}
                    dimmer="inverted"
                  >
                    <Modal.Content>
                      <WhiteBox>
                        <DataBox>
                          <div>
                            <ul aria-label="Vänligen bekräfta dina uppgifter">
                              <li>
                                <TextCopy name="confirm-your-details" />
                              </li>
                              <li aria-label="Organisationsnamn/ synas och presenteras">
                                <span className="form-like">
                                  {organizerName}
                                </span>
                              </li>
                              <li aria-label="Tält">
                                <span className="form-like">{taltyta}</span>
                              </li>
                              <li aria-label="Period">
                                <span className="form-like">
                                  {fullPeriodLabel}
                                </span>
                              </li>
                              <li aria-label="Pris/period">
                                <span className="form-like">
                                  {Number(pris).toLocaleString('sv')} kr exkl.
                                  moms
                                </span>
                              </li>
                            </ul>
                          </div>
                          {currentUser ? (
                            <RenderUserDetails
                              currentUser={currentUser}
                              authId={authId}
                              firestore={firestore}
                            />
                          ) : (
                            ''
                          )}
                          <TermsCheckBox
                            handleCheckboxChange={this.handleCheckboxChange}
                          />
                        </DataBox>
                      </WhiteBox>
                    </Modal.Content>
                    <Modal.Actions>
                      <div className="flex no-gap">
                        <Button
                          basic
                          onClick={this.handleClose}
                          primary
                          content="Avbryt"
                          icon="cancel"
                          labelPosition="right"
                        />
                        <Button
                          type="submit"
                          loading={submitting}
                          disabled={
                            !(
                              godkannKopvillkorOchBekraftelse &&
                              godkannJarvaVekanKod
                            )
                          }
                          primary
                          content="Bekräfta"
                          labelPosition="right"
                          icon="checkmark"
                          onClick={() => {
                            this.handleClose();
                            this.handleSubmit();
                          }}
                        />
                      </div>
                    </Modal.Actions>
                  </Modal>
                )}
              </Form>
            </WhiteBox>
          </Fragment>
        );
      }
      case 3:
        return <BookingConfirm history={history} />;
      case 1:
      default:
        return (
          <RenderTents
            isFromSponsor
            products={this.selectedProducts(products)}
            handleInputChange={this.handleInputChange()}
            handleNextButtonClick={this.handleNextButtonClick}
            {...this.state}
          />
        );
    }
  };
  // #endregion

  // #region headerSection
  // cSpell:disable
  headerSection = () => (
    <WhiteBox>
      <CardContainer>
        <h3>
          <ContentEditor inline contentId="page-user-book-partnerskap-title">
            Partnerskap till Järvaveckan
          </ContentEditor>{' '}
          {EVENT_YEAR}
        </h3>
        <div className="media-text">
          <div>
            <ContentEditor contentId="page-user-book-partnerskap-main-1">
              {`<p>Partnerskapserbjudandet är en möjlighet för företag, myndigheter
              och organisationer att sprida sitt budskap samtidigt som man är en
              viktig part i att möjliggöra dialog och förståelse mellan olika
              typer av makthavare, företag och medborgare som traditionellt inte
              givits de bästa förutsättningar att delta i det offentliga
              samtalet.
              </p>
              <p>
              Som partner till Järvaveckan får företag associationsrätt och
              tillgång till den självklara mötesplatsen för samhällsförändring.
              Vi erbjuder en plattform som syftar till att verka för att alla
              ska bli inkluderade i det sociala, ekonomiska och politiska livet.
              Drygt 300 organisationer har redan insett vikten av att befinna
              sig i utanförskapsområden där politiken behövs som mest, men där
              deltagandet i den politiska debatten, näringslivet och i
              civilsamhället ofta är som svagast.
              </p>

              <h4>
              <span>Järvaveckan skapar värde</span>
              </h4>
              <p>
              Järvaveckan strävar efter att slipa ner trösklarna mellan vi och
              dom. Vi erbjuder en plattform där politiken, näringslivet och
              civilsamhället får en unik chans att skapa relationer och lära sig
              av varandra. Fördelarna med att vara med på Järvaveckan är många
              </p>
              <ul>
              <li>Ökad varumärkesexponering</li>
              <li>
                Möjlighet att engagera Järvaveckans deltagare i företagets
                viktigaste frågor
              </li>
              <li>Association till Järvaveckan </li>
              <li>
                Möjlighet att genomföra stora rekryteringar och andra
                aktiviteter såsom kontakt med boende och vardagligt folk,
                fördjupade kundrelationer och dialog med medlemmar – för att
                nämna några exempel
              </li>
              <li>
                Möt över 50 000 deltagare som är utspridda över en bred målgrupp
              </li>
              </ul>
            `}
            </ContentEditor>
          </div>
          <div className="medium-image">
            <ContentEditor contentId="page-user-book-partnerskap-main-1-image">
              {`
              <img
                src="https://firebasestorage.googleapis.com/v0/b/jarvaveckan-live.appspot.com/o/assets%2FPartnerskap%20-%205x5%2Fimg-10.jpg?alt=media&token=bea2cd5e-0a72-4f23-b807-7b50400f9979"
                alt="people"
                height="auto"
                width="100%"
              />
              `}
            </ContentEditor>
          </div>
        </div>
      </CardContainer>
    </WhiteBox>
  );

  // cSpell:enable
  // #endregion
  render() {
    const { products } = this.props;
    const { step } = this.state;

    // console.log('Sponsor state', this.state);

    return (
      <ContentContainer>
        <section>
          <WhiteBox>
            <h1>
              <ContentEditor inline contentId="page-partnerskap-title-1">
                Partnerskap
              </ContentEditor>
            </h1>
            {step === 1 ? (
              <SelectProducts>
                {this.selectedProducts(products)?.map((item) => {
                  const { productId, title } = item;

                  return (
                    <a key={productId} href={`#scroll_to_${productId}`}>
                      {title}
                    </a>
                  );
                })}
              </SelectProducts>
            ) : null}
          </WhiteBox>
          {step === 1 ? this.headerSection() : ''}

          {this.renderSteps()}
        </section>
      </ContentContainer>
    );
  }
}

Sponsor.propTypes = {
  products: PropTypes.object,
  history: PropTypes.object,
  firestore: PropTypes.object,
  firebase: PropTypes.object,
  auth: PropTypes.object,
  profile: PropTypes.object,
  sponsorBookings: PropTypes.object,
  authId: PropTypes.string,
  setStep: PropTypes.number,
  totalCurrentSponsorBookings: PropTypes.number,
  loading: PropTypes.bool,
  currentUser: PropTypes.object
};

export default Sponsor;
