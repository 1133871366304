import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Form, Button, Message } from 'semantic-ui-react';

import { StyledFormContainer } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import MyFallbackComponent from '../MyFallbackComponent';
import TermsLinks from '../TermsLinks';

function UserDetails(props) {
  const {
    handleInputChange,
    checkPassword,
    checkFormFilled,
    nextStep,
    values = {},
    goToLogin,
    authError,
    title = 'Fyll i nedan för att skapa ett konto'
  } = props;

  const {
    firstName,
    secondName,
    email,
    password,
    repeatPassword,
    titel = '',
    passordError,
    formFilled
  } = values;

  return (
    <div>
      <StyledFormContainer>
        <h3>{title}</h3>
        <p>
          Har du redan ett konto?{' '}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              goToLogin();
            }}
          >
            Logga in
          </a>
        </p>
        <br />
        <br />
        <Form error={Boolean(authError)} onSubmit={nextStep}>
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            required
            id="firstName"
            name="firstName"
            label="Förnamn"
            value={firstName}
            placeholder="Karin"
            type="text"
            autoFocus
          />
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            required
            id="secondName"
            name="secondName"
            label="Efternamn"
            value={secondName}
            placeholder="Andersdotter"
          />

          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            value={email}
            required
            label="E-post"
            id="email"
            name="email"
            type="email"
            placeholder="karin@mail.com"
          />
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            value={titel}
            id="titel"
            name="titel"
            label="Befattning / Titel"
          />

          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            onBlur={() => checkFormFilled(1)}
            value={password}
            required
            label="Lösenord"
            type="password"
            pattern=".{6,20}"
            id="password"
            name="password"
            autoComplete="on"
            placeholder="Password"
          />
          <Form.Input
            maxLength="140"
            onChange={(e, target) => {
              handleInputChange(e, target);
              checkPassword();
            }}
            value={repeatPassword}
            required
            autoComplete="on"
            label="Upprepa lösenord"
            type="password"
            pattern=".{6,20}"
            name="repeatPassword"
            id="repeatPassword"
            placeholder="Password"
            error={passordError}
          />
          <Message
            error
            header={authError && authError.code}
            content={authError && authError.message}
          />
          <Button
            type="submit"
            fluid
            primary
            disabled={!(!passordError && formFilled)}
          >
            Fortsätt
          </Button>
        </Form>
        <br />
        <br />
        <p>
          Alla ansökningar genomgår en granskning innan de godkänns för ett
          konto hos Järvaveckan.
        </p>
        <TermsLinks />
      </StyledFormContainer>
    </div>
  );
}

UserDetails.propTypes = {
  values: PropTypes.object,
  handleInputChange: PropTypes.func,
  checkFormFilled: PropTypes.func,
  checkPassword: PropTypes.func,
  nextStep: PropTypes.func,
  goToLogin: PropTypes.func,
  authError: PropTypes.object,
  title: PropTypes.string
};
export default withErrorBoundary(
  UserDetails,
  MyFallbackComponent,
  errorCallback
);
