import styled from 'styled-components';

import { vars } from '../../styledComponents';

const AdminHomeContainer = styled.div`
  padding-bottom: 40px;

  .number-warn {
    color: ${vars.jarvaRed};
    font-size: 1.5rem;
  }
  .number-success {
    color: green;
    font-size: 1.5rem;
  }
  .total-summery {
    margin: 1rem 0;
    p {
      font-size: 1.5rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .extra {
    flex-direction: column;
    li {
      border: none;
      padding: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    flex-direction: column;
    gap: 10px;

    @media ${vars.desktopAndUp} {
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      flex-grow: 1;
      padding: 10px 0 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      width: 100%;
      @media ${vars.desktopAndUp} {
        width: auto;
        padding: 20px 0 15px 0;
      }
      &.vert {
        max-width: 100%;
        flex-direction: column;
        height: auto;
        @media ${vars.desktopAndUp} {
          max-width: 48%;
        }
        h4 {
          i {
            font-family: monospace;
            font-size: 0.9rem;
            font-style: normal;
          }
        }
      }
      &:last-child {
        margin-left: 0px;
      }
      .plot {
        transform: translateY(-20px);
        margin-left: auto;
        display: flex;
        &.plot-reg {
          transform: translateY(0px);
          width: 100%;
        }
      }
      .data-text {
        font-size: calc(10px + 1.3vh);
        font-weight: bold;
        color: ${vars.jarvaBlue};
        transform: translateY(0px);
        @media ${vars.desktopAndUp} {
          font-size: calc(10px + 1.3vw);
          transform: translateY(-5px);
        }
      }
      .label {
        display: flex;
        align-items: center;
        @media ${vars.desktopAndUp} {
          width: auto;
        }
      }

      + li {
        margin-top: 0;
      }
    }
  }
  h6 {
    padding: 0;
    margin: 0;
    padding-right: 10px;
    font-size: 0.8rem;
    letter-spacing: 0.8px;
    color: ${vars.jarvaBlue};
  }
  .tent {
    width: 1.5rem;
    aspect-ratio: 1;
    background-color: ${vars.jarvaBlue};
    border: 1px solid ${vars.jarvaBlue};
    margin: 1px;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: translateY(-5px);
    }
    &.empty {
      border: 1px solid ${vars.jarvaBlue};
      background-color: ${vars.jarvaBlueLight};
      color: ${vars.jarvaTextDark};
      cursor: inherit;
      &:hover {
        transform: translateY(0px);
      }
    }
    &.ngo {
      background-color: transparent;
      position: relative;
      opacity: 0.3;
      border-color: ${vars.jarvaBlue};
      &:after {
        content: ' ';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: ${vars.jarvaBlue};
      }
    }
  }
`;

export { AdminHomeContainer };
