import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Form, Button, Message } from 'semantic-ui-react';

import { StyledFormContainer } from '../../styledComponents';
import { errorCallback } from '../../utils/index';
import MyFallbackComponent from '../MyFallbackComponent';
import TermsCheckBox from '../TermsCheckBox';

function Invoice(props) {
  const {
    handleInputChange,
    handleSubmit,
    prevStep,
    values = {},
    authError,
    handleCancelClick,
    title = 'Fyll i organisationens faktureringsuppgifter'
  } = props;
  const {
    step,
    totalSteps,
    fakturamottagare,
    fakturaadress,
    postnummer,
    ort,
    telefonnummer,
    fakturaEmail,
    godkannKopvillkorOchBekraftelse,
    godkannJarvaVekanKod,
    disableSubmit
  } = values;
  return (
    <div>
      <StyledFormContainer>
        <h3>{title}</h3>
        <a href="#" onClick={prevStep}>
          Föregående
        </a>
        <span style={{ float: 'right' }}>
          Steg {step} av {totalSteps}
        </span>
        <br />
        <br />
        <Form onSubmit={handleSubmit} error={Boolean(authError)}>
          <Form.Input
            maxLength="120"
            onChange={handleInputChange}
            required
            id="fakturamottagare"
            name="fakturamottagare"
            label="Fakturamottagare / referens"
            placeholder="Karin Andersdotter"
            defaultValue={fakturamottagare}
            type="text"
            autoFocus
          />
          {/* cspell:disable */}
          <Form.Input
            maxLength="140"
            onChange={handleInputChange}
            required
            id="fakturaadress"
            name="fakturaadress"
            label="Fakturaadress"
            placeholder="Hanstavägen 49, lgh 1617"
            type="text"
            defaultValue={fakturaadress}
          />
          {/* cspell:enable */}
          <Form.Input
            placeholder="16453"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="postnummer"
            name="postnummer"
            label="Postnummer"
            type="text"
            defaultValue={postnummer}
          />
          <Form.Input
            placeholder="Kista"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="ort"
            name="ort"
            label="Ort"
            type="text"
            defaultValue={ort}
          />
          <Form.Input
            placeholder="0744444448"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="telefonnummer"
            name="telefonnummer"
            label="Telefonnummer"
            type="text"
            defaultValue={telefonnummer}
          />
          <Form.Input
            placeholder="karin@mail.com"
            maxLength="140"
            required
            onChange={handleInputChange}
            id="fakturaEmail"
            name="fakturaEmail"
            label="E-post för PDF faktura"
            type="email"
            defaultValue={fakturaEmail}
          />
          <TermsCheckBox handleInputChange={handleInputChange} />
          <Message
            error
            header={authError && authError.code}
            content={authError && authError.message}
          />
          <br />
          <Button
            type="submit"
            fluid
            primary
            disabled={
              disableSubmit ||
              !godkannKopvillkorOchBekraftelse ||
              !godkannJarvaVekanKod
            }
          >
            Skapa konto
          </Button>
        </Form>
        <br />
        <div
          style={{
            margin: '15px 0',
            marginBottom: 0
          }}
        >
          <a
            href="mailto:info@jarvaveckan.se"
            target="_blank"
            rel="noopener noreferrer"
            data-info="info@jarvaveckan.se"
          >
            Hjälp?
          </a>
          <a href="#" style={{ float: 'right' }} onClick={handleCancelClick}>
            Avbryt
          </a>
        </div>
      </StyledFormContainer>
    </div>
  );
}

Invoice.propTypes = {
  values: PropTypes.object,
  authError: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleCancelClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  prevStep: PropTypes.func,
  title: PropTypes.string
};

export default withErrorBoundary(Invoice, MyFallbackComponent, errorCallback);
