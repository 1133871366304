/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/**
 *
 * PrivateRoute
 *
 */
import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Book from '../../containers/Book';

function PrivateRoute(props) {
  const { children, isApproved, ...rest } = props;

  const renderEl = (args) => {
    if (isApproved) {
      return (
        <div>
          {Children.map(children, (child) => {
            return cloneElement(child, { ...child.props });
          })}
        </div>
      );
    }

    return <Book {...args} disableButtons />;
  };

  return <Route {...rest} render={renderEl} />;
}

PrivateRoute.propTypes = {
  isApproved: PropTypes.bool,
  isBookingsLoaded: PropTypes.bool,
  isApprovedLoaded: PropTypes.bool,
  children: PropTypes.any,
  location: PropTypes.object
};

export default PrivateRoute;
