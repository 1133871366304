import styled from 'styled-components';

import { vars } from '../../styledComponents';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
  padding: 20px 0px;
  justify-content: center;
  align-items: top;
  max-width: 1400px;
  @media ${vars.desktopAndUp} {
    padding: 20px 0px;
    margin: 30px 0;
    margin: auto;
  }
  section {
    flex: 2;
    flex-basis: 50%;
    flex-grow: 1;
    padding-right: 0;
    padding-top: 3vh;
    padding-bottom: 5vh;

    text-align: left;
    > p,
    h1,
    .text-content {
      max-width: 1000px;
    }
    > h1 {
      font-size: 40px;
      line-height: 44px;
      @media ${vars.desktopAndUp} {
        font-size: 4vw;
        line-height: 4.1vw;
        margin-top: 1rem;
      }
    }
    @media ${vars.desktopAndUp} {
      padding-top: 5vh;
      padding-right: 5%;
    }
  }

  .important-info {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2.6rem 2rem;
    line-height: 2rem;
    h3 {
      border-bottom: yellow 2px solid;
      width: fit-content;
    }
    span {
      display: inline-block;
      padding: 1rem 0;
      font-size: 16px;
      > h3 {
        font-size: 17px;
      }
      > a {
        border-bottom: #2337ec solid 2px;
        width: fit-content;
        color: white;
        padding: 3px 0px;
      }
    }
  }
`;

const FormWrapper = styled.div`
  flex: 1;
  min-width: 320px;
  max-width: 550px;
  margin: auto;
  h3 {
    text-align: left;
  }
  border-radius: 4px;
  backdrop-filter: blur(7px);
  background-color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 99;
`;

const AnimationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: none;
  @media ${vars.desktopAndUp} {
    display: block;
  }
`;

export { Container, FormWrapper, AnimationWrapper };
