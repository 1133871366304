import styled from 'styled-components';

import { ContentContainer } from '../../styledComponents';

const BookingRegisterContentContainer = styled(ContentContainer)`
  font-size: 14px;
  .sent {
    background-color: #d6fbd9;
  }
  .to-send {
    background-color: #fffbc9;
  }
`;

export { BookingRegisterContentContainer };
