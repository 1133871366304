import styled from 'styled-components';

import { vars } from '../../styledComponents';

const AppContainer = styled.main`
  .columns {
    min-height: calc(100vh - 76.34px);

    display: flex;
    flex-direction: column;
    @media ${vars.ipadAndUp} {
      flex-direction: row;
    }

    .sidenav {
      flex-basis: 30%;
      padding: 0 1.5rem;
    }

    .main {
      flex: 1 70%;
      padding: 0 1.5rem;
      background-color: #fff;
      overflow: hidden;
      border-radius: 1rem;
    }
  }
`;

export { AppContainer };
